import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'menuOrderedByGroup',
})
export class MenuOrderedByGroupPipe implements PipeTransform {
  transform(menus: any): any[] {
    const menusGrouped: any[] = [];
    menus.forEach((menuComp: any) => {
      menuComp.menus.forEach((menuR: any) => {
        const idxG = menusGrouped.findIndex(
          (menuGroup: any) => menuGroup.group === menuR.group
        );
        if (idxG === -1) {
          menusGrouped.push({
            group: menuR.group,
            menus: [menuR],
          });
        } else {
          menusGrouped[idxG].menus.push(menuR);
        }
      });
    });
    return menusGrouped;
  }
}
