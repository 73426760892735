import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  DateAdapter,
  MatDateFormats,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'butaco-viandas-general-user-dates',
  templateUrl: './viandas-general-user-dates.component.html',
  styleUrls: ['./viandas-general-user-dates.component.scss'],
})
export class ViandasGeneralUserDatesComponent implements OnInit {
  @Input() selectedDate: any;
  @Input() locationsToChoose: any;
  @Input() contractStartEndDay: any;
  @Input() allOrdersAdmin: any;
  @Output() evChangeSelectedDay = new EventEmitter();
  @Output() evChangeSelectedLocation = new EventEmitter();

  calendarHeader = ExampleHeader;

  sortedLocations: any[] = [];
  selectedLocation: any = {id : 0};

  constructor() {}

  ngOnInit(): void {
    this.sortLocationsBySucursal();
    //console.log('ALL ORDERS ADMIN', this.allOrdersAdmin);

    this.setOrdersInLocations();
    //console.log('SORTED LOCATIONS', this.sortedLocations);
    //console.log(this.selectedDate)
  }

  changeSelectedDay(date: Date): void {
    this.evChangeSelectedDay.emit(date);
  }

  sortLocationsBySucursal() {
    //console.log(this.locationsToChoose)
    const sortLocations: any = {};
    for (const location of this.locationsToChoose) {
      if (sortLocations[location.sucursal.id] === undefined) {
        sortLocations[location.sucursal.id] = {
          sucursal: location.sucursal.name,
          locations: [
            {
              name: location.name,
              id: location.id,
              orders: [],
            },
          ],
        };
      } else {
        sortLocations[location.sucursal.id].locations.push({
          name: location.name,
          id: location.id,
        });
      }
    }
    const arrSortLocations: any = [];
    for (const location in sortLocations) {
      const aux = sortLocations[location];
      arrSortLocations.push(aux);
    }
    this.sortedLocations = arrSortLocations;
  }

  setOrdersInLocations() {
    for (const location of this.sortedLocations) {
      for (const order of this.allOrdersAdmin) {
        if (order.location.id === location.locations[0].id) {
          location.locations[0].orders.push(order);
        }
      }
    }
    this.setTypeMenuInLocations();
  }

  setTypeMenuInLocations() {
    for (const sucursal of this.sortedLocations) {
      for (let i = 0; i < sucursal.locations.length; i++) {
        //console.log(sucursal)
        const location = sucursal.locations[i];
        if (location.orders.length > 0) {
          //Seteo el tipo de menu - Ciudad - Campo - Especial, segun el primer menu pedido
          location.typeMenu = location.orders[0].menus_requested[0].typeMenu.type;
          //Calculo las cantidades totales de cada tipo de menu
          const totalesAceptados: any = {
            Especial: 0,
            Liviano: 0,
            Saludable: 0,
            Pleno: 0,
            Almuerzo: 0,
            Cena: 0,
            Total: 0,
          };
          const totalesPendientes : any = {
            Especial: 0,
            Liviano: 0,
            Saludable: 0,
            Pleno: 0,
            Almuerzo: 0,
            Cena: 0,
            Total: 0,
          }
          const totalesCancelados : any = {
            Especial: 0,
            Liviano: 0,
            Saludable: 0,
            Pleno: 0,
            Almuerzo: 0,
            Cena: 0,
            Total: 0,
          }

          //Ordenando las ordenes por estado
          const ordenesAceptadas = []
          const ordenesCanceladas = []
          const ordenesPendientes = []

          for (const order of location.orders) {
            switch (order.status_request) {
              case 'accepted':
                ordenesAceptadas.push(order)
                break;
              case 'acceptancePending':
                ordenesPendientes.push(order)
                break;
              case 'canceled':
                ordenesCanceladas.push(order)
                break;
            }

            //Ordenando por principal y seteando totales
            for (const menu of order.menus_requested) {
              //console.log(order)
              if (menu.typeMenu.component === 'Principal') {
                if (menu.typeMenu.type === 'Especial') {
                  if (order.status_request === 'accepted') {
                    totalesAceptados.Especial += menu.quantity;
                  } else if (order.status_request === 'canceled') {
                    totalesCancelados.Especial += menu.quantity;
                  } else if (order.status_request === 'acceptancePending') {
                    totalesPendientes.Especial += menu.quantity;
                  }
                } else {
                  if (order.status_request === 'accepted') {
                    totalesAceptados[menu.typeMenu.name] += menu.quantity;
                    totalesAceptados.Total += menu.quantity;
                  } else if (order.status_request === 'canceled') {
                    totalesCancelados[menu.typeMenu.name] += menu.quantity;
                    totalesCancelados.Total += menu.quantity;
                  } else if (order.status_request === 'acceptancePending') {
                    totalesPendientes[menu.typeMenu.name] += menu.quantity;
                    totalesPendientes.Total += menu.quantity;
                  }
                }
              }
            }
          }
          location.ordenesAceptadas = ordenesAceptadas;
          location.ordenesPendientes = ordenesPendientes;
          location.ordenesCanceladas = ordenesCanceladas;
          location.totalesAceptados = totalesAceptados;
          location.totalesPendientes = totalesPendientes;
          location.totalesCancelados = totalesCancelados;
        } else {
          location.typeMenu = 'Sin pedidos';
          location.totalesAceptados = {
            Especial: 0,
            Liviano: 0,
            Saludable: 0,
            Pleno: 0,
            Almuerzo: 0,
            Cena: 0,
            Total: 0
          }
          location.totalesCancelados = {
            Especial: 0,
            Liviano: 0,
            Saludable: 0,
            Pleno: 0,
            Almuerzo: 0,
            Cena: 0,
            Total: 0
          }
          location.totalesPendientes = {
            Especial: 0,
            Liviano: 0,
            Saludable: 0,
            Pleno: 0,
            Almuerzo: 0,
            Cena: 0,
            Total: 0
          }
          location.bebidas = [];
        }
      }
    }
    //console.log(this.sortedLocations)
  }

  changeSelectedLocation(location: any) {
    this.selectedLocation = location;
    this.evChangeSelectedLocation.emit(location);
    //console.log(this.selectedLocation);
  }
}

/** Custom header component for datepicker. */
@Component({
  selector: 'example-header',
  templateUrl: './calendar-header/calendar-header.html',
  styleUrls: ['./calendar-header/calendar-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExampleHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        this._dateFormats.display.monthYearA11yLabel
      )
      .replace('de', '');
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}
