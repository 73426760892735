<div class="container" *ngIf="loadingUserData; then gettingData else dataLoaded"></div>

<ng-template #gettingData>
    <mat-spinner color='primary' mode="indeterminate">
    </mat-spinner>
</ng-template>
<ng-template #dataLoaded>
    <div *ngIf="locationsAvailable; then userHasLocation else noLocation"></div>

    <!-- <div class="container" *ngIf="user.role === 'admin_company'; then admin else clientUser"></div> -->
    <!-- <div class="date-picker">
        <mat-form-field>
            <mat-label>Ubicación</mat-label>
            <mat-select (selectionChange)="locationChange()" [(value)]="selectedLocation">
                <mat-option [value]="location"
                    *ngFor="let location of locationsAvailable">{{location.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>

            <mat-label>Fecha de vianda</mat-label>
            <input matInput [matDatepicker]="picker" [value]="selectedDate" [min]="currentDate"
                (dateChange)="dateChange($event.value)"> -->
    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
    <!-- <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div> -->

    <!-- <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Pedido de vianda">
            <butaco-pedido-de-vianda [user]='user' [selectedDate]='selectedDate' [contractClient]='contractClient'
                [userPreference]='userPreference' [drinkList]='drinkList' [weekMenus]="weekMenus"
                [selectedWeek]="selectedWeek" [contractUser]="contractUser" [selectedLocation]="selectedLocation"
                [weekRequestedViandas]="weekRequestedViandas" [contractStartDate]="contractStartDate"
                [contractEndDate]="contractEndDate" [userRol]="userRol">
            </butaco-pedido-de-vianda>
        </mat-tab>
        <mat-tab label="Menús">
            <butaco-menus [user]='user' [contractClient]='contractClient' [drinkList]='drinkList'
                [weekMenus]="weekMenus" [selectedWeek]="selectedWeek" [menuConfig]="menuConfig"
                [userSpecialMenus]='userSpecialMenus' [contractUser]="contractUser">
            </butaco-menus>
        </mat-tab>
        <mat-tab *ngIf="userRol === 'Responsable de sucursal'" label="Detalle por usuario">
            <butaco-detalle-por-usuario [weekRequestedViandas]="weekRequestedViandas"
                [selectedDate]='selectedDate'></butaco-detalle-por-usuario>
        </mat-tab>
        <mat-tab *ngIf="user.role === 'Responsable de sucursal'" label="Resumen">
            <butaco-resume-viandas [user]='user' [selectedDate]='selectedDate' [contractClient]='contractClient'
                [userPreference]='userPreference' [drinkList]='drinkList' [weekMenus]="weekMenus"
                [selectedWeek]="selectedWeek" [contractUser]="contractUser" [selectedLocation]="selectedLocation"
                [weekRequestedViandas]="weekRequestedViandas" [contractStartDate]="contractStartDate"
                [contractEndDate]="contractEndDate"></butaco-resume-viandas>
        </mat-tab>
    </mat-tab-group> -->
</ng-template>
<ng-template #admin>
</ng-template>
<ng-template #userHasLocation>
    <div class="header-container">
        <div class="toggle-items">
            <mat-button-toggle-group [(ngModel)]="selectedTog">

                <mat-button-toggle value="Pedidos de vianda" class="tog-Item">

                    Pedidos de vianda</mat-button-toggle>

                <mat-button-toggle value="Menús" class="tog-Item">

                    Menús</mat-button-toggle>

                <mat-button-toggle value="Detalle por usuario" class="tog-Item"
                    *ngIf="userRol === 'Responsable de sucursal'">
                    Detalle por usuario</mat-button-toggle>

                <!-- <mat-button-toggle value="Resumen" class="tog-Item" *ngIf="userRol === 'Responsable de sucursal'">
                    Resumen</mat-button-toggle> -->

            </mat-button-toggle-group>
        </div>
        <div class="date-location-picker">
            <div class="location-picker">
                <mat-form-field  [ngClass]="{'hide-select' : locationsAvailable.length === 1}" appearance="outline" class="input-field">
                    <mat-label>Ubicación</mat-label>
                    <mat-select (selectionChange)="locationChange()" [(value)]="selectedLocation">
                        <mat-option [value]="location"
                            *ngFor="let location of locationsAvailable">{{location.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="date-picker">
                <mat-form-field appearance="outline" class="input-field">
                    <mat-label>Fecha de vianda</mat-label>
                    <input matInput [matDatepicker]="picker" [value]="selectedDate" [min]="currentDate"
                        (dateChange)="dateChange($event.value)">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>


    <div class="data-container">
        <div *ngIf="selectedTog === 'Pedidos de vianda'">
            <butaco-pedido-de-vianda [user]='user' [selectedDate]='selectedDate' [contractClient]='contractClient'
                [userPreference]='userPreference' [drinkList]='drinkList' [weekMenus]="weekMenus"
                [selectedWeek]="selectedWeek" [contractUser]="contractUser" [selectedLocation]="selectedLocation"
                [weekRequestedViandas]="weekRequestedViandas" [contractStartDate]="contractStartDate"
                [contractEndDate]="contractEndDate" [userRol]="userRol" [locationsAvailable]="locationsAvailable">
            </butaco-pedido-de-vianda>
        </div>
        <div *ngIf="selectedTog === 'Menús'">
            <butaco-menus [userRol]='userRol' [contractClient]='contractClient' [drinkList]='drinkList'
                [weekMenus]="weekMenus" [selectedWeek]="selectedWeek" [menuConfig]="menuConfig"
                [userSpecialMenus]='userSpecialMenus' [contractUser]="contractUser"
                [selectedLocation]="selectedLocation">
            </butaco-menus>
        </div>
        <div *ngIf="selectedTog === 'Detalle por usuario'">
            <butaco-detalle-por-usuario [companyRequestedViandas]="companyRequestedViandas"
                [selectedDate]='selectedDate' [selectedLocation]="selectedLocation"></butaco-detalle-por-usuario>
        </div>
        <div *ngIf="selectedTog === 'Resumen'">
            <butaco-resume-viandas [user]='user' [selectedDate]='selectedDate' [contractClient]='contractClient'
                [userPreference]='userPreference' [drinkList]='drinkList' [weekMenus]="weekMenus"
                [selectedWeek]="selectedWeek" [contractUser]="contractUser" [selectedLocation]="selectedLocation"
                [weekRequestedViandas]="weekRequestedViandas" [contractStartDate]="contractStartDate"
                [contractEndDate]="contractEndDate"></butaco-resume-viandas>
        </div>
    </div>
</ng-template>

<ng-template #clientUser>
    <div class="container" *ngIf="userWithoutLocation; then noLocation else withLocation"></div>
</ng-template>

<ng-template #noLocation>
    <div class="no-location">
        Su cuenta no tiene ninguna ubicación asignada, por favor comuníquese con su administrador para que lo
        asigne.
    </div>

</ng-template>

<ng-template #withLocation>
</ng-template>