import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { CommonService } from 'src/app/shared/services/common.service';
import { ViandasNewRequestDialogComponent } from '../viandas-new-request-dialog/viandas-new-request-dialog.component';
import { ViandasService } from 'src/app/shared/services/viandas.service';

@Component({
  selector: 'butaco-viandas-admin',
  templateUrl: './viandas-admin.component.html',
  styleUrls: ['./viandas-admin.component.scss'],
})
export class ViandasAdminComponent implements OnInit {
  @Input() user: any;
  @Input() company: any;
  @Input() userPreference: any;
  @Input() drinkList: any;
  @Input() contractClient: any;

  isLoading = false;
  date = new Date();
  currentMonth: any;

  locations: any[] = [];
  locationsWithRequest: any[] = [];
  emptyDaysReference: any[] = [];

  startDay: any;
  endDay: any;
  menus: any;
  constructor(
    private viandasServ: ViandasService,
    private commonServ: CommonService,
    // private _bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    // Actualiza la fecha cada un minuto para que se mantener la hora actual
    dayjs.locale('es');
    this.currentMonth = {
      month: dayjs(new Date()),
      monthName: dayjs(new Date()).format('MMMM'),
    };

    setInterval(() => {
      this.date = new Date();
    }, 1000);

    this.startDay = dayjs(this.currentMonth.month).startOf('month').toDate();
    this.endDay = dayjs(this.currentMonth.month).endOf('month').toDate();

    this.setEmptyDatesArray(
      dayjs(this.currentMonth.month).startOf('month'),
      dayjs(this.currentMonth.month).endOf('month')
    );
  }

  setEmptyDatesArray(startDay: any, endDay: any): any {
    let currentDay = startDay;
    dayjs.extend(isSameOrBefore);
    while (dayjs(currentDay).isSameOrBefore(dayjs(endDay), 'day')) {
      this.emptyDaysReference.push({
        day_name: currentDay.format('DD-MM-YYYY'),
        day: currentDay,
      });
      currentDay = dayjs(currentDay).add(1, 'day');
    }
  }

  ngOnInit(): void {
    this.setLocations();
    this.getMenuList();
  }
  getMenuList(): void {
    this.commonServ.getMenuConfig().subscribe(
      (res) => {
        if (res) {
          this.menus = res.types_menus;
        }
      },
      (err) => console.error(err)
    );
  }

  setLocations(): void {
    this.contractClient.forEach((cont: any, idC: number) => {
      if (cont) {
        cont.locations.forEach((loc: any, idL: number) => {
          const newLocation = {
            location: loc,
            contract: cont,
          };
          this.locations.push(newLocation);

          if (
            idL === cont.locations.length - 1 &&
            idC === this.contractClient.length - 1
          ) {
            this.getAllRequests(this.startDay, this.endDay);
          }
        });
      }
    });
  }

  getAllRequests(startDay: Date, endDay: Date): any {
    console.log(startDay)
    this.viandasServ
      .getRequestMeals(startDay, endDay, this.company.id)
      .subscribe((res: any) => {
        if (res) {
          const orders = res.filter(
            (ord: any) => ord.status_request !== 'cancelled'
          );
          this.setLocationsWithRequests(orders);
          //TODO: Armar request array
        }
      });
  }

  setLocationsWithRequests(request: any): void {
    dayjs.extend(dayOfYear);

    this.locationsWithRequest = [];
    const daysInCourse: any[] = [];
    this.locations.forEach((loc: any, idL: number) => {
      let locationWithRequest: any = {
        location: loc.location,
        dates: [],
        totalRequests: 0,
        specials_request: 0,
      };
      this.emptyDaysReference.forEach((day: any, idD: number) => {
        const findRequest = request.find((req: any) => {
          if (req.type === 'Personal') {
            return (
              dayjs(req.date_Meal.toDate()).isSame(
                dayjs(day.day.toDate()),
                'day'
              ) && loc.location.id === req.location.id
            );
          } else {
            return (
              dayjs(req.date_Meal.toDate()).isSame(
                dayjs(day.day.toDate()),
                'day'
              ) && loc.location.id === req.location.location.id
            );
          }
        });

        let statusDay;

        if (dayjs(day.day.toDate()).isSame(dayjs(), 'day')) {
          statusDay = 'En Curso';
          daysInCourse.push(
            dayjs(day.day).dayOfYear().toString() + loc.location.name
          );
        } else if (dayjs(day.day.toDate()).isBefore(dayjs(), 'day')) {
          statusDay = 'Finalizado';
        } else {
          statusDay = 'Pendiente';
        }
        const priority = ['Entrada', 'Principal', 'Postre', 'Bebida'];

        const newDate: any = {
          date: day.day.toDate(),
          dateId: dayjs(day.day).dayOfYear().toString() + loc.location.name,
          requestDetail: null,
          requests: loc.contract.components
            .map((comp: any) => {
              const newRequest: any = {
                component: comp,
                totalSum: 0,
              };
              return newRequest;
            })
            .sort((a: any, b: any) => {
              return (
                priority.indexOf(a.component) - priority.indexOf(b.component)
              );
            }),
          status: statusDay,
        };

        if (findRequest && findRequest.type !== 'Personal') {
          newDate.requestDetail = findRequest;
          findRequest.menus_requested.forEach((menu: any, idM: number) => {
            const findIndexReqType = newDate.requests.findIndex(
              (comp: any) => comp.component === menu.typeMenu.component
            );
            if (findIndexReqType !== -1) {
              if (menu.typeMenu.component === 'Principal') {
                newDate.requests[findIndexReqType].totalSum += menu.quantity;
                locationWithRequest.totalRequests += menu.quantity;
              }
            }
          });
          findRequest.drinks_requested.forEach((drink: any, idM: number) => {
            const findIndexReqType = newDate.requests.findIndex(
              (comp: any) => comp.component === 'Bebida'
            );
            if (findIndexReqType !== -1) {
              newDate.requests[findIndexReqType].totalSum += drink.quantity;
            }
          });
        } else if (findRequest && findRequest.type === 'Personal') {
          findRequest.menus_requested.forEach((menu: any, idM: number) => {
            const findIndexReqType = newDate.requests.findIndex((comp: any) => {
              if (comp.component === menu.component) {
                return true;
              } else if (
                menu.component === undefined &&
                comp.component === 'Principal'
              ) {
                return true;
              }
              return false;
            });
            if (findIndexReqType !== -1) {
              if (menu.component === 'Principal') {
                newDate.requests[findIndexReqType].totalSum++;
                locationWithRequest.totalRequests++;
              }
              if (menu.component === 'Entrada') {
                newDate.requests[findIndexReqType].totalSum++;
              }
              if (menu.component === 'Postre') {
                newDate.requests[findIndexReqType].totalSum++;
              }
              if (menu.especial === true) {
                newDate.requests[findIndexReqType].totalSum++;
                locationWithRequest.totalRequests++;
                locationWithRequest.specials_request++;
              }
            }
          });

          findRequest.drinks_requested.forEach((drink: any, idM: number) => {
            const findIndexReqType = newDate.requests.findIndex(
              (comp: any) => comp.component === 'Bebida'
            );
            if (findIndexReqType !== -1) {
              newDate.requests[findIndexReqType].totalSum++;
            }
          });
        }
        
        locationWithRequest.dates.push(newDate);

        if (idD === this.emptyDaysReference.length - 1) {
          this.locationsWithRequest.push(locationWithRequest);
        }
        if (
          idD === this.emptyDaysReference.length - 1 &&
          idL === this.locations.length - 1
        ) {
          // Scrolls to the day in course added in the ID html
          // setTimeout(() => {
          //   daysInCourse.forEach((day: any) => {
          //     // console.log(day);
          //     document.getElementById(day).scrollIntoView();
          //   }, 500);
          // });
        }
      });
    });
  }

  newMealRequest(): void {
    // const dialogRef = this._bottomSheet.open(ViandasNewRequestDialogComponent, {
    const dialogRef = this.dialog.open(ViandasNewRequestDialogComponent, {
      data: {
        locations: this.locations,
        user: this.user,
        company: this.company,
        locationsSelected: [],
        drinks: this.drinkList,
        date: dayjs(),
      },
      autoFocus: false,
      // disableClose: true,
    });

    // dialogRef.afterDismissed().subscribe((result) => {
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.snackBar.open('🎉 El pedido fue realizado correctamente', '', {
          duration: 3000,
        });
      }
    });
  }

  changeMonth(action: string) {
    // console.log(this.currentMonth);
    if (action === 'next') {
      this.currentMonth = {
        month: dayjs(this.currentMonth.month, 'MM')
          .add(1, 'month')
          .format('MM'),
        monthName: dayjs(this.currentMonth.month)
          .add(1, 'month')
          .format('MMMM'),
      };
    } else {
      this.currentMonth = {
        month: dayjs(this.currentMonth.month, 'MM')
          .subtract(1, 'month')
          .format('MM'),
        monthName: dayjs(this.currentMonth.month)
          .subtract(1, 'month')
          .format('MMMM'),
      };
    }
  }

  goTo(date: any, location: any): void {
    // if (date.requestDetail.id) {
    //   this.router.navigate(['/viandas', date.requestDetail.id]);
    // }
    // console.log(location);
    const url = `/viandas/${location.location.id}/${dayjs(date.date).format(
      'DD-MM-YYYY'
    )}`;
    // console.log(url);

    this.router.navigate([
      '/viandas/ubicacion',
      {
        location: location.location.id,
        location_name: location.location.name,
        date: dayjs(date.date).format('DD-MM-YYYY'),
      },
    ]);
  }
}
