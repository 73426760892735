<div class="container-dialog">
  <ng-container *ngIf="loading; else dataLoaded">
    <div class="mensaje">Cargando info...</div>
  </ng-container>
  <ng-template #dataLoaded>
    <div class="container">
      <div class="details">
        <div class="title-dialog">
          <h4 class="tittle">
            {{ orderId ? "Editar Pedido" : "Nuevo Pedido" }}
          </h4>
        </div>
        <form [formGroup]="detailsForm">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Seleccione una fecha</mat-label>
            <input matInput [matDatepickerFilter]="disableCalendarDay" [min]="currentDate"
              (dateChange)="onChangeDate($event.value)" [matDatepicker]="picker" formControlName="date" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="detailsForm.controls.date.hasError('required')">
              Debe seleccionar una fecha.
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-select [disabled]="this.availableLocations === undefined"
              (selectionChange)="onChangeLocations($event.value)" matInput placeholder="Ubicación"
              formControlName="location" required autocomplete="off">
              <mat-option *ngFor="let location of availableLocations" [value]="location">
                {{ location.name }}
              </mat-option>
            </mat-select>
            <mat-error> Debe seleccionar una ubicación. </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="selectedLocation.rol === 'Responsable de sucursal'" appearance="outline"
            style="width: 100%">
            <mat-select matInput placeholder="A nombre de" formControlName="requested_to" required
              (selectionChange)="onChangeUser($event.value)" autocomplete="off">
              <mat-option *ngFor="let user of company.users" [value]="user.uid">
                {{ user.first_names }} {{ user.last_names }}
              </mat-option>
              <mat-option value="sin_opcion"> Sin opcion </mat-option>
            </mat-select>
            <mat-label *ngIf="orderId">A nombre de</mat-label>
            <mat-error> Debe seleccionar un nombre. </mat-error>
          </mat-form-field>
        </form>
      </div>
      <div class="menu-containers">
        <div class="menu-div">
          <div class="menus">
            <div *ngIf="contract.typeMenu">
              <h3>Menú {{ contract.typeMenu }}</h3>
              <hr />
            </div>
            <ng-container *ngIf="contract.typeMenu; else sinUbicacion">
              <!--MENU GENERICO-->
              <ng-container *ngIf="filteredMenus.length > 0; else noHayMenu">
                <form [formGroup]="menusRequestedForm">
                  <table class="menutype">
                    <tr class="menu-row" *ngFor="let menu of menusRequestedForm.controls | keyvalue">
                      <td class="data-menu">
                        <div class="menu-name">
                          <div class="viandaInfo">
                            <p class="product-name">
                              {{ menu.value.value.typeMenu.menu_name }}
                            </p>
                            <p class="product-description">
                              {{ menu.value.value.typeMenu.name }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="menu-quantity">
                          <div *ngIf="
                          menu.value.value.typeMenu.component != 'Principal'
 
                            ">
                            <span>{{ menu.value.value.quantity }}</span>
                          </div>
                          <div *ngIf="
                          menu.value.value.typeMenu.component != 'Entrada' &&
                          menu.value.value.typeMenu.component != 'Postre' &&
                          menu.value.value.typeMenu.component != 'Fruta'
                            " class="almuerzo-quantity">
                            <button mat-fab color="accent" class="buttonAddOrQuit"
                              [disabled]="menu.value.value.quantity == 0" (click)="
                                addOrRemoveMenu(
                                  -1,
                                  menu.value.value
                                )
                              ">
                              <mat-icon>do_not_disturb_on</mat-icon>
                            </button>
                            <input type="number" min="0" class="text-input" disabled
                              [(value)]="menu.value.value.quantity" />
                            <button [disabled]="
                            menu.value.value.typeMenu.menu_disabled ||
                                !canAddMenu()
                              " mat-fab class="buttonAddOrQuit" (click)="
                                addOrRemoveMenu(
                                  1,
                                  menu.value.value
                                )
                              " color="primary">
                              <mat-icon>add_circle</mat-icon>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </form>

              </ng-container>
              <ng-template #noHayMenu>
                <div class="menu-container mensaje">
                  <h3>
                    No hay menús de {{ contract.typeMenu }} disponibles para esta fecha
                  </h3>
                </div>
              </ng-template>
              <div *ngIf="contract.typeMenu && user.special_enabled.length > 0">
                <div>
                  <div class="menus">
                    <h3>Menús Especiales</h3>
                  </div>
                  <hr />
                </div>
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-select placeholder="Seleccione Menu especial a agregar"
                    (selectionChange)="onChangeEspecial($event.value.value.value)">
                    <mat-option *ngFor="let special of specialMenusRequestedForm?.controls | keyvalue"
                      [value]="special">
                      {{ special.value.value.typeMenu.name }}<mat-divider></mat-divider>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <ng-container>
                  <div *ngFor="let menu of specialMenusRequestedForm?.controls | keyvalue">
                    <div class="menu-container" *ngIf="menu.value.value.select || menu.value.value.quantity > 0">
                      <div class="menu-name">
                        <div class="viandaInfo">
                          <p class="product-name">{{ menu.value.value.typeMenu.name }}</p>
                          <p class="product-description">
                            <span *ngIf="checkContractAvailability('Entrada')">Entrada -</span>
                            <span> Principal </span>
                            <span *ngIf="checkContractAvailability('Postre')">- Postre </span>
                            <span *ngIf="checkContractAvailability('Fruta')">- Fruta </span>
                          </p>
                        </div>
                      </div>
                      <div class="menu-quantity">
                        <div class="almuerzo-quantity">
                          <button mat-fab color="accent" class="buttonAddOrQuit"
                            [disabled]="menu.value.value.quantity == 0" (click)="
                              addOrRemoveMenu(-1, menu.value.value)
                            ">
                            <mat-icon>do_not_disturb_on</mat-icon>
                          </button>
                          <input type="number" min="0" class="text-input" disabled
                            [(value)]="menu.value.value.quantity" />
                          <button [disabled]="!canAddMenu()" mat-fab class="buttonAddOrQuit" (click)="
                              addOrRemoveMenu(+1, menu.value.value)
                            " color="primary">
                            <mat-icon>add_circle</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="menu-form" class="menu-container"
                      *ngIf="menu.value.value.select || menu.value.value.quantity > 0">
                      <form class="example-form">
                        <mat-form-field appearance="outline" style="width: 116%">
                          <mat-label>Observaciones para menú especial</mat-label>
                          <input (ngModelChange)="changeObservation($event, menu.value.value)"
                            [disabled]="menu.value.value.quantity <= 0"
                            [ngModel]="menu.value.value.typeMenu.observation" name="observation" matInput />
                        </mat-form-field>
                      </form>
                    </div>
                  </div>
                </ng-container>
              </div>
              <!-- BEBIDAS -->
              <div *ngIf="checkContractAvailability('Bebida')" class="bebida">
                <div class="menus">
                  <h3>Bebidas</h3>
                </div>
                <hr />
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-select placeholder="Seleccione bebida a agregar"
                    (selectionChange)="onChangeBebida($event.value)">
                    <mat-option *ngFor="let drink of drinksRequestedForm?.controls | keyvalue"
                      [value]="drink.value.value">
                      {{ drink.value.value.drink.name }}<mat-divider></mat-divider>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="drink-container" *ngIf="drinksData.length > 0; else noHayDrink">
                  <div *ngFor="let drink of drinksRequestedForm?.controls | keyvalue">
                    <div class="viandaInfo drink-name"
                      *ngIf="drink.value.value.select || drink.value.value.quantity > 0">
                      <div class="product-info">
                        <p class="product-name">{{ drink.value.value.drink.name }}</p>
                        <p class="product-description">{{ drink.value.value.drink.content }}</p>
                      </div>
                      <div class="drink-addorremove">
                        <button mat-icon-button color="accent" [disabled]="
                        drink.value.value.quantity  == 0 ||
                        drink.value.value.quantity  == undefined
                          " class="buttonAddOrQuit" (click)="addOrRemoveDrink(-1, drink.value.value)">
                          <mat-icon fontSet="material-icons-outlined" class="buttonAddOrQuit">
                            do_not_disturb_on
                          </mat-icon>
                        </button>
                        <input type="number" min="0" class="text-input" disabled
                          [value]="drink.value.value.quantity || 0" />
                        <button [disabled]="drinksAndMenusQuantityIsEqual()" mat-icon-button color="primary"
                          class="buttonAddOrQuit" (click)="addOrRemoveDrink(+1, drink.value.value)">
                          <mat-icon fontSet="material-icons-outlined" class="buttonAddOrQuit">
                            add_circle
                          </mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-template #noHayDrink>
                  <div class="menu-container">
                    <h3>No hay bebidas disponibles para esta fecha</h3>
                  </div>
                </ng-template>
              </div>
            </ng-container>
            <ng-template #sinUbicacion>
              <h4 class="mensaje">
                Por favor seleccione una ubicacion para mostrar los menus
                correspondientes
              </h4>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="resumeContent">
        <div class="title-dialog">
          <h4 class="tittle">Mi pedido</h4>


        </div>
        <div class="resume">
          <span class="cartel" *ngIf="
              !canAddMenu()
            ">
            <div class="mensaje-bebidas">
              Solo puede pedir una vianda principal
            </div>
          </span>
          <span class="cartel" *ngIf="checkContractAvailability('Bebida') && canAddDrinks()">
            <div class="mensaje-bebidas">
              Hay menos bebidas que menus principales
            </div>
          </span>
          <div class="buttons">
            <button class="btnGuardar" mat-raised-button color="primary" [disabled]="loading" [disabled]="
                disableSaveButton() || savingData"
               (click)="checkEditPermissions()">
              <mat-icon fontSet="material-icons-outlined">shopping_cart
              </mat-icon>
              <span>GUARDAR CAMBIOS</span>
            </button>
            <button class="btnReactivar" matTooltip="Reactivar pedido" *ngIf="
                orderToEdit &&
                (orderToEdit?.status_request | checkOrderStatus : 'activate')
              " color="primary" mat-stroked-button fontSet="material-icons-outlined" (click)="checkEditPermissions()">
              <mat-icon fontSet="material-icons-outlined">check_circle</mat-icon>
            </button>


            <button *ngIf="
                  orderId &&
                  (orderToEdit?.status_request | checkOrderStatus : 'cancel');
              " class="btnCancel" matTooltip="Cancelar pedido" mat-stroked-button fontSet="material-icons-outlined"
              (click)="changeStatus( orderToEdit, 'cancelled')">
              <mat-icon fontSet="material-icons-outlined">close</mat-icon>
            </button>



            <button class="btnClose" matTooltip="Volver a inicio" mat-stroked-button (click)="navigateToViandas()">
              <mat-icon fontSet="material-icons-outlined">keyboard_return</mat-icon>
            </button>

          </div>
          <div class="ubicacion">
            <span>Ubicación</span>
            <hr class="line" />
            <div>{{ selectedLocation.name }}</div>
            <div class="product-description">{{ selectedLocation.address }}</div>
            <div *ngIf="!selectedLocation" class="noSelect">
              No se ha seleccionado una ubicación
            </div>
          </div>
          <div>
            <div class="menus">
              <span>Menús</span>
              <hr class="line" />
              <div *ngFor="let menu of menusRequestedForm?.controls | keyvalue">
                <div class="menuConten" *ngIf="menu.value.value.quantity > 0">
                  <div class="quantity">
                    <div>{{ menu.value.value.quantity }} X</div>
                  </div>
                  <div class="textConten">
                    <div class="text">
                      <p class="product-name">{{ menu.value.value.typeMenu.menu_name }}</p>
                      <p class="product-description">
                        {{ menu.value.value.typeMenu.name }}
                      </p>
                    </div>
                    <div *ngIf="menu.value.value.typeMenu.component === 'Principal'">
                      <button mat-icon-button (click)="
                          addOrRemoveMenu(-menu.value.value.quantity, menu.value.value)
                        ">
                        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="noSelect" *ngIf="!thereAreMenusSelected()">
                No hay menús seleccionados
              </div>
            </div>
            <div class="menusSpecial" *ngIf="contract.typeMenu && requestQuantityControls.specialMenus > 0">
              <span>Menús Especiales</span>
              <hr class="line" />
              <div *ngFor="let menu of specialMenusRequestedForm?.controls | keyvalue">
                <div class="menuConten" *ngIf="menu.value.value.quantity > 0">
                  <div class="quantity">
                    <div>{{ menu.value.value.quantity }} X</div>
                  </div>
                  <div class="textConten">
                    <div class="text">
                      <p class="product-name">
                        {{ menu.value.value.name }}
                        <span *ngIf="checkContractAvailability('Entrada')">Entrada -</span>
                        <span> Principal </span>
                        <span *ngIf="checkContractAvailability('Postre')">- Postre </span>
                        <span *ngIf="checkContractAvailability('Fruta')">- Fruta </span>
                      </p>
                    </div>
                    <div>
                      <button mat-icon-button (click)="
                          addOrRemoveMenu(
                            -menu.value.value.quantity,
                            menu.value.value
                          )
                        ">
                        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="requestQuantityControls.specialMenus === 0" class="noSelect">
                No hay menús especiales seleccionados
              </div>
            </div>
            <div class="drinks" *ngIf="checkContractAvailability('Bebida')">
              <span>Bebidas</span>
              <hr class="line" />
              <div *ngFor="let drink of drinksRequestedForm?.controls | keyvalue">
                <div class="menuConten" *ngIf="drink.value.value.quantity > 0">
                  <div class="quantity">
                    <div color="primary">{{ drink.value.value.quantity }} X</div>
                  </div>
                  <div class="textConten">
                    <div class="text">
                      <p class="product-name">{{ drink.value.value.drink.name }}</p>
                      <p class="product-description">{{ drink.value.value.drink.content }}</p>
                    </div>
                    <div>
                      <button mat-icon-button (click)="
                          addOrRemoveDrink(
                            -drink.value.value.quantity,
                            drink.value.value
                          )
                        ">
                        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="requestQuantityControls.drinks <= 0" class="noSelect">
                No hay bebidas seleccionadas
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>