import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesRoutingModule } from './pages-routing.module';
import { MatIcon } from '@angular/material/icon';
import { OrderRequestComponent } from './viandas/order-request/order-request.component';
import { InputsRequestComponent } from './viandas/order-request/inputs-request/inputs-request.component';
import { MenuesDrinksRequestComponent } from './viandas/order-request/menues-drinks-request/menues-drinks-request.component';
import { ResumeRequestComponent } from './viandas/order-request/resume-request/resume-request.component';
import { ViandasModule } from './viandas/viandas.module';


@NgModule({
  declarations: [
  ],
  imports: [
    ViandasModule,
    CommonModule,
    PagesRoutingModule,
  ]
})
export class PagesModule { }
