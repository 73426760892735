<div class="admin-viandas-container">
  <div class="header">
    <div class="header-month">
      <h1>Pedidos</h1>
      <div class="month-selector">
        <button mat-icon-button color='primary' (click)="changeMonth('prev')">
          <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>
        </button>
        <h5 class="dateText"> {{ currentMonth.monthName }}</h5>
        <button mat-icon-button color='primary' (click)="changeMonth('next')">
          <mat-icon fontSet="material-icons-outlined">chevron_right</mat-icon>
        </button>
      </div>
    </div>
    <div class="timeDateCont">
      <h5 class="dateText">{{ date | date:'dd/MM/YYYY':'UTC-3'}} - {{ date | date:'HH:mm:ss':'UTC-3'}}</h5>
    </div>
  </div>

  <mat-progress-bar color='primary' mode="indeterminate" *ngIf="isLoading">
  </mat-progress-bar>

  <div class="content">
    <div class="container-list-location">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab *ngFor="let location of locationsWithRequest">
          <ng-template mat-tab-label class="label-tab">
            {{location.location.name}}
            <!-- <mat-icon class="example-tab-icon">thumb_up</mat-icon> -->
            <div class="container-total-req">
              <!-- <span>Total: </span> <span class="accodeon-total">{{location.totalRequests}}</span> -->
              <h4 [matTooltip]="'Pedidos Totales: ' + location.totalRequests" matTooltipPosition="above">{{location.totalRequests}}</h4>
            </div>
          </ng-template>

          <!-- CONTENT ACCORD -->
          <div class="row-date" *ngFor="let date of location.dates; let last = last; let first = first"
            (click)="goTo(date,location)">
            <div class="header-content" *ngIf="first">
              <h5>Fecha</h5>
              <h5 *ngFor="let comp of date.requests" [matBadgeHidden]="comp.component !== 'Principal'"
                [matBadge]="location.specials_request" [matTooltip]="'Pedidos Especiales: ' + location.specials_request"
                matTooltipPosition="above">
                {{comp.component}}</h5>
              <h5>Estado</h5>
            </div>
          </div>
          <div class="content-accordion">
            <div class="row-date" *ngFor="let date of location.dates; let last = last; let first = first"
              (click)="goTo(date,location)" [ngClass]="{'todays-row': date.status === 'En Curso'}" id='{{date.dateId}}'>
              <div class="data-content">
                <h5>{{date.date | date:'dd/MM/YYYY':'UTC-3'}}</h5>
                <h5 *ngFor="let comp of date.requests" [ngStyle]="{'opacity': comp.totalSum === 0 ? '0.3' : '1'}">
                  {{comp.totalSum}}</h5>
                <div class="status-container" [ngStyle]="{'opacity': date.status === 'Finalizado' ? '0.3' : '1'}">
                  <mat-icon *ngIf="date.status === 'Finalizado'" fontSet="material-icons-outlined">remove_circle_outline
                  </mat-icon>
                  <mat-icon *ngIf="date.status === 'En Curso'" fontSet="material-icons-outlined" color='primary'>
                    schedule</mat-icon>
                  <mat-icon *ngIf="date.status === 'Pendiente'" fontSet="material-icons-outlined" color='warn'>pending
                  </mat-icon>
                  <h5>{{date.status}}</h5>
                </div>
              </div>
              <mat-divider *ngIf="!last">
              </mat-divider>
            </div>
          </div>

        </mat-tab>
      </mat-tab-group>

      <!-- <mat-accordion multi>
        <mat-expansion-panel class="mat-elevation-z0" *ngFor="let location of locationsWithRequest" expanded>
          <mat-expansion-panel-header class="header-accordion">
            <mat-panel-title>
              {{location.location.name}}
            </mat-panel-title>
            <mat-panel-description>
              {{location.location.address}}
            </mat-panel-description>
            <div style="margin: 0 8px 0 0">
              <span>Total: </span> <span class="accodeon-total">{{location.totalRequests}}</span>
            </div>
          </mat-expansion-panel-header>
          

          <div class="row-date" *ngFor="let date of location.dates; let last = last; let first = first"
            (click)="goTo(date,location)">
            <div class="header-content" *ngIf="first">
              <h5>Fecha</h5>
              <h5 *ngFor="let comp of date.requests" [matBadgeHidden]="comp.component !== 'Principal'"
                [matBadge]="location.specials_request" [matTooltip]="'Pedidos Especiales: ' + location.specials_request"
                matTooltipPosition="above">
                {{comp.component}}</h5>
              <h5>Estado</h5>
            </div>
          </div>
          <div class="content-accordion">
            <div class="row-date" *ngFor="let date of location.dates; let last = last; let first = first"
              (click)="goTo(date,location)" [ngClass]="{'todays-row': date.status === 'En Curso'}" id='{{date.dateId}}'>
              <div class="data-content">
                <h5>{{date.date | date:'dd/MM/YYYY':'UTC-3'}}</h5>
                <h5 *ngFor="let comp of date.requests" [ngStyle]="{'opacity': comp.totalSum === 0 ? '0.3' : '1'}">
                  {{comp.totalSum}}</h5>
                <div class="status-container" [ngStyle]="{'opacity': date.status === 'Finalizado' ? '0.3' : '1'}">
                  <mat-icon *ngIf="date.status === 'Finalizado'" fontSet="material-icons-outlined">remove_circle_outline
                  </mat-icon>
                  <mat-icon *ngIf="date.status === 'En Curso'" fontSet="material-icons-outlined" color='primary'>
                    schedule</mat-icon>
                  <mat-icon *ngIf="date.status === 'Pendiente'" fontSet="material-icons-outlined" color='warn'>pending
                  </mat-icon>
                  <h5>{{date.status}}</h5>
                </div>
              </div>
              <mat-divider *ngIf="!last">
              </mat-divider>
            </div>
          </div>

        </mat-expansion-panel>
      </mat-accordion> -->
    </div>
  </div>
</div>
<button mat-fab color='primary' class="fab-add-btn" matTooltip="Realizar un nuevo pedido de viandas"
  matTooltipPosition="above" (click)="newMealRequest()">
  <mat-icon>add</mat-icon>
</button>