import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
@Component({
  selector: 'butaco-viandas-normal-user-dates',
  templateUrl: './viandas-normal-user-dates.component.html',
  styleUrls: ['./viandas-normal-user-dates.component.scss'],
})
export class ViandasNormalUserDatesComponent implements OnInit, OnChanges {
  @Input() menus: any;
  @Input() selectedDate: any;
  @Output() evChangeSelectedDay = new EventEmitter();
  @Output() evChangeSelectedWeek = new EventEmitter();
  
  constructor() {}

  ngOnInit(): void {
    dayjs.locale('es');
    //console.log('INICIO', this.menus)
  }
  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes)
    //console.log('CHANGES', this.menus)
  }

  getFullDate(date: Date): string {
    var days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
    return `${days[dayjs(date).day()]} ${dayjs(date).format('DD')}`;
  }
  getDateName(date: Date): string {
    var days = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
    return days[dayjs(date).day()];
  }

  getDateNumber(date: Date): string {
    return dayjs(date).format('DD');
  }

  isSelectedDate(date: Date): boolean {
    return dayjs(date).isSame(dayjs(this.selectedDate), 'day');
  }

  changeSelectedDay(date: Date): void {
    this.evChangeSelectedDay.emit(date);
  }

  changeWeek(action: string): void {
    this.evChangeSelectedWeek.emit(action);
  }
}
