<div class="bottom-sheet-container">
    <div class="header-container">
        <h1 class="header-title">Nuevo Pedido</h1>
        <div class="header-date-controls">

            <button mat-icon-button color='primary' (click)="changeDay('prev')">
                <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>
            </button>
            <h2 class="header-date">{{currentDate | date:"EE dd/MM/yyyy"}}</h2>

            <button mat-icon-button color='primary' (click)="changeDay('next')">
                <mat-icon fontSet="material-icons-outlined">chevron_right</mat-icon>
            </button>

        </div>
    </div>
    <form [formGroup]="newRequest" class="form-container">

        <mat-form-field appearance="outline" style="width: 100%; font-size: 12px; line-height: 1.5">
            <mat-label>Ubicación</mat-label>
            <mat-select formControlName="location" (selectionChange)="changeLocation($event.value)">
                <mat-option *ngFor="let location of data.locations" [value]="location">
                    {{ location.location.name }} ({{ location.location.address }})
                </mat-option>
            </mat-select>
            <mat-error *ngIf="newRequest.controls.location.hasError('required')">Debe seleccionar una ubicación.
            </mat-error>
        </mat-form-field>

        <!-- <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Cantidad de Personas</mat-label>
            <input matInput placeholder="Nombre Completo" formControlName="quantityPersons" type="number" required>
            <mat-icon matPrefix fontSet="material-icons-outlined"> people </mat-icon>
            <mat-error *ngIf="newRequest.controls.quantityPersons.hasError('required')">Debe ingresar la cantidad de
                personas.</mat-error>
        </mat-form-field> -->

        <!-- MENU PRINCIPAL -->
        <div class="title-section-form-container">
            <h2 class="title-section-form">Menú Principal</h2>
            <button mat-icon-button class="icnBtn" color='primary' mat-button [matMenuTriggerFor]="menuMenus"
                [disabled]='typeMenusAvaible.length === 0'>
                <mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>
        <mat-menu #menuMenus="matMenu">
            <button mat-menu-item *ngFor="let typeMenu of typeMenusAvaible"
                (click)="addMenuType(typeMenu)">{{typeMenu}}</button>
        </mat-menu>
        <mat-divider></mat-divider>

        <div class="jornadasContainer">
            <div class="no-location-selected" *ngIf="formArrayMenus.length <= 0">
                <h4>Debe seleccionar una ubicación para poder seleccionar el menú</h4>
            </div>
            <div *ngIf="formArrayMenus.length > 0">
                <div formArrayName="menus"
                    *ngFor="let obj of getControls(newRequest, 'menus') | orderMealsForm; let i = index; let last = last">
                    <div [formGroupName]="i" [class.form-group-to-remove]="formArrayMenus.length > 1" class="row-form">
                        <mat-form-field color="primary" style="width: 70%" appearance="outline">
                            <mat-label>{{menusSelected[i].menuTypeLabel}}</mat-label>
                            <mat-select formControlName="typeMenu"  (selectionChange)="valueChange($event)">
                                <mat-option *ngFor="let menu of menusSelected[i].options" [value]="menu">
                                    {{menu.menu_name | titlecase}}
                                </mat-option>
                                <!-- <div *ngIf="menusSelected[i].menuTypeLabel === 'Entrada'">
                                    <mat-option *ngFor="let menuSpecial of specialMenuEntryOptions" [value]="menuSpecial">
                                        - Especial: {{menuSpecial | titlecase}} -
                                    </mat-option>
                                </div> -->
                                <div *ngIf="menusSelected[i].menuTypeLabel === 'Principal'">
                                    <mat-option *ngFor="let menuSpecial of specialMenuMainOptions"
                                        [value]="menuSpecial">
                                        - Especial: {{menuSpecial.menu_name | titlecase}} -
                                    </mat-option>
                                </div>
                            </mat-select>
                            <!-- <mat-error
                                *ngIf="formArrayMenus.at(i).controls.typeMenu.hasError('required')">
                                Debe seleccionar {{menusSelected[i].menuTypeLabel}}.
                            </mat-error> -->
                        </mat-form-field>

                        <mat-form-field color="primary" style="width: 20%" appearance="outline">
                            <mat-label>Cantidad</mat-label>
                            <input matInput formControlName="quantity" type="number" placeholder="Cantidad" autocomplete="off">
                        </mat-form-field>

                        <button mat-icon-button color='accent' (click)="deleteMenu(i)">
                            <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>


        <!-- BEBIDAS -->
        <div
            *ngIf="newRequest.controls.location.value !== '' && newRequest.controls.location.value.contract.components.includes('Bebida')">
            <div class="title-section-form-container">
                <h2 class="title-section-form">Bebidas</h2>
                <button mat-icon-button class="icnBtn" color='primary' (click)="addDrinkToChoose()">
                    <mat-icon>add_circle_outline</mat-icon>
                </button>
            </div>
            <mat-divider></mat-divider>

            <div class="jornadasContainer">
                <div class="no-location-selected" *ngIf="formArrayDrinks.length <= 0">
                    <h4>Debe seleccionar una ubicación para poder seleccionar el menú</h4>
                </div>
                <div *ngIf="formArrayDrinks.length > 0">
                    <div formArrayName="drinks"
                        *ngFor="let obj of getControls(newRequest, 'drinks') | orderMealsForm; let i = index; let last = last">
                        <div [formGroupName]="i" [class.form-group-to-remove]="formArrayDrinks.length > 1"
                            class="row-form">
                            <mat-form-field color="primary" style="width: 70%" appearance="outline">
                                <mat-label>Bebida</mat-label>
                                <mat-select formControlName="drink" (selectionChange)="valueChange($event)">
                                    <mat-option *ngFor="let drink of data.drinks" [value]="drink">
                                        {{drink.name}} - {{drink.content}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field color="primary" style="width: 20%" appearance="outline">
                                <mat-label>Cantidad</mat-label>
                                <input matInput formControlName="quantity" type="number" placeholder="Cantidad" autocomplete="off">
                            </mat-form-field>

                            <button mat-icon-button color='accent' (click)="deleteDrink(i)">
                                <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="creating-request" *ngIf="creatingNewRequest">
            <h5>Creando pedido</h5>
            <mat-progress-bar color='primary' mode="indeterminate">
            </mat-progress-bar>
        </div>

        <div class="error-quantity-container" *ngIf="errorQuantity.error">
            <mat-icon fontSet="material-icons-outlined" color='accent' style="margin-right: 8px;">report_problem
            </mat-icon>
            <h5>{{errorQuantity.msg}}</h5>
        </div>

        <div class="btn-submit">
            <button mat-stroked-button color="accent" style='margin-right: 16px;' (click)="closeSheet()">
                Cancelar
            </button>
            <button mat-raised-button color="primary" [disabled]='creatingNewRequest' (click)="createNewRequest()">
                Realizar Pedido
            </button>
        </div>
    </form>

</div>