import { NgIf, NgFor, CommonModule } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TypeMenu } from './../../../shared/models/viandas';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import dayjs from 'dayjs';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'butaco-resume-viandas',
  templateUrl: './resume-viandas.component.html',
  styleUrls: ['./resume-viandas.component.scss'],
  standalone: true,
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  imports: [CommonModule, MatTableModule, NgFor, MatButtonModule, NgIf, MatIconModule],
})
export class ResumeViandasComponent implements OnInit {
  @Input() user: any;
  @Input() userRol: string
  @Input() userPreference: any;
  @Input() selectedDate: any;
  @Input() drinkList: any;
  @Input() contractClient: any;
  @Input() weekMenus: any;
  @Input() menuConfig: any;
  @Input() contractUser: any;
  @Input() selectedWeek: any;
  @Input() weekRequestedViandas: any;
  @Input() selectedLocation: any;
  @Input() contractStartDate: any;
  @Input() contractEndDate: any;


  /**
   * detecta los cambios cada vez que se cambia la fecha o la ubicación
   */
  ngOnChanges() {
    this.selectedDate = dayjs(this.selectedDate).format();
    this.generateResumeDate()
  }

  resumeTotalComponents: Array<any> = [];
  resumeOrder: Array<any> = [];

  columnsToDisplay = [''];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: any | null;

  ngOnInit() {
    this.generateResumeDate();
  };

  /**
   * genera cada uno de los array que van a ser mostrados 
   */
  generateResumeDate(): void {
    let viandasRequested = this.generateFilteredDates();
    let resumViandasRequested: Array<any> = [];
    let totalComponents: Array<any> = [];

    viandasRequested.forEach((requestVianda: any) => {
      let resumeRequest = this.generateResumeRequest(requestVianda);

      if (requestVianda.drinks_requested.length > 0) {
        resumeRequest = this.generateResumeDrinks(requestVianda, resumeRequest);
      }

      resumViandasRequested.push(resumeRequest);
      totalComponents = this.generateResumeComponents(resumeRequest, totalComponents);
    });

    this.resumeTotalComponents = totalComponents;
    this.resumeOrder = resumViandasRequested;
    this.setColumnsToDisplay();

    this.resumeOrder = this.resumeOrder.map(resume => {
      resume.components = resume.components.concat(
        totalComponents
          .filter(column => !resume.components.find((resum: any) => resum.name === column.name))
          .map(column => ({ name: column.name, quantity: 0 }))
      );
      return resume;
    });
  }

  /**
   * setea las columnas de la tabla
   */
  setColumnsToDisplay(): void {
    this.columnsToDisplay = this.resumeTotalComponents.map(resum => resum.name);
    this.columnsToDisplay.unshift("Fecha");
    this.columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  }

  /**
   *  genera un array por cada una de las fechas que están en los pedidos de vianda
   * @returns  
   * */
  generateFilteredDates(): any {
    let viandasRequested: Array<any> = [];
    viandasRequested = this.weekRequestedViandas.filter((request: any) => {
      const requestDate = new Date(request.date_meal.seconds * 1000);

      // Filtrar solo los elementos cuyas fechas coinciden con alguna fecha en selectedWeek
      return this.selectedWeek.some((weekDay: any) => {
        const weekDayDate = weekDay.date;
        return (
          requestDate.getDate() === weekDayDate.getDate() &&
          requestDate.getMonth() === weekDayDate.getMonth() &&
          requestDate.getFullYear() === weekDayDate.getFullYear()
        );
      });
    });
    return viandasRequested;
  }

  /**
   * genera un array con la fecha y los componentes de cada vianda 
   * @param requestVianda 
   * @returns 
   */
  generateResumeRequest(requestVianda: any): object {
    let dateMealVianda: any = dayjs(new Date(requestVianda.date_meal.seconds * 1000)).format('DD/MM/YYYY');
    const componentArray: string[] = requestVianda.menus_requested.
      filter((menu: any) => menu.typeMenu.component === "Principal")
      .map((menu: any) => ({
        name: menu.typeMenu.name,
        quantity: menu.quantity,
      }));
    let resumeRequest = {
      date: dateMealVianda,
      components: componentArray
    }

    return resumeRequest;
  }

  generateResumeDrinks(requestVianda: any, resumeRequest: any) {
    const totalQuantity = requestVianda.drinks_requested.reduce((total: number, item: any) => total + item.quantity, 0);
    resumeRequest.components.push({ name: 'Bebida', quantity: totalQuantity });
    return resumeRequest;
  }

  /**
   * genera un array con la suma de cada uno de los componentes en total que hay en los pedidos de vianda
   * @param requestVianda 
   * @param totalComponents 
   * @returns 
   */
  generateResumeComponents(requestVianda: any, totalComponents: any): Array<any> {
    requestVianda.components.forEach((component: any) => {
      let compontentFinded = totalComponents.find((comp: any) => comp.name === component.name);
      if (!compontentFinded) {
        totalComponents.push({ name: component.name, quantity: component.quantity })
      } else {
        compontentFinded.quantity += component.quantity;
      }
    });
    return totalComponents;
  }

  /**
   * funcion que muestra el remito
   */
  seeRemitrance() {
    //aún no está hecho :)
  }
}
