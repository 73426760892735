import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FirstCharUppercasePipe } from './pipes/first-char-uppercase.pipe';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { CheckEditPermissionsPipe } from './pipes/check-edit-permissions/check-edit-permissions.pipe';
import { CheckOrderStatusPipe } from './pipes/check-order-status/check-order-status.pipe';

@NgModule({
    declarations: [
        FirstCharUppercasePipe,
        MessageDialogComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        CheckEditPermissionsPipe,
        CheckOrderStatusPipe,
    ],
    exports: [
        MaterialModule,
        FirstCharUppercasePipe,
    ]
})
export class SharedModule { }
