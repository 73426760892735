import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ViandasMainComponent } from '../viandas-main/viandas-main.component';
import { Router, RouterLink } from '@angular/router';
import { NgForOf, NgIf, NgStyle, NgClass, formatDate, CommonModule } from '@angular/common';

import dayjs from 'dayjs';
import 'dayjs/locale/es'; // Importa el idioma que desees usar (en este caso, español)
import { ContractHelpers } from 'src/app/shared/helpers/contractHelpers';
import { DateHelpers } from 'src/app/shared/helpers/dateHelpers';
import { TarjetaViandaComponent } from './tarjeta-vianda/tarjeta-vianda.component';
import { ViandaStateMachineService } from 'src/app/shared/services/vianda-state-machine.service';
import { ConfigState } from 'src/app/shared/interfaces/configState-interface';
import { ViandasService } from 'src/app/shared/services/viandas.service';
import { Pedido } from 'src/app/shared/models/viandas';
import { DeliveryService } from 'src/app/shared/services/delivery-service.service';
import { viandaStatus } from 'src/app/shared/models/enums';

@Component({
  selector: 'butaco-pedido-de-vianda',
  templateUrl: './pedido-de-vianda.component.html',
  styleUrls: ['./pedido-de-vianda.component.scss'],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    forwardRef(() => ViandasMainComponent),
    RouterLink,
    NgIf,
    NgForOf,
    NgClass,
    NgStyle,
    TarjetaViandaComponent,
  ],
  standalone: true,
  // schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class PedidoDeViandaComponent implements OnInit {
  @Input() user: any;
  @Input() userRol: string;
  @Input() userPreference: any;
  @Input() selectedDate: any;
  @Input() drinkList: any;
  @Input() contractClient: any;
  @Input() weekMenus: any;
  @Input() menuConfig: any;
  @Input() specialMenus: any;
  @Input() contractUser: any;
  @Input() selectedWeek: any;
  @Input() weekRequestedViandas: any;
  @Input() selectedLocation: any;
  @Input() contractStartDate: any;
  @Input() contractEndDate: any;
  @Input() locationsAvailable: Array<any>;
  menusPublished: boolean;
  currentDate: Date;
  weekData: Array<any> = [];
  viandaId: string;
  configState: ConfigState;
  constructor(
    private route: Router,
    private viandasServ: ViandasService,
    private contractHelpers: ContractHelpers,
    private dateHelpers: DateHelpers,
    private viandaStateMachine: ViandaStateMachineService,
    private deliveryServ: DeliveryService
  ) { }

  ngOnInit() {
    this.selectedDate = dayjs(this.selectedDate).format();
    this.configState = this.viandaStateMachine.getConfigState();

    const tiempoTranscurrido = Date.now();
    this.currentDate = new Date(tiempoTranscurrido);
    this.getDeliveryOrders();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedDate = dayjs(this.selectedDate).format();
    this.getDeliveryOrders();
  }

  generateWeekData() {
    this.validateMenusPublished();
    this.weekData = [];
    let availableDay: any;
    this.viandaId = null;

    this.selectedWeek.forEach((weekDay: any) => {
      //entro a cada día de la semana
      let status = 'empty';
      let canAddOrder: boolean

      //el dia esta en el contrato
      availableDay = this.contractHelpers.checkDayAvailability(
        weekDay,
        this.contractUser,
        this.contractStartDate,
        this.contractEndDate
      );

      //el dia ya paso o no
      let beforeCurrentDate = true;

      let configDay = this.configState?.find(
        (c) => c.day === dayjs(weekDay.date).day()
      );
      if (
        (configDay &&
          dayjs(weekDay.date)
            .startOf('date')
            .isSame(dayjs(this.currentDate).startOf('date')) &&
          dayjs(this.currentDate).hour() < configDay.pendingTo.hour) ||
        dayjs(weekDay.date)
          .startOf('date')
          .isAfter(dayjs(this.currentDate).startOf('date'))
      ) {
        beforeCurrentDate = false;
      }

      if (availableDay && beforeCurrentDate && status === 'empty') {
        availableDay = false;
        status = 'No habilitado';
      }

      // puede crear nuevo pedido
      let canCreateOrder = this.viandaStateMachine.canCreate(
        dayjs(this.currentDate),
        dayjs(weekDay.date)
      );

      //se puede editar
      let canEdit = this.viandaStateMachine.canEdit(
        dayjs(this.currentDate),
        dayjs(weekDay.date)
      );

      let editPermissions = this.getEditPermissions(canEdit);

      let viandaArray: Array<any> = []; //esto se va a cambiar a que cada pedido tenga su array, pero se deja por los casos en que no haya viandas
      let drinksArray: Array<any> = [];
      let dayRequest: any;
      let weekDayItems: any[] = []; //weekDayItems es un array de los pedidos formateados para el componente tarjeta

      if (this.weekRequestedViandas) {
        //busca las viandas de la semana para el dia weekDay y las ordena de mas reciente a mas antigua
        dayRequest = this.weekRequestedViandas
          .filter((request: any) => {
            const requestDate = new Date(request.date_meal.seconds * 1000);
            return (
              requestDate.getDate() === weekDay.date.getDate() &&
              requestDate.getMonth() === weekDay.date.getMonth() &&
              requestDate.getFullYear() === weekDay.date.getFullYear()
            );
          })
          .sort((a: any, b: any) => {
            const dateA = dayjs
              .unix(a.date_request.seconds)
              .add(a.date_request.nanoseconds / 1000000, 'millisecond')
              .toDate();
            const dateB = dayjs
              .unix(b.date_request.seconds)
              .add(b.date_request.nanoseconds / 1000000, 'millisecond')
              .toDate();

            return dateB.getTime() - dateA.getTime(); // Ordena de más reciente a más antiguo
          });

        if (dayRequest && this.menusPublished) {
          //saque available day porque sino no arma el array para dias pasados
          //genero un array con los objetos menus pedidos

          dayRequest.forEach((request: any) => {
            if (request.delivery_order?.status) {
              if (canAddOrder != false) {
                canAddOrder = request.delivery_order.status != 'pendiente'
              }
            } else {
              if(request.status_request === viandaStatus.AprobacionPendiente){
                canAddOrder = false
              }
            }

            if(!canCreateOrder){
              canAddOrder = false
            }

            viandaArray = [];
            drinksArray = [];
            request.menus_requested.forEach((menuRequested: any) => {
              let newVianda = this.viandasServ.generateMenuItem(
                menuRequested.quantity,
                menuRequested.typeMenu,
                menuRequested.typeMenu.name,
                true,
                availableDay,
                'vianda'
              );
              newVianda.id = request.id;
              viandaArray.push(newVianda);
            });

            request.drinks_requested.forEach((drinkRequested: any) => {
              drinksArray.push({
                name: drinkRequested.drink.name,
                content: drinkRequested.drink.content,
                quantity: drinkRequested.quantity,
              });
            });
            status = request.status_request;

            weekDayItems.push({
              viandas: viandaArray,
              drinks: drinksArray,
              status: status,
              id: request.id,
            });
          });
        }
      } else if (
        ((!dayRequest && !availableDay) || (dayRequest && !availableDay)) &&
        this.weekMenus
      ) {
        //si no hay vianda y el día no está disponible en el contrato

        if (this.weekMenus) {
          let menuDay = this.weekMenus.find((menu: any) =>
            menu != null
              ? this.dateHelpers.getWeekDayNumber(menu.day)
              : null === this.dateHelpers.getWeekDayNumber(weekDay.day)
          );

          if (menuDay) {
            menuDay.menu.forEach((menu: any) => {
              viandaArray.push(
                this.viandasServ.generateMenuItem(
                  undefined,
                  undefined,
                  { name: menu.name },
                  true,
                  availableDay,
                  'vianda'
                )
              );
            });
          }
        }
        status = 'No habilitado';
      } else if (!this.menusPublished) {
        viandaArray.push({});
        status = 'No habilitado';
      } else {
        viandaArray.push({});
      }

      const fechaDay = dayjs(weekDay.date); //obtengo la fecha del dia

      let weekDayData = {
        editPermissions: editPermissions,
        canCreateOrder: canCreateOrder,
        dayName: weekDay.day,
        date: dayjs(weekDay.date).format('DD/MM'),
        dateNewRequest: fechaDay,
        // vianda: viandaArray,
        // drink: drinksArray,
        location: this.selectedLocation.name,
        status: status,
        availableDay: availableDay,
        beforeCurrentDate: beforeCurrentDate,
        // id: dayRequest?.id,
        items: weekDayItems,
        canAddOrder: canAddOrder
      };
      //agrego el objeto al array de info de la semana y reinicio el arreglo de menus
      this.weekData.push(weekDayData);
      viandaArray = [];

    });
  }

  validateMenusPublished() {
    let menu = this.weekMenus.find((menu: any) =>
      menu != null ? menu.published : null === true
    );
    if (menu) {
      this.menusPublished = true && menu.published;
    } else {
      this.menusPublished = false;
    }
  }

  returnViandaAndDayData(dayData: any, item: any) {
    let vianda = this.weekRequestedViandas.find(
      (weekVianda: Pedido) => weekVianda.id === item.id
    );
    return { vianda: vianda, dayData: dayData };
  }

  orderVianda(date: any) {
    this.viandasServ.redirectToNewRequest(
      'nuevo pedido',
      date,
      null,
      this.selectedLocation
    );
  }

  canCreateNewRequest(): boolean {

    return false;
  }

  getEditPermissions(canEdit: string): any {
    const permissions = {
      userCanEdit: false,
      butacoCanEdit: true,
      status: 'pending',
    };
    if (canEdit === 'all') {
      permissions.userCanEdit = true;
      permissions.butacoCanEdit = true;
      permissions.status = 'accept';
    } else if (canEdit === 'butaco') {
      permissions.userCanEdit = true;
      permissions.butacoCanEdit = true;
      permissions.status = 'pending';
    }

    return permissions;
  }

  getDeliveryOrders(): void {
    const ordersID: string[] = this.weekRequestedViandas
      .map((vianda: any) => vianda.delivery_order_id)
      .filter((id: any) => id !== '');
    this.deliveryServ.getDeliveryByArray(ordersID).then((deliveryOrders) => {
      const deliveryOrderMap = new Map();
      deliveryOrders.forEach((order) => {
        deliveryOrderMap.set(order.id, order);
      });

      this.weekRequestedViandas.forEach((data: any) => {
        const deliveryOrder = deliveryOrderMap.get(data.delivery_order_id);
        if (deliveryOrder) {
          data.delivery_order = deliveryOrder;
        }
      });
      this.generateWeekData();
    });
  }

  //---------------------------------------

  isBeforeCurrentDate(date: any, configDay: any) {
    return !(
      (configDay &&
        dayjs(date)
          .startOf('date')
          .isSame(dayjs(this.currentDate).startOf('date')) &&
        dayjs(this.currentDate).hour() < configDay.pendingTo.hour) ||
      dayjs(date)
        .startOf('date')
        .isAfter(dayjs(this.currentDate).startOf('date'))
    );
  }

  requestTemplate(dayData: any): 'notAvailable' | 'new' | 'requests' | 'outOfRange' | 'addNew'{
    if(dayData.status == 'No habilitado'){
      return 'notAvailable';
    }

    if(dayData.status == 'empty' ){
      if(dayData.canCreateOrder){
        return 'new';
      } else {

        if(dayData.editPermissions.userCanEdit){
          return 'requests';
        } else {
          return 'outOfRange';
        }
        
      }
      // if(dayData.editPermissions.userCanEdit){
      //   return 'requests';
      // }
    }

    // Requests Cards
    if (dayData.status != 'empty' && this.userRol === 'Responsable de sucursal' && dayData.canAddOrder){
      return 'addNew'
    }

    if(dayData.status != 'empty' && dayData.status != 'No habilitado'){
      return 'requests';
    } 
    return 'notAvailable';
  }
}
