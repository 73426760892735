import {
  Component,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { PedidoDeViandaComponent } from '../pedido-de-vianda.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { CommonModule, NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { CheckEditPermissionsPipe } from 'src/app/shared/pipes/check-edit-permissions/check-edit-permissions.pipe';
import { CheckOrderStatusPipe } from 'src/app/shared/pipes/check-order-status/check-order-status.pipe';
import { ViandaStateMachineService } from 'src/app/shared/services/vianda-state-machine.service';
import { ViandasService } from 'src/app/shared/services/viandas.service';
import { EventEmitter } from 'stream';
import { Pedido } from 'src/app/shared/models/viandas';
import { DeliveryService } from 'src/app/shared/services/delivery-service.service';
import { DateHelpers } from 'src/app/shared/helpers/dateHelpers';
import dayjs from 'dayjs';
import {
  CdkOverlayOrigin,
  Overlay,
  OverlayModule,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'butaco-tarjeta-vianda',
  templateUrl: './tarjeta-vianda.component.html',
  styleUrls: ['./tarjeta-vianda.component.scss'],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    forwardRef(() => PedidoDeViandaComponent),
    RouterLink,
    NgIf,
    NgForOf,
    NgClass,
    NgStyle,
    CheckEditPermissionsPipe,
    CheckOrderStatusPipe,
    OverlayModule,
  ],
  standalone: true,
})
export class TarjetaViandaComponent {
  @ViewChild('contentTemplate', { static: true })
  contentTemplate: TemplateRef<any>;
  @ViewChild(CdkOverlayOrigin) trigger: CdkOverlayOrigin;
  @Input() set cardData(data: any) {
    this.dayData = data.dayData;
    this.vianda = data.vianda;
  }
  @Input() userRol: String;
  @Input() selectedLocation: any;
  @Input() addNewRequest: (date: any) => void;

  dayData: any;
  vianda: Pedido;

  groupedMenus: any[] = [];
  totalDrinks: number = 0;

  isOpen = false;
  constructor(
    private route: Router,
    private viandasServ: ViandasService,
    private viandaStateMachine: ViandaStateMachineService,
    private deliveryServ: DeliveryService,
    private dateHelper: DateHelpers,
    private overlay: Overlay
  ) {}

  ngOnInit() {
    this.getTotalDrinks();
    this.getTotalByComponent();
    this.sortAndGroupMenus(this.vianda.menus_requested);
  }

  cancelRequest() {
    this.viandaStateMachine.changeStatus(this.vianda, 'cancelled');

    //esto esta feo aca creo
    if (this.vianda.delivery_order_id) {
      this.vianda.status_request = 'cancelled';
      this.deliveryServ.editItemDelivery(
        this.vianda,
        this.vianda.id,
        this.vianda.delivery_order_id
      );
    }
  }

  editVianda(date: any, order: any) {
    this.viandasServ.redirectToNewRequest('edicion de pedido', date, order, this.selectedLocation);
  }

  //-------------------------BUTTONS-------------------------//
  canEdit() {
    return (
      this.vianda.delivery_order?.status === 'pendiente' ||
      !this.vianda.delivery_order
    );
  }

  addNew() {
    const date = this.dateHelper.convertDate(this.vianda.date_meal);
    const formatedDate = dayjs(date).format();

    if (this.addNewRequest) {
      this.addNewRequest(formatedDate);
    }
  }

  //-------------------------TOTAL DE NO PRINCIPALES  -------------------------//
  getTotalDrinks() {
    let total = 0;
    this.vianda.drinks_requested.forEach((element) => {
      total += element.quantity;
    });
    this.totalDrinks = total;
    return total;
  }

  getTotalByComponent() {
    const totalByComponent: { [key: string]: number } = {};
    this.vianda.menus_requested.forEach((element) => {
      const component = element.typeMenu.component;
      if (component !== 'Principal') {
        if (!totalByComponent[component]) {
          totalByComponent[component] = 0;
        }
        totalByComponent[component] += element.quantity;
      }
    });
    const resultArray = Object.keys(totalByComponent).map((key) => ({
      [key]: totalByComponent[key],
    }));
    return resultArray;
  }

  //-------------------------SORT-------------------------//

  sortAndGroupMenus(menus: any[]): void {
    const groupedMenus: { [key: string]: { quantity: number; typeMenu: any } } =
      {};
    const finalMenuList: any[] = [];
    const addedComponents: { [key: string]: boolean } = {};

    //agrupa principal y no principal
    menus.forEach((menu) => {
      const component = menu.typeMenu.component;

      if (component !== 'Principal') {
        if (!groupedMenus[component]) {
          groupedMenus[component] = {
            quantity: 0,
            typeMenu: { ...menu.typeMenu },
          };
        }
        groupedMenus[component].quantity += menu.quantity;
      } else {
        // añadir menus no principal no agrgados
        Object.keys(groupedMenus).forEach((key) => {
          if (!addedComponents[key]) {
            finalMenuList.push(groupedMenus[key]);
            addedComponents[key] = true;
          }
        });

        // Añadir el menú "Principal"
        finalMenuList.push(menu);
      }
    });

    // añade cualquier componente no principal no se haya añadido
    Object.keys(groupedMenus).forEach((key) => {
      if (!addedComponents[key]) {
        finalMenuList.push(groupedMenus[key]);
      }
    });

    // ordenar el array final por order
    finalMenuList.sort((a, b) => {
      const orderA = a.typeMenu.order || Number.MAX_VALUE;
      const orderB = b.typeMenu.order || Number.MAX_VALUE;
      return orderA - orderB;
    });

    this.groupedMenus = finalMenuList;
  }
}
