import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common.service';
import { ViandasAdminComponent } from '../viandas-admin/viandas-admin.component';
import { ViandasService } from 'src/app/shared/services/viandas.service';

@Component({
  selector: 'butaco-viandas-new-request-dialog',
  templateUrl: './viandas-new-request-dialog.component.html',
  styleUrls: ['./viandas-new-request-dialog.component.scss'],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-AR' }, //replace "en-US" with your locale
    //otherProviders...
  ],
})
export class ViandasNewRequestDialogComponent implements OnInit, OnDestroy {
  newRequest = this.fb.group({
    location: ['', [Validators.required]],
    // quantityPersons: [1, [Validators.required]],
    menus: this.formBuild.array([]),
    drinks: this.formBuild.array([]),
  });

  menusSelected: any[] = [];
  isLoading = false;
  creatingNewRequest = false;

  menus: any;

  typeMenusAvaible: any[] = [];
  specialMenus: any[] = [];
  currentDate: any;
  specialMenusSub: Subscription;
  errorQuantity = {
    error: false,
    msg: 'No error',
  };

  specialMenuEntryOptions: any[] = [];
  specialMenuMainOptions: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // private _bottomSheetRef: MatBottomSheetRef<ViandasAdminComponent>,
    // @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private formBuild: UntypedFormBuilder,
    private viandasServ: ViandasService,
    private snackBar: MatSnackBar,
    private commonServ: CommonService
  ) {}

  ngOnInit(): void {
    this.currentDate = this.data.date ? this.data.date.toDate() : dayjs();
    this.getMenuForSingleDate(dayjs(this.currentDate).format('YYYYMMDD'));
    this.getSpecialMenu();
    if (this.data.locations.length === 1) {
      this.newRequest.controls.location.setValue(this.data.locations[0]);
      this.newRequest.controls.location.disable();
      setTimeout(() => {
        this.changeLocation(this.data.locations[0]);
      }, 200);
    }
  }

  ngOnDestroy(): void {
    if (this.specialMenusSub) {
    }
  }
  getSpecialMenu(): void {
    if (!this.specialMenusSub) {
      this.specialMenusSub = this.commonServ.getSpecialMenu().subscribe(
        (res) => {
          if (res) {
            // console.log(res);
            this.specialMenus = res;

            // this.specialMenuEntryOptions = this.specialMenus.map((menu:any) => menu.type);
            this.specialMenuMainOptions = this.specialMenus.map((menu: any) => {
              return {
                name: menu.description,
                component: 'Principal',
                menu_name: menu.name,
              };
            });
          }
        },
        (error) => console.error(error)
      );
    }
  }

  getMenuForSingleDate(date: string): void {
    this.isLoading = true;
    // Se envie el principio del dia y el final porque el query a FB toma las horas en cuenta en el desde hasta
    // console.log(startDay, endDay);
    this.viandasServ
      .getMenuForSingleDate(date)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          // console.log(res);
          this.isLoading = false;
          this.menus = res;
        },
        (err: any) => console.error(err)
      );
  }

  addAdditionalItemGroupToForm(formArrayName: string, index?: number): void {
    if (formArrayName === 'menus') {
      if (index !== undefined) {
        this.formArrayMenus.insert(index, this.addAdditionalMenu());
      } else {
        (<UntypedFormArray>this.newRequest.get(formArrayName)).push(
          this.addAdditionalMenu()
        );
      }
    } else {
      (<UntypedFormArray>this.newRequest.get(formArrayName)).push(
        this.addAdditionalDrink()
      );
    }
  }

  private addAdditionalMenu(): UntypedFormGroup {
    return this.formBuild.group({
      typeMenu: new UntypedFormControl('', Validators.required),
      quantity: new UntypedFormControl(1, Validators.required),
    });
  }
  private addAdditionalDrink(): UntypedFormGroup {
    return this.formBuild.group({
      drink: new UntypedFormControl('', Validators.required),
      quantity: new UntypedFormControl(1, Validators.required),
    });
  }

  changeLocation(location: any): void {
    // console.log(location);
    this.newRequest.controls.menus = this.formBuild.array(
      [],
      Validators.required
    );
    this.newRequest.controls.drinks = this.formBuild.array(
      [],
      Validators.required
    );
    this.typeMenusAvaible = [];
    if (this.menus) {
      location.contract.components.forEach((comp: any) => {
        if (comp !== 'Bebida') {
          const newMenu = {
            menuTypeLabel: comp,
            options: this.menus.menu.filter(
              (menu: any) =>
                menu.component === comp &&
                menu.type === location.contract.typeMenu
            ),
          };
          // console.log(newMenu);
          this.typeMenusAvaible.push(comp);
          this.menusSelected.push(newMenu);
          this.addAdditionalItemGroupToForm('menus');
        }
        if (comp === 'Bebida') {
          const newDrink = {
            menuTypeLabel: comp,
            options: [
              {
                name: 'Coca-Cola',
                typeMenu: comp,
              },
            ],
          };
          this.addAdditionalItemGroupToForm('drinks');
        }
      });
    }
  }

  addMenuType(menuTypeSelected: string): void {
    const newMenu = {
      menuTypeLabel: menuTypeSelected,
      options: this.menus.menu.filter(
        (menu: any) =>
          menu.component === menuTypeSelected &&
          menu.type ===
            this.newRequest.controls.location.value.contract.typeMenu
      ),
    };
    // console.log(newMenu);

    const lastIndexOf = this.menusSelected.findIndex(
      (menu: any) => menu.menuTypeLabel === menuTypeSelected
    );

    this.menusSelected.splice(lastIndexOf, 0, newMenu);

    this.addAdditionalItemGroupToForm('menus', lastIndexOf);
  }

  addDrinkToChoose(): void {
    this.addAdditionalItemGroupToForm('drinks');
  }

  deleteMenu(i: number): void {
    const quantitySameTypeMenu = this.menusSelected.filter(
      (menu: any) => menu.menuTypeLabel === this.menusSelected[i].menuTypeLabel
    ).length;
    // console.log(quantitySameTypeMenu);

    if (quantitySameTypeMenu <= 1) {
      this.snackBar.open('❌ Debe seleccionar al menos un menú', '', {
        duration: 3000,
      });
    } else {
      this.menusSelected.splice(i, 1);
      this.formArrayMenus.removeAt(i);
    }
  }
  deleteDrink(i: number): void {
    const quantityDrinks = this.formArrayDrinks.controls.length;
    // console.log(quantitySameTypeMenu);

    if (quantityDrinks <= 1) {
      this.snackBar.open('❌ Debe seleccionar al menos un una bebida', '', {
        duration: 3000,
      });
    } else {
      this.formArrayDrinks.removeAt(i);
    }
  }

  closeSheet(): void {
    //TODO: Check if the form is touched
    // this._bottomSheetRef.dismiss(undefined);
    this.dialogRef.close();
  }

  getControls(fg: UntypedFormGroup, key: string) {
    return (<UntypedFormArray>fg.controls[key]).controls;
  }

  get formArrayMenus() {
    // Typecast, because: reasons
    // https://github.com/angular/angular-cli/issues/6099
    return <UntypedFormArray>this.newRequest.get('menus');
  }
  get formArrayDrinks() {
    // Typecast, because: reasons
    // https://github.com/angular/angular-cli/issues/6099
    return <UntypedFormArray>this.newRequest.get('drinks');
  }

  valueChange(value: any) {
    // console.log(value);
    if (this.errorQuantity.error === true) {
      this.errorQuantity = {
        error: false,
        msg: 'No error',
      };
    }
  }

  createNewRequest(): void {
    if (this.newRequest.valid) {
      const currentContract = this.newRequest.controls.location.value.contract;
      let quantitiesEntry = 0;
      let quantitiesMain = 0;
      let quantitiesDrinks = 0;
      this.errorQuantity = {
        error: false,
        msg: 'No error',
      };
      quantitiesMain = this.formArrayMenus.value
        .filter((menu: any) => menu.typeMenu.component === 'Principal')
        .map((men: any) => men.quantity)
        .reduce((a: number, b: number) => a + b);

      if (currentContract.components.includes('Entrada')) {
        quantitiesEntry = this.formArrayMenus.value
          .filter((menu: any) => menu.typeMenu.component === 'Entrada')
          .map((men: any) => men.quantity)
          .reduce((a: number, b: number) => a + b);

        if (quantitiesEntry > quantitiesMain) {
          this.errorQuantity = {
            error: true,
            msg: 'Hay más entradas seleccionadas que platos principales',
          };
        }
      }
      if (currentContract.components.includes('Bebida')) {
        quantitiesDrinks = this.formArrayDrinks.value
          .map((drink: any) => drink.quantity)
          .reduce((a: number, b: number) => a + b);

        if (quantitiesDrinks > quantitiesMain) {
          this.errorQuantity = {
            error: true,
            msg: 'Hay más bebidas seleccionadas que platos principales',
          };
        }
      }
      if (this.errorQuantity.error === false) {
        this.creatingNewRequest = true;
        const newRequest = {
          date_request: new Date(),
          date_Meal: dayjs(this.currentDate).toDate(),
          location: this.newRequest.controls.location.value.location,
          requested_to: this.data.user,
          requester: this.data.userGral ? this.data.userGral : this.data.user,
          company: {
            companyId: this.data.company.id,
            companyName: this.data.company.companyName,
            contractId: this.data.locations[0].contract.id,
          },
          // quantity_meals: this.newRequest.controls.quantityPersons.value,
          menus_requested: this.formArrayMenus.value,
          drinks_requested: this.formArrayDrinks.value,
          //TODO: If it is more than 10am must be approved, else rejected
          // Status: - Accepted
          // Status: - Rejected
          // Status: - Pending to delivery
          // Status: - Doing
          // Status: - To Do
          // Status: - Delivered
          // Status: - Delayed
          status_request: 'accepted',
          status_making: 'accepted',
          type: 'Invitation',
        };
        this.viandasServ
          .orderMeal(newRequest)
          .then((res: any) => {
            // console.log(res);
            // this._bottomSheetRef.dismiss(true);
            this.dialogRef.close(true);
            this.creatingNewRequest = false;
          })
          .catch((err: any) => {
            this.snackBar.open(
              '❌ Ocurrio un erro y no se pudo realizar el pedido, por favor intente más tarde nuevamente.',
              '',
              {
                duration: 3000,
              }
            );
            this.creatingNewRequest = false;
          });
      }
    } else {
      this.showValidationMsg(this.newRequest);
    }
  }

  showValidationMsg(formGroup: UntypedFormGroup): void {
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: UntypedFormControl = formGroup.controls[key] as UntypedFormControl;

        if (Object.keys(control).includes('controls')) {
          const formGroupChild: UntypedFormGroup = formGroup.controls[
            key
          ] as UntypedFormGroup;
          this.showValidationMsg(formGroupChild);
        }
        control.updateValueAndValidity();
        control.markAsTouched();
      }
    }
  }

  changeDay(action: string) {
    let newDate;
    if (action === 'next') {
      newDate = dayjs(this.currentDate).add(1, 'day');
    } else {
      newDate = dayjs(this.currentDate).subtract(1, 'day');
    }
    this.currentDate = newDate;
  }
}
