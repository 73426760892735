<div class="viandas-detail">
  <div class="viandas-actions">
    <div class="section-header">
      <div class="action-icons">
        <div class="search-container formSearch">
          <mat-form-field appearance="fill" style="display: flex; align-items: center">
            <input matInput [formControl]="search" placeholder="Buscar pedido" autocomplete="off" />
            <mat-icon color="primary" matPrefix>search</mat-icon>
            <mat-spinner *ngIf="searchingItems" diameter="20" matSuffix color="warn">
            </mat-spinner>
          </mat-form-field>
          <!-- Agrega un botón para restablecer los datos -->
          <button mat-icon-button (click)="resetData()"><mat-icon>restart_alt</mat-icon></button>

        </div>
      </div>
    </div>
    <div class="table">
      <div class="loading">
        <mat-progress-bar color="primary" mode="indeterminate" *ngIf="loading">
        </mat-progress-bar>
      </div>
      <div *ngIf="(!viandasDetailArray || viandasDetailArray.length === 0) && !loading" class="no-results">
        <mat-icon fontSet="material-icons-outlined" color="primary">
          search_off
        </mat-icon>
        <p>No se encontraron pedidos de vianda</p>
      </div>

      <div class="detail-table" *ngIf="!loading && viandasDetailArray && viandasDetailArray.length > 0">
        <table>
          <tr class="theader">
            <td class="user-column">Usuario</td>
            <td class="quantity-column">Cantidad</td>
            <td class="status-column">Estado</td>
            <td class="date-column">Fecha pedido</td>
            <td class="action-column">Detalle</td>
          </tr>
          <tr class="table-row" *ngFor="let vianda of viandasDetailArray" class="tbody">
            <td class="user-column">
              {{ vianda.user }}
            </td>
            <td class="quantity-column">
              {{vianda.quantity}}
            </td>
            <td class="status-column">
              <div class="pending-label" *ngIf="vianda.status ==='pending'">
                <mat-icon class="user-status-icon" fontSet="material-icons-outlined">pending</mat-icon>
                Pendiente
              </div>
              <div class="pending-label" *ngIf="vianda.status ==='cancellation pending'">
                <mat-icon class="user-status-icon" fontSet="material-icons-outlined">pending</mat-icon>
                Pendiente de cancelación
              </div>
              <div class="active-label" *ngIf="vianda.status ==='accepted'">
                <mat-icon class="user-status-icon" fontSet="material-icons-outlined"
                  color="primary">check_circle</mat-icon>
                Aceptada
              </div>
              <div class="inactive-label" *ngIf="vianda.status==='cancelled'">
                <mat-icon class="user-status-icon" fontSet="material-icons-outlined" fontColor="warn">cancel</mat-icon>
                Cancelada
              </div>
            </td>
            <td class="date-column">
              {{vianda.date}}
            </td>
            <td class="action-column">

              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon fontSet="material-icons-outlined" color="primary" disabled>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="inactive-label">
                <button mat-menu-item (click)="
                redirectToNewRequest(
                  selectedDate,
                  vianda.id
                )
              ">
                  <mat-icon fontSet="material-icons-outlined" color="primary">edit</mat-icon>
                  Editar pedido
                </button>

                 <div class="cancel">
                  <button mat-menu-item (click)="changeStatus('cancelled',vianda)" *ngIf="vianda.status === 'accepted'">
                    <mat-icon fontSet="material-icons-outlined" 
                      >cancel</mat-icon
                    >
                    Cancelar
                  </button>
                </div>

                <div class="primary">
                  <button mat-menu-item (click)="changeStatus('accepted',vianda)" *ngIf="vianda.status === 'cancelled'">
                    <mat-icon fontSet="material-icons-outlined" color="primary"
                      >check_circle</mat-icon
                    >
                    Aceptar
                  </button>
                </div> 
              </mat-menu>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <mat-paginator [length]="viandasDetailArray.length" [pageSize]="pageSize" [pageIndex]="currentPage"
    [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPageChange($event)" aria-label="Select page">
  </mat-paginator>


</div>