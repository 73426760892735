import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import localeEsArExt from '@angular/common/locales/extra/es-AR';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { getEspPaginatorIntl } from './material/esp-paginator';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ViandasModule } from './pages/viandas/viandas.module';
import { PagesModule } from './pages/pages.module';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { HttpClientModule } from '@angular/common/http';
import {AngularFireAnalyticsModule, UserTrackingService} from "@angular/fire/compat/analytics";

registerLocaleData(localeEsAr, 'es-AR', localeEsArExt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    //FormsModule,
    //ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFireMessagingModule,
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getEspPaginatorIntl() },
    UserTrackingService
    // {
    //   provide: USE_FUNCTIONS_EMULATOR,
    //   useValue: environment.firebase.emulator ? ['localhost', 5001] : undefined,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
