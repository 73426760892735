import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { redirectUnauthorizedTo, redirectLoggedInTo, canActivate } from '@angular/fire/compat/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);
const redirectLoggedInToLayout = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./pages/layout/layout-main.module').then(m => m.LayoutMainModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'auth', loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule),
    ...canActivate(redirectLoggedInToLayout)
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


