<div class="container">

    <div class="title-section">
        <h2 class="titleSection">Esta Semana</h2>

    </div>
    <div *ngIf="menus.length>1" class="date-picker-section">
        <button mat-icon-button color="primary" (click)="changeWeek('prev')">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <div class="dates-container">
            <div *ngFor="let menu of menus" class="date" [ngClass]="{'selectedDate': isSelectedDate(menu.date)}"
                (click)="changeSelectedDay(menu.date)">
                <h5>{{getDateName(menu.date)}}</h5>
                <h4>{{getDateNumber(menu.date)}}</h4>
            </div>
        </div>
        <button mat-icon-button color="primary" (click)="changeWeek('next')">
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>
    <div class="days-menu-section">
        <div *ngFor="let menu of menus;"
            [ngClass]="{'day':menu.available_to_order || menu.alredy_has_order, 'day-dissabled': !menu.available_to_order && !menu.alredy_has_order, 'selectedDate': isSelectedDate(menu.date)}"
        >
            <ng-container *ngIf="menu.available_to_order || menu.alredy_has_order; else diaNoHabilitado">
                <div 
                    class="day-data"
                    (click)="changeSelectedDay(menu.date)"
                >
                    <h5 class="day-data-name">{{getFullDate(menu.date)}}</h5>
                    
                    <h5 *ngIf="menu.menus_requested.length > 0" class="day-data-location"></h5>

                    <ng-container *ngIf="menu.status_request !== 'cancelled' && menu.status_request !== 'cancellationPending' ; else pedidoCancelado">
                        <div *ngIf="menu.menus_requested.length !== 0">
                            <h5 class="day-data-notation" *ngIf="menu.status_request==='accepted' &&  menu.alredy_has_order">
                                Pedido Realizado
                            </h5>
                            <h5 class="day-data-notation" *ngIf="menu.status_request==='acceptancePending' && menu.alredy_has_order ">
                                Pedido Pendiente de Aceptar
                            </h5>
                            <h5 class="day-data-notation" *ngIf="menu.available_to_order && !menu.alredy_has_order">
                                Por favor guarde su pedido
                            </h5>
                        </div>
                        <div *ngIf="menu.menus_requested.length === 0">
                            <h5 class="day-data-notation" *ngIf="menu.available_to_order">Pedido
                                aún sin confirmar</h5>
                        </div>
                    </ng-container>
                    <ng-template #pedidoCancelado>
                        <div *ngIf="menu.status_request === 'cancellationPending'">
                            <h5 class="day-data-notation">
                                Cancelacion Pendiente
                            </h5>
                        </div>
                        <div *ngIf="menu.status_request === 'cancelled'">
                            <h5 class="day-data-notation">
                                Pedido Cancelado
                            </h5>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
            <ng-template #diaNoHabilitado>
                <div class="day-data">
                <h5 class="day-data-name">{{getFullDate(menu.date)}}</h5>
                
                <h5 *ngIf="menu.menus_requested.length > 0" class="day-data-location"></h5>
                
                <div *ngIf="menu.menus_requested.length === 0">
                    <h5 class="day-data-notation" *ngIf="!menu.available_to_order">Día no
                        habilitado</h5>
                </div>
            </div>
            </ng-template>
            
            <div class="day-icon">
                <div *ngIf="!menu.alredy_has_order" >
                    <img *ngIf="menu.available_to_order" style="opacity: 0.5;"
                        src="/assets/images/exclamation_circle_outline.svg">
                </div>
                <ng-container *ngIf="menu.status_request === 'accepted' || menu.status_request === 'acceptancePending'; else iconoCancelado">
                    <div *ngIf="menu.menus_requested.length > 0 && menu.alredy_has_order && menu.status_request === 'accepted'">
                        <img style="opacity: 0.7;" src="/assets/images/check_circle_outline.svg">
                    </div>
                    <div *ngIf="menu.menus_requested.length > 0 && menu.alredy_has_order && menu.status_request === 'acceptancePending'">
                        <img style="opacity: 0.7;" src="/assets/images/checkPending_circle_outline.svg">
                    </div>

                </ng-container>
                <ng-template #iconoCancelado>
                    <div>
                        <div *ngIf="menu.status_request === 'cancellationPending'">
                            <img *ngIf="menu.available_to_order && menu.alredy_has_order" style="opacity: 0.5;"
                                src="/assets/images/cancellPending_circle_outline.svg" matTooltip="Pedido Pendiente de cancelacion">
                        </div>
                        <div *ngIf="menu.status_request === 'cancelled'">
                            <img *ngIf="menu.available_to_order && menu.alredy_has_order" style="opacity: 0.5;"
                                src="/assets/images/cancell_circle_outline.svg" matTooltip="Pedido Cancelado">
                        </div>
                        <img *ngIf="!menu.available_to_order" style="opacity: 0.1;"
                            src="/assets/images/none_circle_outline.svg" matTooltip="Día deshabilitado">
                    </div>
                </ng-template>
                
            </div>
        </div>
    </div>
</div>