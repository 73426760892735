<div class="container">
  <div class="menu-data">
    <div class="menu-card" *ngFor="let dayData of weekData; let isLast = last">
      <div class="week-day">
        <h4 class="text-lg day">{{ dayData.dayName }} {{ dayData.date }}</h4>
      </div>
      <div class="vianda-info" [ngClass]="!isLast ? 'border-right' : ''">
        <div class="location-status" [ngClass]="dayData.status == 'No habilitado' ? 'empty' : ''">
          <div class="location" *ngIf="
              dayData.status != 'empty' && dayData.status != 'No habilitado'
            ">
            <mat-icon fontSet="material-icons-outlined"> location_on </mat-icon>
            <h4 class="text-md locationName">
              {{ dayData.location }}
            </h4>
          </div>
        </div>

        <div class="menu-day" *ngIf="menusPublished; else menusUnpublished">

          <div [ngSwitch]="requestTemplate(dayData)">
            <div *ngSwitchCase="'notAvailable'">
              <div class="no-disponible">
                No disponible
              </div>
            </div>

            <div *ngSwitchCase="'outOfRange'">
              <div class="no-disponible">
                SU SOLICITUD SE ENCUENTRA FUERA DEL RANGO DE PEDIDOS. POR FAVOR, CONTACTARSE CON BUTACO
              </div>
            </div>

            <div *ngSwitchCase="'new'" style="height: 100%; width: 100%;">
              <div [ngClass]="dayData.status == 'empty' ? 'empty' : 'notEmpty'">
                <button (click)="orderVianda(dayData.dateNewRequest)" mat-mini-fab color="primary">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>

            <div *ngSwitchCase="'requests'">
              <div *ngFor="let item of dayData.items">
                <butaco-tarjeta-vianda [addNewRequest]="orderVianda.bind(this)"
                  [cardData]="returnViandaAndDayData(dayData, item)" [userRol]="userRol"
                  [selectedLocation]="selectedLocation"></butaco-tarjeta-vianda>
              </div>
            </div>

            <div *ngSwitchCase="'addNew'">
              <div *ngFor="let item of dayData.items">
                <butaco-tarjeta-vianda [addNewRequest]="orderVianda.bind(this)"
                  [cardData]="returnViandaAndDayData(dayData, item)" [userRol]="userRol"
                  [selectedLocation]="selectedLocation"></butaco-tarjeta-vianda>
              </div>
              <div [ngClass]="dayData.status == 'empty' ? 'empty' : 'notEmpty'">
                <button (click)="orderVianda(dayData.dateNewRequest)" mat-mini-fab color="primary">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>

            <div *ngSwitchDefault>Algo sucedio, por favor, reacargue la pagina</div>
          </div>
        </div>
        <ng-template #menusUnpublished>
          <div class="cartel">No hay menus creados para esta fecha</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>