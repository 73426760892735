import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DateHelpers {
    constructor() { }

    //recibe por parámetro un día de la semana y retorna el número correspondiente
    getWeekDayNumber(weekDay: string) {
        const day = weekDay.toLowerCase()
        let number
        switch (day) {
            case 'domingo':
                number = 0
                break;
            case 'lunes':
                number = 1
                break;
            case 'martes':
                number = 2
                break;
            case 'miércoles':
                number = 3
                break;
            case 'miercoles':
                number = 3
                break;
            case 'jueves':
                number = 4
                break;
            case 'viernes':
                number = 5
                break;
            case 'sabado':
                number = 6
                break;
            case 'sábado':
                number = 6
                break;
        }
        return number
    }


    //convierto el timestamp que paso por parámetro a milisegundos para poder pasarlo a formato Date()
    convertDate(date: any) {
        return (date.seconds * 1000) + (date.nanoseconds / 1000000);
    }

    convertDateToTimestamp(date: string): number {
        // Crear un objeto Date a partir de la cadena ISO
        const fecha = new Date(date);
        // Obtener el timestamp en milisegundos y convertirlo a segundos
        const timestamp = fecha.getTime() / 1000;
        return timestamp;
    }

   
}