import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import dayjs from 'dayjs';
import { ViandasNewRequestDialogComponent } from '../../viandas-new-request-dialog/viandas-new-request-dialog.component';


@Component({
  selector: 'butaco-viandas-general-user-resume',
  templateUrl: './viandas-general-user-resume.component.html',
  styleUrls: ['./viandas-general-user-resume.component.scss']
})
export class ViandasGeneralUserResumeComponent implements OnInit, OnChanges {

  @Input() locationGralUser: any;
  @Input() selectedDate : any;
  @Input() setView : any;
  @Input() user : any;
  @Output() evChangeView = new EventEmitter();
  @Output() evEditVianda = new EventEmitter();
  @Output() evCancellVianda = new EventEmitter();

  sortArrByUsers : any = [] = []
  loading = true;

  acceptedSortArr : any = [] = []
  pendingSortArr : any = [] = []
  canceledSortArr : any = [] = []

  tabSelected:number = 0;
  tabStyle:any = ['primary', 'warn', 'accent']

  availableToEdit = false

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.setEdition(this.selectedDate)
    //console.log(this.locationGralUser)
    //console.log(this.drinkList)
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDate && !changes.selectedDate.firstChange) {
      this.locationGralUser = {name: ''}
      this.setEdition(this.selectedDate)
    }   
    if (changes.locationGralUser && !changes.locationGralUser.firstChange) {
      //console.log(changes.locationGralUser)
      this.sortLocationByUser()
      //console.log(changes.locationGralUser.currentValue)
    }
  }

  sortLocationByUser(){
    const arrUsers = [];
    const arrUsersPedidosAceptados = []
    const arrUsersPedidosCanelados = []
    const arrUsersPedidosPendientes = []

    for (const order of this.locationGralUser.orders) {
      const user = `${order.requested_to.names} ${order.requested_to.last_names}`;
      const userRole = order.requested_to.role
      const userId = order.requested_to.id
      const orderId = order.id
      const userObj = order.requested_to
      const date = order.date_request.toDate()

      let totalPrincipal = 0;
      const principalDetails:any = {}
      const drinksDetails:any = {}
      for (const menu of order.menus_requested){  
        if(menu.typeMenu.component === 'Principal'){
          totalPrincipal += menu.quantity
          principalDetails[menu.typeMenu.menu_name] = {name:menu.typeMenu.menu_name, quantity:menu.quantity}
        }
      }
      for(const drink of order.drinks_requested){
        drinksDetails[drink.drink.name] = {name:drink.drink.name, content:drink.drink.content, quantity:drink.quantity}
      }

      const userDetail = {
        userObj,
        user,
        userRole,
        userId,
        date,
        totalPrincipal,
        principalDetails : Object.values(principalDetails),
        drinksDetails : Object.values(drinksDetails),
        orderId
      }
      //console.log('USER DETAIL', userDetail)
      switch (order.status_request) {
        case 'accepted':
          arrUsersPedidosAceptados.push(userDetail)
          break;
        case 'canceled':
          arrUsersPedidosCanelados.push(userDetail)
          break;
        case 'acceptancePending':
          arrUsersPedidosPendientes.push(userDetail)
      }

      arrUsers.push(userDetail)
    }

    this.acceptedSortArr = arrUsersPedidosAceptados
    this.canceledSortArr = arrUsersPedidosCanelados
    this.pendingSortArr = arrUsersPedidosPendientes
    this.sortArrByUsers = arrUsers
    this.setDiferenceBetwenOrders()

    this.loading = false;
  }

  setDiferenceBetwenOrders(){
    //Buscando la orden aceptada y la pendiente para ver que cambios de principales tiene
    this.pendingSortArr.forEach((pendingOrder:any, i:number) => {
      const userId = pendingOrder.userId
      let acceptedOrder = this.acceptedSortArr.filter((order:any) => order.userId === userId)
      acceptedOrder = acceptedOrder[0]

      if(acceptedOrder){ //TENIA UNA ORDEN ACEPTADA
        //Determinando los cambios en los principales
        pendingOrder.principalDetails.forEach((pendingDetails:any, ixD:number) => {
          let acceptedDetails = acceptedOrder.principalDetails.filter((details:any) => details.name === pendingDetails.name)
          acceptedDetails = acceptedDetails[0]
          if(acceptedDetails){
            //TENIA UNA ORDEN ACEPTADA CON ESTE MENU
            if(acceptedDetails.quantity !== pendingDetails.quantity){
              //TIENE UNA DIFERENCIA ENTRE LAS CANTIDADES
              const difference = pendingDetails.quantity - acceptedDetails.quantity
              this.pendingSortArr[i].principalDetails[ixD].difference = difference
              this.pendingSortArr[i].principalDetails[ixD].before = acceptedDetails.quantity
              this.pendingSortArr[i].principalDetails[ixD].after = pendingDetails.quantity
              this.pendingSortArr[i].principalDetails[ixD].date = this.formatDate(this.pendingSortArr[i].date)
            } else if (acceptedDetails.quantity === pendingDetails.quantity){
              //NO TIENE UNA DIFERENCIA ENTRE LAS CANTIDADES
              this.pendingSortArr[i].principalDetails[ixD].difference = 0
              this.pendingSortArr[i].principalDetails[ixD].before = pendingDetails.quantity
              this.pendingSortArr[i].principalDetails[ixD].after = acceptedDetails.quantity
              this.pendingSortArr[i].principalDetails[ixD].date = this.formatDate(acceptedOrder.date)
            }
          }  else {
            //NO TENIA UNA ORDEN ACEPTADA CON ESTE MENU
            this.pendingSortArr[i].principalDetails[ixD].difference = pendingDetails.quantity
            this.pendingSortArr[i].principalDetails[ixD].before = 0
            this.pendingSortArr[i].principalDetails[ixD].after = pendingDetails.quantity
            this.pendingSortArr[i].principalDetails[ixD].date = this.formatDate(this.pendingSortArr[i].date)
          }
        });

        //Determinando los cambios en bebidas
        pendingOrder.drinksDetails.forEach((drinksDetails:any, ixD:number) => {
          let acceptedDetails = acceptedOrder.drinksDetails.filter((details:any) => details.name === drinksDetails.name)
          acceptedDetails = acceptedDetails[0]
          if(acceptedDetails){
            //TENIA UNA ORDEN ACEPTADA CON ESTE MENU
            if(acceptedDetails.quantity !== drinksDetails.quantity){
              //TIENE UNA DIFERENCIA ENTRE LAS CANTIDADES
              const difference = drinksDetails.quantity - acceptedDetails.quantity
              this.pendingSortArr[i].drinksDetails[ixD].difference = difference
              this.pendingSortArr[i].drinksDetails[ixD].before = acceptedDetails.quantity
              this.pendingSortArr[i].drinksDetails[ixD].after = drinksDetails.quantity
              this.pendingSortArr[i].drinksDetails[ixD].date = this.formatDate(this.pendingSortArr[i].date)
            } else if (acceptedDetails.quantity === drinksDetails.quantity){
              //NO TIENE UNA DIFERENCIA ENTRE LAS CANTIDADES
              this.pendingSortArr[i].drinksDetails[ixD].difference = 0
              this.pendingSortArr[i].drinksDetails[ixD].before = drinksDetails.quantity
              this.pendingSortArr[i].drinksDetails[ixD].after = acceptedDetails.quantity
              this.pendingSortArr[i].drinksDetails[ixD].date = this.formatDate(acceptedOrder.date)
            }
          }  else {
            //NO TENIA UNA ORDEN ACEPTADA CON ESTE MENU
            this.pendingSortArr[i].drinksDetails[ixD].difference = drinksDetails.quantity
            this.pendingSortArr[i].drinksDetails[ixD].before = 0
            this.pendingSortArr[i].drinksDetails[ixD].after = drinksDetails.quantity
            this.pendingSortArr[i].drinksDetails[ixD].date = this.formatDate(this.pendingSortArr[i].date)
          }
        })
      } else { //NO TIENE NINGUNA ORDEN ACEPTADA
        //Determinando los cambios en los principales
        pendingOrder.principalDetails.forEach((pendingDetails:any, ixD:number) => {
          this.pendingSortArr[i].principalDetails[ixD].difference = pendingDetails.quantity
          this.pendingSortArr[i].principalDetails[ixD].before = 0
          this.pendingSortArr[i].principalDetails[ixD].after = pendingDetails.quantity
          this.pendingSortArr[i].principalDetails[ixD].date = this.formatDate(this.pendingSortArr[i].date)
        });
        //Determinando los cambios en bebidas
        pendingOrder.drinksDetails.forEach((pendingDetails:any, ixD:number) => {
          this.pendingSortArr[i].drinksDetails[ixD].difference = pendingDetails.quantity
          this.pendingSortArr[i].drinksDetails[ixD].before = 0
          this.pendingSortArr[i].drinksDetails[ixD].after = pendingDetails.quantity
          this.pendingSortArr[i].drinksDetails[ixD].date = this.formatDate(this.pendingSortArr[i].date)
        });
      }
    })
  }

  editOrCancellVianda(event:any, user:any){
    if(event === 'agregar'){
      this.evEditVianda.emit(user.userObj)
    }
    if(event === 'cancelar'){
      const result = window.confirm('¿Está seguro que desea cancelar la orden?')
      if (result){
        //TODO AGREGAR UN MODAL MAS LINDO
        const orderDetails = {orderId: user.orderId, state: 'cancelled'}
        this.evCancellVianda.emit(orderDetails)
      } else {
        return
      }

    }
  }

  changeView(event:any){
    this.evChangeView.emit(event)
  }

  changeSelectedTab(event:any){
    this.tabSelected = event.index
  }

  formatDate(date:any){
    return dayjs(date).format('DD/MM/YYYY HH:mm')
  }

  setEdition(selectedDate: any){
    this.availableToEdit = !(dayjs(selectedDate).isBefore(new Date(), 'day'))
  }

}
