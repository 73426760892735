import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkEditPermissions',
  standalone: true
})
export class CheckEditPermissionsPipe implements PipeTransform {
  transform(editPermissions: any, permissionsNeeded: String): boolean {
    //permissionsNeeded tiene 4 claves: 'usuario', 'Butaco', 'cualquiera' y 'ninguno', para evaluar si los permisos que se necesitan 
    //están activos o no. Si 'usuario' puede editar, Butaco también, por lo que es lo mismo que si la clave fuera 'ambos'
    let meetsCondition = false
    switch (permissionsNeeded) {
      case ('usuario'):
        if (editPermissions.userCanEdit) {
          meetsCondition = true
        }
        break;
      case ('Butaco'):
        if (!editPermissions.userCanEdit && editPermissions.butacoCanEdit) {
          meetsCondition = true
        }
        break;
      case ('cualquiera'):
        if (editPermissions.userCanEdit || editPermissions.butacoCanEdit) {
          meetsCondition = true
        }
        break;
      case ('usuario pendiente'):
        if (editPermissions.userCanEdit && editPermissions.status === 'pending') {
          meetsCondition = true
        }
        break;
      default:
        meetsCondition = false
        break;
    }
    return meetsCondition
  }

}
