<div *ngIf="isLoading; then loading else loaded"></div>
<ng-template #loading>
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</ng-template>

<ng-template #loaded>
  <ng-container *ngIf="view==='user_gral'; else user_individual">
    <div class="user-gral-container">
      
      <div class="user-gral-dates">
        <ng-container *ngIf="!selectedDateData; then loadingCalendar; else calendarGralUser"></ng-container>

        <ng-template #loadingCalendar>
          <div
            style="display: flex; flex-direction: column; justify-content: center;align-items: center; height: 100%;"
          >
            <mat-spinner mode="indeterminate" color="primary" diameter="50">
            </mat-spinner>
            Cargando calendario...
          </div>
        </ng-template>

        <ng-template #calendarGralUser>
          <butaco-viandas-general-user-dates [selectedDate]="selectedDate" (evChangeSelectedDay)="changeSelectedDay($event)" 
          [locationsToChoose]="selectedDateData.locations" [contractStartEndDay]="contractStartEndDay" [allOrdersAdmin]="allOrdersAdmin" 
          (evChangeSelectedLocation)="changeSelectedLocationGral($event)">
          </butaco-viandas-general-user-dates>
        </ng-template>
      </div>
      <div class="user-gral-resume">
        <butaco-viandas-general-user-resume [locationGralUser]="locationGralUser" [selectedDate]="selectedDate" 
        (evChangeView)="changeView($event)" [user]="user" (evEditVianda)="onEditMenuGralUser($event)" (evCancellVianda)="onCancelMenuGralUser($event)"
        >
        </butaco-viandas-general-user-resume>
      </div>
    </div>
  </ng-container>

  <ng-template #user_individual>
    <div class="todays-menu-container">
      <butaco-viandas-normal-user-dates class="dates" [menus]="menus" [selectedDate]="selectedDate"
        (evChangeSelectedDay)="changeSelectedDay($event)" (evChangeSelectedWeek)="changeSelectedWeek($event)">
      </butaco-viandas-normal-user-dates>
      <div *ngIf="selectedDateData">
        <butaco-viandas-normal-user-menus [date]='selectedDateData.date' [locationsToChoose]="selectedDateData.locations"
          [specialMenus]="specialMenus" [isAvailable]="selectedDateData.available_to_order && selectedDateData.available_to_edit"
          [menusToChoose]="selectedDateData.menu_types" [drinksToChoose]="selectedDateData.drinks"
          [alreadyHasOrder]="selectedDateData.requestAreadySaved" class="menu-date"
          (onChangeLocation)="changeLocationSelected($event)" (onAddedMenu)="onAddedMenu($event)"
          (onAddedDrink)="onAddedDrink($event)"></butaco-viandas-normal-user-menus>
      </div>
      <div *ngIf="!selectedDateData"
        style="display: flex;flex-direction: column; justify-content: center;align-items: center; height: 100%;">
        <mat-spinner mode="indeterminate" color="primary" diameter="50">
        </mat-spinner>
        Cargando pedido...
      </div>
      <div *ngIf="selectedDateData">
        <butaco-viandas-normal-user-request class="request" [selectedDateData]="selectedDateData"
          (onMakeRequest)="onMakeRequest()" (onCancellReactiveReq)="onCancellReactiveReq($event)">
        </butaco-viandas-normal-user-request>
        <!-- Boton cambiar vista -->
        <button *ngIf="user.role ==='user_gral'" mat-fab class="add-task-button" color="warn" (click)="changeView('user_gral')">
          <mat-icon style="color: white">history</mat-icon>
        </button>
      </div>
      <div *ngIf="!selectedDateData"
        style="display: flex;flex-direction: column; justify-content: center;align-items: center; height: 100%;">
        <mat-spinner mode="indeterminate" color="primary" diameter="50">
        </mat-spinner>
        Cargando pedido...
      </div>
    </div>
  </ng-template>
  
</ng-template>