import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Drink } from '../interfaces/drink-interface'
import { Menu } from '../interfaces/menu-interface';
import { SpecialMenu } from '../interfaces/special-menu-interface';
//import { AnyMxRecord } from 'dns';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private specialMenusListCollection: AngularFirestoreCollection<any>;
  private menuListCollection: AngularFirestoreCollection<any>;
  private drinkListCollection: AngularFirestoreCollection<any>;
  private utilsCollection: AngularFirestoreCollection<any>;
  public menuSettings$ = new BehaviorSubject<any>(undefined);
  public eventConf$ = new BehaviorSubject<any>(undefined);

  menuCongSub: any;
  eventConfSub: any;

  constructor(private afs: AngularFirestore) {
    this.specialMenusListCollection = this.afs.collection<any>('special_menus');
    this.drinkListCollection = this.afs.collection<any>('drinks');
    this.menuListCollection = this.afs.collection<any>('menues');
    this.utilsCollection = this.afs.collection<any>('utils');
  }

  // this func creates and updates menues
  setMenu(menu: Menu): Promise<any> {
    return this.menuListCollection.doc(menu.id).set(menu);
  }

  deleteMenu(menuId: string): Promise<any> {
    return this.menuListCollection.doc(menuId).delete();
  }

  getMenuList(): any {
    return this.menuListCollection.valueChanges();
  }

  // this func creates and updates drinks
  setDrink(drink: Drink): Promise<any> {
    return this.drinkListCollection.doc(drink.id).set(drink);
  }

  getDrinkList(): any {
    return this.drinkListCollection.valueChanges();
  }

  // this func creates and updates drinks
  setSpecialMenu(specialMenu: SpecialMenu): Promise<any> {
    return this.specialMenusListCollection.doc(specialMenu.id).set(specialMenu);
  }

  getSpecialMenu(): any {
    return this.specialMenusListCollection.valueChanges();
  }

  getMenuConfig(): any {
    return this.menuSettings$.asObservable();
  }

  getMenuConfigData(): void {
    if (!this.menuCongSub) {
      this.menuCongSub = this.utilsCollection
        .doc('menu_config')
        .valueChanges({ idField: 'id' })
        .subscribe((res) => {
          this.menuSettings$.next(res);
        });
    }
  }

  saveMenuConfig(config: any): Promise<any> {
    return this.utilsCollection.doc('menu_config').set(config);
  }

  getServicesTasks(): any {
    return this.utilsCollection
      .doc('services_tasks')
      .valueChanges({ idField: 'id' });
  }

  getBusinessUnits(): any {
    return this.utilsCollection
      .doc('business_unit')
      .valueChanges({ idField: 'id' });
  }

  editBusinessUnitTask(bUnit:any): Promise<any> {
    return this.utilsCollection.doc('business_unit').set(bUnit);
  }

  editServiceTask(bUnit:any): Promise<any> {
    return this.utilsCollection.doc('services_tasks').set(bUnit);
  }

  getEventConfigData(): void {
    if (!this.eventConfSub) {
      this.eventConfSub = this.utilsCollection
        .doc('events_config')
        .valueChanges({ idField: 'id' })
        .subscribe((res) => {
          this.eventConf$.next(res);
        });
    }
  }

  getEventConfig(): Observable<any> {
    return this.eventConf$.asObservable();
  }

  editEventConfig(eventConfig:any): Promise<any> {
    return this.utilsCollection.doc('events_config').set(eventConfig);
  }
}
