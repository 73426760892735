import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'butaco-viandas-normal-user-request',
  templateUrl: './viandas-normal-user-request.component.html',
  styleUrls: ['./viandas-normal-user-request.component.scss'],
})
export class ViandasNormalUserRequestComponent implements OnInit{
  @Input() selectedDateData: any;
  @Output() onMakeRequest = new EventEmitter<any>();
  @Output() onCancellReactiveReq = new EventEmitter<any>();
  enableEdit:boolean = true;

  constructor() {}

  ngOnInit(): void {
    //Si cuando inicializa el componente, no hay menus pedidos, es un pedido nuevo
    if (this.selectedDateData.menus_requested.length === 0){
      this.enableEdit = false
    }
    //console.log('INIT', this.selectedDateData)
  }

  removeMeal(idx: number): void {
    if (this.selectedDateData.menus_requested[idx].quantity > 1) {
      this.selectedDateData.menus_requested[idx].quantity--;
    } else {
      this.selectedDateData.menus_requested.splice(idx, 1);
    }
  }

  removeDrink(idx: number): void {
    if (this.selectedDateData.drinks_requested[idx].quantity > 1) {
      this.selectedDateData.drinks_requested[idx].quantity--;
    } else {
      this.selectedDateData.drinks_requested.splice(idx, 1);
    }
  }

  makeRequest(): void {
    this.onMakeRequest.emit();
  }

  cancellReactiveReq(state:string) :void {
    //TODO agregar modal mas lindo
    if (confirm('Seguro que desea cambiar el estado de su menu?')){
      this.onCancellReactiveReq.emit(state)
    }
  }

  enableEditFn(){
    this.selectedDateData.available_to_edit = true
    this.enableEdit = this.selectedDateData.available_to_order && this.selectedDateData.available_to_edit
    this.enableEdit = !this.enableEdit
  }

}
