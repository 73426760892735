<div *ngIf="loadingData; then getingData else  dataGeted"></div>

<ng-template #getingData>
    <h4 class="mat-h4"> Obteniendo pedidos ...</h4>
    <mat-progress-bar color='primary' mode="indeterminate">
    </mat-progress-bar>
</ng-template>

<ng-template #dataGeted>
    <div *ngIf="errorGetingData; then errorData else noErrorData"></div>
</ng-template>

<ng-template #errorData>
    <h4 class="mat-h4"> Ocurrio un error y no se pudo obtener la información, por favor vuelva a intentarlo mas tarde.
    </h4>
</ng-template>

<ng-template #noErrorData>
    <div class="container-detail">
        <div class="header">
            <div class="header-month">
                <h1>Detalle de pedidos</h1>
                <div class="month-selector">
                    <button mat-icon-button color='primary' (click)="changeDay('prev')">
                        <mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon>
                    </button>
                    <h5 class="dateText"> {{ currentDate | date:'dd-MM-YYYY'}}</h5>
                    <button mat-icon-button color='primary' (click)="changeDay('next')">
                        <mat-icon fontSet="material-icons-outlined">chevron_right</mat-icon>
                    </button>
                </div>

            </div>
            <h4 class="totalRequest">Total: <b style="color: var(--secundary-color);">{{totalRequestToday}}</b></h4>
        </div>

        <div class="content">
            <div class="req-registered-users-container">
                <div class="req-registered-users-header">
                    <h4>Usuarios registrados</h4>
                </div>
                <div class="req-registered-users-content" *ngIf="requestsRegUsers && requestsRegUsers.length > 0">
                    <div class="header-table">
                        <h5 class="requester">Solicitá</h5>
                        <h5>Principal</h5>
                        <h5>Bebida</h5>
                        <h5>Entrada</h5>
                        <h5>Postre</h5>
                    </div>
                    <div class="content-table">
                        <div class="row-data-table" *ngFor="let request of requestsRegUsers">
                            <div class="row-data">
                                <h4 class="requester">{{request.requester.last_names}},{{request.requester.names}}</h4>
                                <div style="max-width: 200px;">
                                    <div *ngFor="let menu_requested of request.reqPrincipal; let last = last">
                                        <h4 class="menuText">{{menu_requested.menu_name | titlecase}}</h4>
                                        <span class="menuTextType">{{menu_requested.name | titlecase}}</span>
                                    </div>
                                </div>
                                <div style="max-width: 200px;">
                                    <div *ngIf="request.drinks_requested.length > 0">
                                        <div *ngFor="let drink of request.drinks_requested; let last = last">
                                            <span>{{drink.name}} - {{drink.content}}</span>
                                        </div>
                                    </div>
                                    <mat-icon fontSet="material-icons-outlined"
                                        *ngIf="request.drinks_requested.length === 0" color='accent'>cancel</mat-icon>
                                </div>
                                <div>
                                    <mat-icon fontSet="material-icons-outlined" *ngIf="request.reqEntry"
                                        color='primary'>done</mat-icon>
                                    <mat-icon fontSet="material-icons-outlined" *ngIf="!request.reqEntry"
                                        color='accent'>cancel</mat-icon>
                                </div>
                                <div>
                                    <mat-icon fontSet="material-icons-outlined" *ngIf="request.reqDessert"
                                        color='primary'>done</mat-icon>
                                    <mat-icon fontSet="material-icons-outlined" *ngIf="!request.reqDessert"
                                        color='accent'>cancel</mat-icon>
                                </div>
                                <!-- <div>
                                    <h4 class="sumQuantity">{{request.quantity}}</h4>
                                </div> -->
                            </div>
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                </div>
                <div class="req-registered-users-content  no-request" *ngIf="requestsRegUsers && requestsRegUsers.length == 0">
                    <h4 class="mat-h4" style="opacity: 0.5;"> No se encontraron pedidos de viandas de usuarios
                        registrados.</h4>
                </div>
            </div>
            <div class="req-invitation-users-container">
                <div class="req-invitation-users-header">
                    <h4>Usuarios No registrados</h4>

                </div>
                <div class="req-invitation-users-content" *ngIf="requestsInvUsers && requestsInvUsers.length > 0">
                    <div class="header-table">
                        <h5 class="requester">Solicitá</h5>
                        <h5>Principal</h5>
                        <h5>Bebida</h5>
                        <h5>Entrada</h5>
                        <h5>Postre</h5>
                        <h5>Cantidad</h5>
                    </div>
                    <div class="content-table">
                        <div class="row-data-table" *ngFor="let request of requestsInvUsers">
                            <div class="row-data">
                                <h4 class="requester">{{request.requester.last_names}},{{request.requester.names}}</h4>
                                <div style="max-width: 200px;">
                                    <div *ngFor="let menu_requested of request.reqPrincipal; let last = last">
                                        <h4 class="menuText">{{menu_requested.typeMenu.menu_name | titlecase}}</h4>
                                        <span class="menuTextType">{{menu_requested.typeMenu.name | titlecase}}</span>
                                    </div>
                                </div>
                                <div style="max-width: 200px;">
                                    <div *ngIf="request.drinks_requested.length > 0">
                                        <div *ngFor="let drink of request.drinks_requested; let last = last">
                                            <span>{{drink.drink.name}} - {{drink.drink.content}}</span>
                                        </div>
                                    </div>
                                    <mat-icon fontSet="material-icons-outlined"
                                        *ngIf="request.drinks_requested.length === 0" color='accent'>cancel</mat-icon>
                                </div>
                                <div>
                                    <mat-icon fontSet="material-icons-outlined" *ngIf="request.reqEntry"
                                        color='primary'>done</mat-icon>
                                    <mat-icon fontSet="material-icons-outlined" *ngIf="!request.reqEntry"
                                        color='accent'>cancel</mat-icon>
                                </div>
                                <div>
                                    <mat-icon fontSet="material-icons-outlined" *ngIf="request.reqDessert"
                                        color='primary'>done</mat-icon>
                                    <mat-icon fontSet="material-icons-outlined" *ngIf="!request.reqDessert"
                                        color='accent'>cancel</mat-icon>
                                </div>
                                <div>
                                    <h4 class="sumQuantity">{{request.quantity}}</h4>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                </div>
                <div class="req-invitation-users-content no-request" *ngIf="requestsInvUsers && requestsInvUsers.length === 0">
                    <h4 class="mat-h4" style="opacity: 0.5;"> No se encontraron pedidos de viandas para invitados.</h4>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<button mat-fab color='primary' class="fab-add-btn" matTooltip="Realizar un nuevo pedido de viandas"
  matTooltipPosition="above" (click)="newMealRequest()" [disabled]='!user || !company || !drinkList'>
  <mat-icon>add</mat-icon>
</button>