<div class="resume-container">
    <!-- TITULO Día y locacion -->
    <h4>{{selectedDate | date:'dd/MM/yyyy'}} - {{locationGralUser.name}}</h4>

    <!-- TABS -->
    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="changeSelectedTab($event)" [color]="tabStyle[tabSelected]">
        <!-- TAB ACEPTADOS -->
        <mat-tab>
            <!-- HEADER TAB -->
            <ng-template mat-tab-label>
                <span [ngStyle]="{'color': tabSelected===0 ? '#64886a' : 'gray'}">Confirmados</span>
            </ng-template>

            <!-- CONTENIDO TAB -->
            <ng-container *ngIf="locationGralUser.id; else noLocationSelected">
                <!-- BARRA CON TOTALES -->
                <div class="total">
                    <span class="pedidos-text">Pedidos</span> 
                    <p class="total-text">Total: <span>{{locationGralUser.totalesAceptados.Total}}</span></p>
                </div>

                <!-- ACORDION POR ORDENES -->
                <div *ngIf="!loading && sortArrByUsers.length > 0" class="resume-by-user">
                    <!-- SOLICITO/CANTIDADES-->
                    <div style="display:flex; justify-content: space-between; width: 86.3%; margin-left:1.5rem">
                        <span style="font-size:smaller; font-weight: 600; color:grey">Solicito</span>
                        <span style="font-size:smaller; font-weight: 600; color:grey">Cantidades</span>
                    </div>
                    <!-- ORDENES -->
                    <div *ngFor="let user of acceptedSortArr" style="margin-bottom: 1rem">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header style="height: 2rem;">
                                    <mat-panel-title style="font-size: smaller; font-weight: 600;">
                                        <div class="panel-title">
                                            <span>
                                                {{user.user}} 
                                                <span style="font-size: x-small;"*ngIf="user.userRole === 'user_gral'">(Encargado)</span>
                                            </span>
                                            <div class="title-options">
                                                <span>{{user.totalPrincipal}}</span>
                                                <button mat-icon-button color="warn" [matMenuTriggerFor]="menu"
                                                (click)="$event.stopPropagation()" *ngIf="availableToEdit"
                                                >
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <menu mat-menu-item class="menuItem"
                                                        (click)="editOrCancellVianda('agregar', user)"
                                                    >
                                                        Editar Pedido
                                                    </menu>
                                                    <menu mat-menu-item class="menuItem"
                                                        (click)="editOrCancellVianda('cancelar', user)"
                                                    >
                                                        Cancelar Pedido
                                                    </menu>
                                                </mat-menu>
                                            </div>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div style="display:flex; justify-content: space-between; width: 88.4%; margin-left:1.5rem; margin-bottom:0.7rem">
                                    <span style="font-size:smaller; font-weight: 600; color:grey">Principal</span>
                                    <span style="font-size:smaller; font-weight: 600; color:grey">Cantidades</span>
                                </div>
                                <div *ngFor="let menu of user.principalDetails let x = index" class="principal-details">
                                    <div style="display:flex; justify-content: space-between;">
                                        <span class="menu-name">{{menu.name | titlecase}}</span> <span class="menu-total">{{menu.quantity}}</span>
                                    </div>
                                    <div *ngIf="x !== (user.principalDetails.length -1)">
                                        <hr style="border-top: 1px solid #c2c2c2;">
                                    </div>
                                </div>
                                <div *ngIf="user.drinksDetails.length > 0"> 
                                    <div style="display:flex; justify-content: space-between; width: 88.4%; margin:0.7rem 0 0.7rem 1.5rem">
                                        <span style="font-size:smaller; font-weight: 600; color:grey">Bebidas</span>
                                    </div>
                                    <div *ngFor="let drink of user.drinksDetails let i = index" class="principal-details">
                                        <div style="display:flex; justify-content: space-between;">
                                            <span class="menu-name">{{drink.name | titlecase}} - {{drink.content}}</span> <span class="menu-total">{{drink.quantity}}</span>
                                        </div>
                                        <div *ngIf="i !== (user.drinksDetails.length -1)">
                                            <hr style="border-top: 1px solid #c2c2c2;">
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </ng-container>
            <!-- Sin locacion seleccionada -->
            <ng-template #noLocationSelected>
                Por favor seleccione una sucursal para ver sus detalles...
            </ng-template>
        </mat-tab>

        <!-- TAB PENDIENTES -->
        <mat-tab>
            <!-- HEADER TAB -->
            <ng-template mat-tab-label>
                <span [ngStyle]="{'color': tabSelected===1 ? '#ff9914' : 'gray'}">Pendientes</span>
            </ng-template>

            <!-- CONTENIDO TAB -->
            <ng-container *ngIf="locationGralUser.id; else noLocationSelected">
                <!-- ACORDION POR ORDENES -->
                <div *ngIf="!loading && pendingSortArr.length > 0" class="resume-by-user">
                    <!-- SOLICITO/CANTIDADES-->
                    <div style="display:flex; justify-content: space-between; width: 86.3%; margin-left:1.5rem">
                        <span style="font-size:smaller; font-weight: 600; color:grey">Solicito</span>
                        <span style="font-size:smaller; font-weight: 600; color:grey">Cantidades</span>
                    </div>
                    <!-- ORDENES -->
                    <div *ngFor="let user of pendingSortArr" style="margin-bottom: 1rem">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header style="height: 2rem;">
                                    <mat-panel-title style="font-size: smaller; font-weight: 600;">
                                        <div class="panel-title">
                                            <span>
                                                {{user.user}} 
                                                <span style="font-size: x-small;"*ngIf="user.userRole === 'user_gral'">(Encargado)</span>
                                            </span>
                                            <div class="title-options">
                                                <span>{{user.totalPrincipal}}</span>
                                            </div>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div style="display:flex; justify-content: space-between; width: 45rem; margin-left:1.5rem; margin-bottom:0.7rem;">
                                    <span style="font-size:smaller; font-weight: 600; color:grey; width:19rem;">Principal</span>
                                    <span style="font-size:smaller; font-weight: 600; color:grey; width:9rem;">Fecha</span>
                                    <span style="font-size:smaller; font-weight: 600; color:grey; width:5rem; text-align:center;">Cambio</span>
                                    <span style="font-size:smaller; font-weight: 600; color:grey; width:4rem; text-align:center;">Antes</span>
                                    <span style="font-size:smaller; font-weight: 600; color:grey; width:4rem; text-align:center;">Ahora</span>
                                </div>
                                <div *ngFor="let menu of user.principalDetails let x = index" class="pending-details">
                                    <div style="display:flex; justify-content: space-between;">
                                        <span class="menu-name">{{menu.name | titlecase}}</span> 
                                        <span class="menu-date">{{menu.date}}</span>
                                        <span class="menu-difference" [ngStyle]="{'color': menu.difference === 0 ? '' : menu.difference > 0 ? 'green' : 'red'}">
                                            <div *ngIf="menu.difference > 0">+</div>
                                            {{menu.difference}}
                                        </span>
                                        <span class="menu-before">{{menu.before}}</span>
                                        <span class="menu-after">{{menu.after}}</span>
                                    </div>
                                    <div *ngIf="x !== (user.principalDetails.length -1)">
                                        <hr style="border-top: 1px solid #c2c2c2;">
                                    </div>
                                </div>
                                <div *ngIf="user.drinksDetails.length > 0"> 
                                    <div style="display:flex; justify-content: space-between; width: 88.4%; margin:0.7rem 0 0.7rem 1.5rem">
                                        <span style="font-size:smaller; font-weight: 600; color:grey">Bebidas</span>
                                    </div>
                                    <div *ngFor="let drink of user.drinksDetails let i = index" class="pending-details">
                                        <div style="display:flex; justify-content: space-between;">
                                            <span class="menu-name">{{drink.name | titlecase}}</span> 
                                            <span class="menu-date">{{drink.date}}</span>
                                            <span class="menu-difference" [ngStyle]="{'color': drink.difference === 0 ? '' : drink.difference > 0 ? 'green' : 'red'}">
                                                <div *ngIf="drink.difference > 0">+</div>
                                                {{drink.difference}}
                                            </span>
                                            <span class="menu-before">{{drink.before}}</span>
                                            <span class="menu-after">{{drink.after}}</span>
                                        </div>
                                        <div *ngIf="i !== (user.drinksDetails.length -1)">
                                            <hr style="border-top: 1px solid #c2c2c2;">
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </ng-container>
        </mat-tab>

        <!-- TAB CANCELADOS -->
        <mat-tab>
            <!-- HEADER TAB -->
            <ng-template mat-tab-label>
                <span [ngStyle]="{'color': tabSelected===2 ? '#ee2e31' : 'gray'}">Cancelados</span>
            </ng-template>

            <!-- CONTENIDO TAB -->
            Cancelados...
        </mat-tab>
    </mat-tab-group>



    <!-- Boton  "+" -->
    <button mat-fab class="add-task-button" color='primary' (click)="changeView('user_individual')">
        <mat-icon>add</mat-icon>
    </button>
</div>