import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule, Routes } from '@angular/router';
import { ViandasAdminDetailComponent } from './viandas-admin-detail/viandas-admin-detail.component';
import { ViandasMainComponent } from './viandas-main/viandas-main.component';
import { NewRequestComponent } from './new-request/new-request.component';
import { OrderRequestComponent } from './order-request/order-request.component';

const routes: Routes = [
  {
    path: '',
    component: ViandasMainComponent,
    pathMatch: 'full',
    children: [
      {
        path: ':date',
        component: ViandasMainComponent,
      }
    ]
  },
  {
    path: 'ubicacion',
    component: ViandasAdminDetailComponent,
  },
  {
    path: 'viandas/:action/:date/:id',
    component: NewRequestComponent,
    children: [
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-AR' },]
})
export class ViandasRoutingModule { }
