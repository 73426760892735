import { MatIconModule } from '@angular/material/icon';

import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MessageDialogComponent } from 'src/app/shared/dialogs/message-dialog/message-dialog.component';

//SERVICES
import { ViandasService } from '../../../shared/services/viandas.service';
import { CompanyDataService } from 'src/app/shared/services/company-data.service';
import { SettingsService } from 'src/app/shared/services/settings-service.service';

//MODELOS E INTERFACES
import { Pedido } from 'src/app/shared/models/viandas';

//ANGULAR MATERIAL

import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import dayjs from 'dayjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DateHelpers } from 'src/app/shared/helpers/dateHelpers';
import { ContractHelpers } from 'src/app/shared/helpers/contractHelpers';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CheckOrderStatusPipe } from 'src/app/shared/pipes/check-order-status/check-order-status.pipe';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DeliveryService } from 'src/app/shared/services/delivery-service.service';
import { DeliveryOrder } from 'src/app/shared/models/delivery-order';
import { ViandaStateMachineService } from 'src/app/shared/services/vianda-state-machine.service';
import { take } from 'rxjs/operators';
import { Timestamp } from 'firebase/firestore';
import { stat } from 'fs';
import { deliveryStatus, Services, viandaStatus } from 'src/app/shared/models/enums';
import { LocationHelpers } from 'src/app/shared/helpers/locationHelpers';
import { OrderMealsFormPipe } from '../pipes/order-meals-form.pipe';
@Component({
  selector: 'butaco-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    RouterLink,
    MatSnackBarModule,
    MatDialogModule,
    MatTooltipModule,
    CheckOrderStatusPipe,
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NewRequestComponent {
  availableLocations: any = [];
  selectedLocation: any;

  companyContracts: any[] = [];
  user: any = [];
  users: any[] = [];

  requester: any = {};

  loading: boolean = false;

  drinksData: any[] = [];

  orderToEdit: any;
  orderId: string;

  detailsForm: any;

  specialMenus: any = [];

  filteredMenus: any[] = [];
  dayMenus: any[] = [];

  menusRequestedForm: FormGroup;
  specialMenusRequestedForm: FormGroup;
  drinksRequestedForm: FormGroup;

  dayRequests: Array<any> = []

  requestQuantityControls = {
    drinks: 0,
    menus: 0,
    specialMenus: 0,
    editedVianda: false
  };

  selectedDate: any;
  action: any;

  company: any;
  contract: any;
  currentDate: Date;
  isNavigating = false;

  savingData: boolean = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private viandasService: ViandasService,
    private setingServ: SettingsService,
    private clientsService: CompanyDataService,
    private snackBar: MatSnackBar,
    private dateHelpers: DateHelpers,
    private contractHelpers: ContractHelpers,
    public dialog: MatDialog,
    private afs: AngularFirestore,
    private deliveryServ: DeliveryService,
    private viandaStateMachine: ViandaStateMachineService,
    private locationHelpers: LocationHelpers
  ) { }

  ngOnInit(): void {
    if (!this.viandasService.newRequestData$.value) {
      this.router.navigate(['/viandas']);
      return;
    }
    this.getDataStorage();
    this.loading = true;
    const tiempoTranscurrido = Date.now();
    this.currentDate = new Date(tiempoTranscurrido);
    this.getDataIni();
  }

  //-----------------------------------METODOS DE OBTENCIÓN DE DATOS ----------------------------------//
  //obtiene los datos necesarios para los formularios de los behaviour subjects en los servicios
  getDataStorage(): void {
    this.selectedDate = this.viandasService.newRequestData$.value.date;
    this.selectedLocation = this.viandasService.newRequestData$.value.location;
    let userData = this.clientsService.generalData$.value.user;
    this.user = userData;
    this.requester = userData;
    this.orderId = this.viandasService.newRequestData$.value.orderId;
    this.company = this.clientsService.generalData$.value.company;
    this.availableLocations =
      this.clientsService.generalData$.value.availableLocations;
    this.companyContracts = this.clientsService.generalData$.value.contracts;
  }

  //obtiene todos los datos necesarios para los formularios
  getDataIni() {
    this.getSpecialMenus();
    this.getDrinks();
    this.getMenuForTheDay(this.selectedDate);
    this.getOrderToEdit();
    this.initForm();
    this.setSelectedLocation();
    this.filterContractByLocation();
    this.setUserFormData();
  }

  //obtiene el contrato correspondiente a la ubicación seleccionada
  filterContractByLocation() {
    this.contract = this.companyContracts.find((contract: any) =>
      contract.locations.find(
        (location: any) => location.id === this.selectedLocation.id
      )
    );
    // this.getMenuForTheDay(this.selectedDate);
  }

  //verifica que el contrato incluya el componente que llega por parámetro
  checkContractAvailability(component: string): boolean {
    return this.contract.components.includes(component);
  }

  //genera la url para la página según fecha, acción y ubicación
  setPageUrl() {
    // Formatear la fecha sin las horas
    const fechaFormateada = dayjs(this.selectedDate).format('D-M-YYYY');

    let url;
    if (this.orderToEdit || this.viandasService.newRequestData$.value.orderId) {
      //cuando consigue la ubicación la pasa por parametro con el nombre
      url = [
        'viandas',
        'edicion de pedido',
        fechaFormateada,
        this.selectedLocation.name,
      ];
    } else {
      url = [
        'viandas',
        'nuevo pedido',
        fechaFormateada,
        this.selectedLocation.name,
      ];
    }

    this.navigateToUrl(url);
  }

  navigateToUrl(url: any) {
    this.router.navigate(url, { replaceUrl: true });
  }

  //selecciona una locations para el formulario por default
  setSelectedLocation() {
    if (this.orderToEdit) {
      this.selectedLocation = this.availableLocations.find(
        (location: any) => location.id === this.orderToEdit.location.id
      );
    } else {
      this.selectedLocation =
        this.locationHelpers.getSelectedLocation() ||
        this.availableLocations[0];
    }
    this.detailsForm.get('location').setValue(this.selectedLocation);
  }

  //setea el requester en el formulario del usuario
  setUserFormData() {
    if (this.selectedLocation.rol != 'Responsable de sucursal') {
      this.detailsForm.get('requested_to').setValue(this.requester.id);
    } else {
      this.detailsForm.get('requested_to').setValue(this.user.uid);
    }
  }

  //modifica los datos del formulario de usuario y obtiene el contrato correspondiente a la nueva location
  onChangeLocations(location: any) {
    this.resetRequestForms();
    this.locationHelpers.setSelectedLocation(location);
    this.selectedLocation = location;
    this.user = this.requester;
    this.detailsForm.get('location').setValue(this.selectedLocation);
    this.setUserFormData();
    this.filterContractByLocation();
    this.filterMenus(this.dayMenus);
  }

  //actualiza los datos del componente según sea necesario con la fecha nueva
  onChangeDate(date: any) {
    this.resetRequestForms();
    this.selectedDate = dayjs(date).format();
    this.detailsForm.get('date').setValue(this.selectedDate);
    this.getMenuForTheDay(this.selectedDate);
    this.searchExistingViandaByDate();
  }

  //obtiene todos los menus correspondientes a la fecha seleccionada
  getMenuForTheDay(date: any) {
    let fechaFormateada = dayjs(date).format('YYYYMMDD');
    let menusSubscription: Subscription = this.viandasService
      .getMenuForSingleDate(fechaFormateada)
      .subscribe((data) => {
        if (data.menu && data.published) {
          this.dayMenus = data.menu;
          this.filterMenus(data.menu);
          menusSubscription.unsubscribe();
        } else {
          this.filteredMenus = [];
        }
      });
    this.loading = false;
  }

  //resetea los formularios y el controlador de cantidades
  resetRequestForms() {
    this.dayRequests = []
    this.drinksRequestedForm = this.initDrinksRequestForm(this.drinksData);
    this.specialMenusRequestedForm = this.initMenusRequestForm(
      this.specialMenus
    );
    this.requestQuantityControls = {
      drinks: 0,
      menus: 0,
      specialMenus: 0,
      editedVianda: false
    };
  }

  //busca viandas existentes para la fecha seleccionada y en caso de existir una llama a setEditData
  searchExistingViandaByDate() {

    this.orderToEdit = undefined //vacío los datos de order to edit 
    this.orderId = undefined

    const startOfDateToSearch = dayjs(this.selectedDate)
      .startOf('day')
      .toDate();
    const endOfDateToSearch = dayjs(this.selectedDate).endOf('day').toDate();

    const startOfDayTimestamp = Timestamp.fromDate(startOfDateToSearch);
    const endOfDayTimestamp = Timestamp.fromDate(endOfDateToSearch);

    this.viandasService
      .getUserOrderByDateAndLocation(
        this.user.id,
        startOfDayTimestamp,
        endOfDayTimestamp,
        this.selectedLocation.id
      )
      .then((res) => {
        if (res) {
          res.map((orderData:any)=>{

            if(!orderData.order.delivery_order.status && orderData.order.delivery_order_id){

              this.deliveryServ.getDeliveryById(orderData.order.delivery_order_id).then((deliveryOrder:any)=>{

                if(deliveryOrder.status === deliveryStatus.Pendiente){
                  this.orderToEdit = orderData.order
                  this.orderId = orderData.id
                  this.setEditData();
                }
              })

            } else if (orderData.order.delivery_order.status === deliveryStatus.Pendiente ) {
              this.orderToEdit = orderData.order
              this.orderId = orderData.id
              this.setEditData();
              // en caso de que ya venga con delivery guardado
            }
          })
          
        }
        this.setPageUrl();
      });
  }

  // Filtra los menús que correspondan al contrato del user seleccionado
  filterMenus(menus: any) {
    this.filteredMenus = [];
    this.filteredMenus = menus
      .filter(
        (menu: any) =>
          menu.type === this.contract.typeMenu &&
          this.contract.components.includes(menu.component) &&
          this.contract.menus.some(
            (contMenu: any) => contMenu.name === menu.name
          ) && menu.menu_name != ''
      )
      .map((menu: any) => {
        const newMenu = menu;
        const findOrder = this.contract.menus.find(
          (menuContract: any) =>
            menuContract.name === menu.name && menuContract.group === menu.group
        );
        if (findOrder) {
          newMenu.order = findOrder.order;
        }
        let existingQuantity = 0;

        return { quantity: existingQuantity, typeMenu: newMenu };
      })
      .sort((a: any, b: any) => {
        if (a.typeMenu.order < b.typeMenu.order) {
          return -1;
        }
        if (a.typeMenu.order > b.typeMenu.order) {
          return 1;
        }
        return 0;
      });
    this.menusRequestedForm = this.initMenusRequestForm(this.filteredMenus);
  }

  //inicializa un formulario de menus
  initMenusRequestForm(formMenus: Array<any>) {
    const group: any = {};
    formMenus.forEach((menu) => {
      let menuKey = menu.typeMenu.especial
        ? menu.typeMenu.menu_name
        : menu.typeMenu.order + ' ' + menu.typeMenu.group;
      group[menuKey] = new FormControl({ ...menu } || '');
    });
    return new FormGroup(group);
  }

  //obtiene de la db las bebidas
  getDrinks(): void {
    this.viandasService
      .getAllDrinks()
      .pipe(take(1))
      .subscribe((data) => {
        this.drinksData = data;
        this.drinksRequestedForm = this.initDrinksRequestForm(data);
      });
  }

  //obtiene de la db los menús especiales
  getSpecialMenus() {
    this.setingServ
      .getSpecialMenu()
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          this.specialMenus = res.map((menu: any) => ({
            quantity: 0,
            typeMenu: {
              component: 'Principal',
              especial: true,
              menu_name: menu.name,
              name: menu.type,
              type: 'Especial',
              observation: '',
            },
          }));

          this.filterSpecialMenusForUser();
        },
        (error: any) => console.error(error)
      );
  }

  //filtra los menus especiales traidos de la db segun los menus especiales disponibles por contrato para el user
  filterSpecialMenusForUser() {
    let filteredSpecialMenus = this.specialMenus.filter((special: any) =>
      this.user.special_enabled?.some(
        (specialMenu: any) => specialMenu.name === special.typeMenu.menu_name
      )
    );
    if (filteredSpecialMenus.length > 0) {
      this.specialMenusRequestedForm =
        this.initMenusRequestForm(filteredSpecialMenus);
    }
  }

  //obtiene de la db la vianda a editar a través del id recibido por behaviour subject
  getOrderToEdit() {
    if (this.orderId) {
      this.viandasService
        .getOrderById(this.orderId)
        .pipe(take(1))
        .subscribe((data) => {
          this.orderToEdit = data;
          this.setEditData();
        });
    } else {
      this.orderToEdit = undefined;
    }
  }

  //setea los valores de los formularios según los datos en la vianda a editar
  setEditData() {
    this.loading = true;
    if (this.orderToEdit.requested_to) {
      // Establece el valor del formControl 'requested_to' con el UID del usuario
      this.detailsForm
        .get('requested_to')
        .setValue(this.orderToEdit.requested_to.uid);

      this.detailsForm.get('location').setValue(this.orderToEdit.location);
      this.availableLocations.push(this.orderToEdit.location);
    } else {
      // Si no existe el usuario o el usuario no está identificado, establece el valor del formControl 'requested_to' como null o algún valor predeterminado.
      this.detailsForm.get('requested_to').setValue(null); // o establece un valor predeterminado
    }
    this.setEditMenus();

    this.loading = false;
    if (this.checkContractAvailability('Bebida')) {
      this.setEditDrinks();
    }
  }

  //genera un array para el formulario de bebidas con los datos de bebidas disponibles
  generateDrinksFormArray(drinks: Array<any>) {
    let drinksFormArray: Array<any> = [];
    drinks.forEach((drink: any) => {
      let drinkData = {
        drink: drink,
        quantity: 0,
        select: false,
      };
      drinksFormArray.push({
        key: drink.id,
        required: false,
        value: drinkData,
      });
    });
    return drinksFormArray;
  }

  //inicializa el formulario de bebidas
  initDrinksRequestForm(formDrinks: Array<any>) {
    let drinks = this.generateDrinksFormArray(formDrinks);
    const group: any = {};
    drinks.forEach((drink) => {
      group[drink.key] = drink.required
        ? new FormControl(drink.value || '', Validators.required)
        : new FormControl(drink.value || '');
    });
    return new FormGroup(group);
  }

  //llena el formulario de bebidas con las bebidas pedidas en la vianda a editar
  setEditDrinks(): void {
    let drinks = this.orderToEdit.drinks_requested;

    drinks.forEach((drink: any) => {
      this.drinksRequestedForm.get(drink.drink.id).value.quantity =
        drink.quantity;
      this.requestQuantityControls.drinks += drink.quantity;
    });
  }

  getMenuOrder(menu: any) {
    let contractMenu = this.contract.menus.find(
      (menuContract: any) =>
        menuContract.component === menu.typeMenu.component &&
        menuContract.group === menu.typeMenu.group && menuContract.name === menu.typeMenu.name
    );
    return contractMenu.order.toString();
  }

  //llena los formularios de menús con los menus pedidos en la vianda a editar
  setEditMenus(): any {
    this.orderToEdit.menus_requested.forEach((menu: any) => {
      if (menu.quantity >= 1) {
        if (menu.typeMenu.type != 'Especial') {
          let menuOrder = menu.order
            ? menu.order.toString()
            : this.getMenuOrder({ ...menu });
          this.menusRequestedForm.get(
            menuOrder + ' ' + menu.typeMenu.group.toString()
          ).value.quantity = menu.quantity;
          menu.typeMenu.component === 'Principal'
            ? (this.requestQuantityControls.menus += menu.quantity)
            : null;
        } else {
          if (menu.typeMenu.component === 'Principal') {
            this.specialMenusRequestedForm.get(
              menu.typeMenu.menu_name
            ).value.quantity = menu.quantity;
            this.specialMenusRequestedForm.get(
              menu.typeMenu.menu_name
            ).value.typeMenu.observation = menu.typeMenu.observation;
            this.requestQuantityControls.specialMenus += menu.quantity;
          }
        }
      }
    });
  }

  //---------------------------------- METODOS PARA EL FORMULARIO E INPUTS --------------------------------------//

  //verifica que el user no pida más menús de los que le corresponde (si no es Responsable de Sucursal solo puede pedir un principal)
  canAddMenu(): boolean {
    const totalMenus =
      this.requestQuantityControls.menus +
      this.requestQuantityControls.specialMenus;
    const isResponsible =
      this.selectedLocation.rol === 'Responsable de sucursal';

    if (isResponsible) {
      return true;
    } else if (!isResponsible && totalMenus < 1) {
      return true;
    } else {
      return false;
    }
  }

  //deshabilita un días fuera de contrato en el datePicker
  disableCalendarDay = (d: Date): boolean => {
    let contractStartDate = new Date(
      this.dateHelpers.convertDate(this.contract.dateFrom)
    );
    let contractEndDate = new Date(
      this.dateHelpers.convertDate(this.contract.dateUntil)
    );
    return this.contractHelpers.checkDayAvailability(
      { date: d, day: dayjs(d).day() },
      this.contract,
      contractStartDate,
      contractEndDate
    );
  };

  //define los atributos del formulario
  initForm(): void {
    let orderId = this.viandasService.newRequestData$.value.orderId;

    this.detailsForm = this.fb.group({
      date: [
        { value: this.selectedDate.$d, disabled: orderId != undefined },
        [Validators.required],
      ],
      requested_to: [{ value: '', disabled: orderId != undefined }, []],
      location: [
        { value: '', disabled: orderId != undefined },
        [Validators.required],
      ],
      requested_to_undefined: [
        {
          value: '',
          disabled: this.orderToEdit != undefined,
        },
        [],
      ],
    });
  }

  //cambia el estado del input requested to dependiendo de el usuario y obtiene los datos necesarios para el usuario nuevo
  onChangeUser(event: any): void {
    if (event == 'sin_opcion') {
      this.detailsForm.get('requested_to_undefined').enable();
      this.user = undefined;
      this.availableLocations = [];
    } else {
      const user = this.company.users.find((user: any) => event == user.uid);
      this.user = user;
      this.detailsForm.get('requested_to_undefined').disable();
      // this.getavailableLocations()
    }
    this.setUserFormData();
    this.filterSpecialMenusForUser();
  }

  //---------------------------------- METODOS PARA AGREGAR O DESAGREGAR MENUS --------------------------------------//

  //suma o resta el menú que llega por parámetro del formulario de menús correspondiente
  addOrRemoveMenu(action: number, objMenu: any) {
    if (!objMenu.typeMenu.especial) {
      this.requestQuantityControls.menus += action;
      this.menusRequestedForm.get(
        objMenu.typeMenu.order.toString() +
        ' ' +
        objMenu.typeMenu.group.toString()
      ).value.quantity += action;

      if (this.checkContractAvailability('Entrada')) {
        let contractEntrada = this.contract.menus.find(
          (menuContract: any) =>
            menuContract.component === 'Entrada' &&
            menuContract.group === objMenu.typeMenu.group
        );
        contractEntrada
          ? (this.menusRequestedForm.get(
            contractEntrada.order.toString() +
            ' ' +
            contractEntrada.group.toString()
          ).value.quantity += action)
          : null;
      }

      if (this.checkContractAvailability('Postre')) {
        let contractPostre = this.contract.menus.find(
          (menuContract: any) =>
            menuContract.component === 'Postre' &&
            menuContract.group === objMenu.typeMenu.group
        );
        contractPostre
          ? (this.menusRequestedForm.get(
            contractPostre.order.toString() +
            ' ' +
            contractPostre.group.toString()
          ).value.quantity += action)
          : null;
      }

      if (this.checkContractAvailability('Fruta')) {
        let contractFruta = this.contract.menus.find(
          (menuContract: any) =>
            menuContract.component === 'Fruta' &&
            menuContract.group === objMenu.typeMenu.group
        );
        contractFruta
          ? (this.menusRequestedForm.get(
            contractFruta.order.toString() +
            ' ' +
            contractFruta.group.toString()
          ).value.quantity += action)
          : null;
      }
    } else {
      this.specialMenusRequestedForm.get(
        objMenu.typeMenu.menu_name
      ).value.quantity += action;
      this.requestQuantityControls.specialMenus += action;
    }
    this.requestQuantityControls.editedVianda = true
  }

  //cambia la observación del menu especial
  changeObservation(observation: any, menu: any) {
    this.specialMenusRequestedForm.get(
      menu.typeMenu.menu_name
    ).value.typeMenu.observation = observation;
  }

  //cambiar el valor select de un menu especial para mostrarlo debajo del input select
  onChangeEspecial(menu: any): void {
    menu.select = true;
  }

  //---------------------------------- METODOS PARA AGREGAR O DESAGREGAR BEBIDAS --------------------------------------//
  //cambiar el valor select de un menu especial para mostrarlo debajo del input select
  onChangeBebida(drink: any) {
    this.drinksRequestedForm.get(drink.drink.id).value.select = true;
  }

  //agregar o desagrega bebidas
  addOrRemoveDrink(action: number, drink: any) {
    this.drinksRequestedForm.get(drink.drink.id).value.quantity += action;
    this.requestQuantityControls.drinks += action;
    this.requestQuantityControls.editedVianda = true
  }

  //deshabilita el botón para guardar el pedido si no se cumplen las condiciones necesarias para pedir la vianda
  disableSaveButton(): boolean {
    const totalMenus =
      this.requestQuantityControls.menus +
      this.requestQuantityControls.specialMenus;
    const isResponsible =
      this.selectedLocation.rol === 'Responsable de sucursal';

    const isFormInvalid = this.detailsForm.invalid;
    const noMenusSelected = !this.thereAreMenusSelected();
    const cannotAddDrinks =
      this.checkContractAvailability('Bebida') && this.canAddDrinks();
    const cannotAddMenus = !isResponsible && totalMenus < 1;
    const cancelledVianda = this.orderToEdit? this.orderToEdit.status_request === viandaStatus.Cancelado : false   
    const editedVianda = this.requestQuantityControls.editedVianda
    return (
      isFormInvalid || noMenusSelected || cannotAddDrinks || cannotAddMenus || cancelledVianda || !editedVianda
    );
  }

  //verifica que hayan agregadas menos bebidas que menus
  canAddDrinks() {
    if (
      this.requestQuantityControls.drinks !==
      this.requestQuantityControls.menus +
      this.requestQuantityControls.specialMenus
    ) {
      return true;
    } else {
      return false;
    }
  }

  //verifica que la cantidad de bebidas y menus agregados sean equivalentes
  drinksAndMenusQuantityIsEqual() {
    if (
      this.requestQuantityControls.drinks ===
      this.requestQuantityControls.menus +
      this.requestQuantityControls.specialMenus
    ) {
      return true;
    } else {
      return false;
    }
  }

  //verifica que haya al menos un menu agregado
  thereAreMenusSelected() {
    if (
      this.requestQuantityControls.menus +
      this.requestQuantityControls.specialMenus >
      0
    ) {
      return true;
    } else {
      return false;
    }
  }
  //---------------------------------- HACER NUEVO PEDIDO --------------------------------------//

  //verifica que el user tenga permisos para editar o pedir una vianda
  checkEditPermissions() {
    let permissions = this.viandasService.getEditPermissions(
      dayjs(this.currentDate),
      dayjs(this.detailsForm.get('date').value)
    );

    if (permissions.userCanEdit) {
      this.saveRequest();
    } else {
      this.dialog.open(MessageDialogComponent, {
        data: {
          message: 'Se venció el plazo para pedir o editar viandas',
        },
      });
    }
  }

  //se guardan los datos de los formularios creando una vianda nueva o editando una existente
  async saveRequest(): Promise<void> {
    this.savingData = true;
    let deliveryData = {
      id: this.afs.createId(),
      edited: false,
    };

    // this.loading = true;
    let menus = this.sortMenus();

    let drinks = this.checkContractAvailability('Bebida')
      ? this.sortDrinks()
      : [];

    let date_meal = new Date(this.detailsForm.get('date').value);
    const initialStatus =
      this.viandaStateMachine.determineInitialState(date_meal);

    if (this.orderToEdit) {
      await this.updateEditOrderData(
        deliveryData,
        menus,
        drinks,
        initialStatus
      );

      this.viandasService
        .viandaOrderSaveChange(this.orderId, this.orderToEdit)
        .then(() => {
          this.loading = false;
          if (this.orderToEdit.delivery_order_id) {
            this.deliveryServ.editItemDelivery(
              this.orderToEdit,
              this.orderId,
              this.orderToEdit.delivery_order_id
            );
          } else if (
            this.orderToEdit.status_request === viandaStatus.Aceptado
          ) {
            this.orderToEdit.id = this.orderId;
            this.deliveryServ.addItemToDelivery(
              this.orderToEdit,
              Services.Viandas
            );
          }

          this.openSnak(
            `El pedido de vianda se ha editado con exito`,
            'OK',
            'snackBarSucces'
          );
          this.navigateToUrl(['viandas', dayjs(this.selectedDate)]);
        });
    } else {
      const request = this.createViandaRequest(
        date_meal,
        menus,
        drinks,
        initialStatus,
        deliveryData
      );

      this.viandasService.createNewOrderVianda(request).then(async (res) => {
        this.loading = false;
        if (initialStatus === viandaStatus.Aceptado) {
          request.id = res;
          this.deliveryServ.addItemToDelivery(request, Services.Viandas);
        }
        this.savingData = false;
        this.openSnak(
          `El nuevo pedido de vianda se ha realizado con exito`,
          'OK',
          'snackBarSucces'
        );
        this.navigateToUrl(['viandas', dayjs(this.selectedDate)]);
      });
    }
  }

  //modifico los datos iniciales de orderToEdit acorde a las modificiaciones realizadas en los formularios
  updateEditOrderData(
    deliveryData: any,
    menus: Array<any>,
    drinks: Array<any>,
    initialStatus: any
  ) {
    deliveryData.id = this.orderToEdit.delivery_order_id;
    deliveryData.edited = true;

    this.orderToEdit.menus_requested = menus;
    this.orderToEdit.drinks_requested = drinks;
    this.orderToEdit.status_request = initialStatus
    const statusOriginal = this.orderToEdit.status_request;
    this.orderToEdit.modifications = this.sortModifications(
      this.orderToEdit.modifications,
      statusOriginal,
      menus,
      drinks
    );
  }

  createViandaRequest(
    date_meal: any,
    menus: Array<any>,
    drinks: Array<any>,
    initialStatus: string,
    deliveryData: any
  ) {
    const request: Pedido = {
      date_request: new Date(),
      date_meal: date_meal,
      type: 'Personal',
      company: {
        companyId: this.company.id,
        companyName: this.company.name,
        contractId: this.contract.id,
      },
      mealPrice: this.contract.price.currentPrice,
      requested_to: this.user
        ? this.user
        : {
          first_names: this.detailsForm.get('requested_to_undefined').value,
          identified: false,
        },
      requester: this.requester,
      location: this.company.locations.find(
        (location: any) => location.id === this.selectedLocation.id
      ),
      menus_requested: menus,
      modifications: this.sortModifications([], 'pending', menus, drinks),
      changes: {
        company_consent: false,
        client_consent: false,
      },
      drinks_requested: drinks,
      status_making: 'pending',
      status_request: initialStatus,
      delivery_order: {},
      delivery_order_id: '',
    };

    request.modifications.push({
      date: new Date(),
      menus: request.menus_requested,
      drinks: request.drinks_requested,
      status: initialStatus,
    });

    return request;
  }

  //genera y devuelve un array con todas las bebidas que tengan quantity > 0 en el formulario
  sortDrinks(): any {
    let requestedDrinks: Array<any> = [];

    this.drinksData.forEach((drink: any) => {
      let requestedDrink = this.drinksRequestedForm.get(drink.id).value;

      if (requestedDrink.quantity > 0) {
        requestedDrinks.push({
          quantity: requestedDrink.quantity,
          drink: requestedDrink.drink,
        });
      }
    });

    return requestedDrinks;
  }

  //devuelve el resultado de todas las modificaciones
  sortModifications(modifications: any, status: any, menus: any, drinks: any) {
    menus = menus.filter((menu: any) => menu.typeMenu.menu_name != '');

    modifications.push({
      drinks: drinks,
      menus: menus,
      version: modifications.length,
      status: status,
      date: new Date(),
    });

    return modifications;
  }

  //genera un array con todos los menus que tengan quantity > 0 en el formulario
  sortMenus() {
    const menus: any = [];

    this.filteredMenus.forEach((menu) => {
      let menuRequest = this.menusRequestedForm.get(
        menu.typeMenu.order.toString() + ' ' + menu.typeMenu.group.toString()
      ).value;
      if (menuRequest.quantity > 0) {
        menus.push(menuRequest);
      }
    });

    //comprueba si hay menus especiales pedidos antes de recorrer specialMenus
    if (this.requestQuantityControls.specialMenus > 0) {
      this.specialMenus.forEach((menu: any) => {
        let specialMenuRequest = this.specialMenusRequestedForm.get(
          menu.typeMenu.menu_name
        )?.value;
        if (specialMenuRequest?.quantity > 0) {
          //genero un menu especial para cada componente del contrato
          this.contract.components.forEach((component: string) => {
            if (component != 'Bebida' && component != 'Cubiertos') {
              menus.push({
                quantity: specialMenuRequest.quantity,
                typeMenu: {
                  ...specialMenuRequest.typeMenu,
                  name: component,
                  component: component,
                },
              });
            }
          });
        }
      });
    }

    return menus;
  }

  changeStatus(vianda: any, status: string) {
    vianda.id = this.orderId;
    this.viandaStateMachine.changeStatus(vianda, status).then(() => {
      if (status === viandaStatus.Cancelado && vianda.delivery_order_id) {
        vianda.request_status = status;
        this.deliveryServ.editItemDelivery(
          vianda,
          vianda.id,
          vianda.delivery_order_id
        );
      }

      this.loading = false;
      this.openSnak(
        `El status del pedido de vianda se ha editado con exito.`,
        'OK',
        'snackBarSucces'
      );
      this.navigateToUrl(['viandas', dayjs(this.selectedDate)]);
    });
  }

  navigateToViandas() {
    const fecha = dayjs(this.selectedDate);
    this.navigateToUrl(['viandas', fecha]);
  }

  openSnak(message: string, action: string, className: string): void {
    this.snackBar.open(message, action, {
      duration: 4000,
      panelClass: [className],
      verticalPosition: 'top',
      horizontalPosition: 'end',
    });
  }
}
