import { Injectable } from '@angular/core';
import {
  AcceptedState,
  CancelledState,
  PendingCancellationState,
  PendingState,
  ViandaState,
} from '../interfaces/vianda-state.interface';
import { Pedido } from '../models/viandas';
import { viandaStatus } from '../models/enums';
import {
  ConfigState,
  ConfigStateDay,
} from '../interfaces/configState-interface';

//DAYJS
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { ViandasService } from './viandas.service';
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrAfter);

@Injectable({
  providedIn: 'root',
})
export class ViandaStateMachineService {
  private state: ViandaState;

  private configStateDefault: ConfigState = [
    {
      day: 0,
      pendingFrom: { day: 5, hour: 6 },
      pendingTo: { day: 5, hour: 9 },
    },
    {
      day: 1,
      pendingFrom: { day: 6, hour: 6 },
      pendingTo: { day: 1, hour: 10 },
    },
    {
      day: 2,
      pendingFrom: { day: 1, hour: 10 },
      pendingTo: { day: 2, hour: 10 },
    },
    {
      day: 3,
      pendingFrom: { day: 2, hour: 10 },
      pendingTo: { day: 3, hour: 10 },
    },
    {
      day: 4,
      pendingFrom: { day: 3, hour: 10 },
      pendingTo: { day: 4, hour: 10 },
    },
    {
      day: 5,
      pendingFrom: { day: 4, hour: 10 },
      pendingTo: { day: 5, hour: 10 },
    },
    {
      day: 6,
      pendingFrom: { day: 5, hour: 6 },
      pendingTo: { day: 5, hour: 9 },
    },
  ];

  getConfigState() {
    //cuando cliente tenga la config, retornar esa, sino la por defecto
    return this.configStateDefault;
  }

  constructor(private viandaServ: ViandasService) { }

  /**
   * Establece el estado del pedido en segun el estado de la solicitud.
   *
   * @param {Pedido} pedido
   * @return {void}
   */
  setState(pedido: Pedido) {
    switch (pedido.status_request) {
      case viandaStatus.Aceptado:
        this.state = new AcceptedState();
        break;
      case viandaStatus.AprobacionPendiente:
        this.state = new PendingState();
        break;
      case viandaStatus.CancelacionPendiente:
        this.state = new PendingCancellationState();
        break;
      case viandaStatus.Cancelado:
        this.state = new CancelledState();
        break;
      default:
        throw new Error('Estado no soportado');
    }
  }

  /**
 * Convierte un timestamp a milisegundos
 * @param date 
 * @returns miliseconds
 */

  convertDate(date: any) {
    return (date.seconds * 1000) + (date.nanoseconds / 1000000);
  }

  /**
   * Cambia el estado segun el estado actual.
   *
   * @param {Pedido} pedido - el pedido a ser aceptado
   * @param {Date} currentDay - la fecha actual
   * @return {void}
   */

  accept(pedido: Pedido, currentDay: Date, configState?: ConfigState): void {
    const config = configState ? configState : this.configStateDefault;
    const configDay = config.find(
      (c) => c.day === dayjs(new Date(this.convertDate(pedido.date_meal))).day()
    );
    const isWithinRange = this.isWithinPendingRange(
      dayjs(currentDay),
      configDay
    );
    this.setState(pedido);
    this.state.accept(pedido, isWithinRange);
  }

  /**
   * Cambia el estado segun el estado actual.
   *
   * @param {Pedido} pedido - el pedido a ser cancelado
   * @param {Date} currentDay - la fecha actual
   * @return {void}
   */
  cancel(pedido: Pedido, currentDay: Date, configState?: ConfigState): void {
    const config = configState ? configState : this.configStateDefault;
    const configDay = config.find(
      (c) => c.day === dayjs(new Date(this.convertDate(pedido.date_meal))).day()
    );
    const isWithinRange = this.isWithinPendingRange(
      dayjs(currentDay),
      configDay
    );

    const sameWeek =
      dayjs(currentDay).week() === dayjs(new Date(this.convertDate(pedido.date_meal))).week();
    this.setState(pedido);
    this.state.cancel(pedido, isWithinRange && sameWeek);
  }


  /**
   * Determina el estado inicial en del pedido segun la fecha.
   *
   * @param {Date} orderDate - La fecha del pedido
   * @return {viandaStatus} El estado inicial de la vianda
   */
  determineInitialState(
    orderDate: Date,
    configState?: ConfigState
  ): viandaStatus {

    const currentDay = dayjs();

    const config = configState ? configState : this.configStateDefault;
    const configDay = config.find((c) => c.day === dayjs(orderDate).day());

    if (!config) {
      return viandaStatus.AprobacionPendiente; // o algun valor por defecto
    }

    const isWithinRange = this.isWithinPendingRange(currentDay, configDay);
    const isAfterRange = this.isAfterPendingTo(currentDay, configDay, dayjs(orderDate));
    const sameWeek = currentDay.week() === dayjs(orderDate).week();

    if (isWithinRange) {
      return viandaStatus.AprobacionPendiente;
    }
    else if (!isAfterRange || !sameWeek) {
      return viandaStatus.Aceptado;
    } else {
      throw new Error('Fuera de rango de edicion. Comuniquese con Butaco');
    }
  }

  /**
   * Realiza un cambio de estado en el pedido según el estado.
   *
   * @param {Pedido} pedido - El pedido al que se le cambiará el estado
   * @param {string} status - El nuevo estado a asignar al pedido
   * @return {void}
   */

  changeStatus(pedido: Pedido, status: string) {
    const date = new Date();
    switch (status) {
      case viandaStatus.Aceptado:
        this.accept(pedido, date);
        break;
      case viandaStatus.Cancelado:
        this.cancel(pedido, date);
        break;
      default:
        throw new Error('Estado no soportado');
    }
    return this.viandaServ.changeOrderStatus(pedido.id, pedido.status_request);
  }

  //--------------------------------FUNCIONES PARA RANGO DE EDICION-----------------------------------------//
  private isWithinPendingRange(
    currentDate: dayjs.Dayjs,
    config: ConfigStateDay
  ): boolean {
  
    const pendingFromDate = dayjs().day(config.pendingFrom.day).hour(config.pendingFrom.hour).minute(0).second(0);

    let pendingToDate = dayjs().day(config.pendingTo.day).hour(config.pendingTo.hour).minute(0).second(0);
    if (config.pendingFrom.day > config.pendingTo.day) {
      pendingToDate = pendingToDate.add(1, 'week');
    }


    if (config.pendingFrom.day <= config.pendingTo.day) {
      // Rango dentro de la misma semana
      return (
        currentDate.isSameOrAfter(pendingFromDate) &&
        currentDate.isBefore(pendingToDate)
      );
    } else {
      // Rango que cruza la semana
      return (
        currentDate.isSameOrAfter(pendingFromDate) ||
        currentDate.isBefore(pendingToDate)
      );
    }
  }

  private isAfterPendingTo(
    currentDate: dayjs.Dayjs,
    config: ConfigStateDay,
    orderDate: dayjs.Dayjs
  ): boolean {

    const currentDay = currentDate.day();
    const currentHour = currentDate.hour();
    if(currentDate.isAfter(orderDate,'week')){return true}
    if (config.pendingFrom.day <= config.pendingTo.day) {

      const isAfterRange = currentDate.isSame(orderDate, 'week') &&
        (currentDay > config.pendingTo.day ||
          (currentDay === config.pendingTo.day &&
            currentHour >= config.pendingTo.hour));
            
      return isAfterRange;

    } else {

      return (currentDate.isSame(orderDate, 'week') &&
        (currentDay > config.pendingTo.day ||
          (currentDay === config.pendingTo.day &&
            currentHour >= config.pendingTo.hour)));

    }
  }

  canCreate(
    currentDate: dayjs.Dayjs,
    orderDate: dayjs.Dayjs,
    configState?: ConfigState
  ): boolean {
    const config = configState ? configState : this.configStateDefault;

    const configDay = config.find((c) => c.day === dayjs(orderDate).day());

    if (!configDay) {
      return false;
    }

    return !this.isAfterPendingTo(currentDate, configDay, orderDate);
  }


  canEdit(
    currentDate: dayjs.Dayjs,
    orderDate: dayjs.Dayjs,
    configState?: ConfigState
  ): string {
    const config = configState ? configState : this.configStateDefault;

    const configDay = config.find((c) => c.day === dayjs(orderDate).day());
    if (!configDay) {
      return 'no';
    }
    // verificar si las fechas están en la misma semana
    const sameWeek = currentDate.week() === orderDate.week();

    if (sameWeek && this.isAfterPendingTo(currentDate, configDay, orderDate) || currentDate.isAfter(orderDate, 'week')) {
      return 'no';
    } else if (sameWeek && this.isWithinPendingRange(currentDate, configDay)) {
      return 'butaco';
    } else {
      return 'all';
    }
  }
}
