import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationHelpers {
  public selectedLocation: any;

  setSelectedLocation(location: any) {
    this.selectedLocation = location
  }

  getSelectedLocation() {
    return this.selectedLocation
  }

}
