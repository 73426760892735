import { Injectable } from '@angular/core';
import { DateHelpers } from './dateHelpers';
import dayjs from 'dayjs';

@Injectable({
    providedIn: 'root',
})
export class ContractHelpers {
    constructor(
        private dateHelpers: DateHelpers
    ) { }

    //compruebo que el contrato esté vigente en la fecha que paso por parámetro
    validateContractVigency(date: any, contractStartDate: any, contractEndDate: any) {
        if ((dayjs(date).isSame(contractStartDate) || dayjs(date).isAfter(contractStartDate)) && (dayjs(date).isSame(contractEndDate) || dayjs(date).isBefore(contractEndDate))) {
            return true
        } else {
            return false
        }
    }

    //compruebo que el día de la semana que paso por parámetro esté incluído en los días de servicio del contrato
    validateWeekDay(day: any, contractUser: any) {
        let dayValid = false
        contractUser.serviceDays.forEach((serviceDay: any) => {
            let weekDay 
            if(Number.isInteger(day)){
                weekDay = day
            } else {
                weekDay = this.dateHelpers.getWeekDayNumber(day)
            }
            if (this.dateHelpers.getWeekDayNumber(serviceDay) === weekDay) {
                dayValid = true
            }
        })
        return dayValid
    }

    checkDayAvailability(weekDay: any, contractUser: any, contractStartDate: any, contractEndDate: any) {
        if (this.validateContractVigency(weekDay.date, contractStartDate, contractEndDate)
            && this.validateWeekDay(weekDay.day, contractUser)) {
            return true
        } else {
            return false
        }
    }

}