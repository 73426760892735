import { Injectable } from '@angular/core';


import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentData,
} from '@angular/fire/compat/firestore';
import {
  collection,
  getDocs,
  query,
  where,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import dayjs from 'dayjs';


import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViandasService {
  private ordersViandasRef: AngularFirestoreCollection<any>;
  requestedViandas$ = new BehaviorSubject<any>(undefined);

  constructor(private afs: AngularFirestore, private route: Router,) {
    this.ordersViandasRef = this.afs.collection<any>('ordered_viandas');
  }

  private CUT_OFF_HOUR = 10; //si por contrato no hay cierta hora definida, se usa esta
  newRequestData$ = new BehaviorSubject<any>(undefined);
  setNewRequestData(requestData: any) {
    this.newRequestData$.next(requestData);
  }

  getNewRequestData() {
    return this.newRequestData$.asObservable();
  }

  redirectToNewRequest(action:string, date: any, orderId: any, location:any) {
    this.setNewRequestData({
      date: date,
      orderId: orderId,
      location: orderId? null : location
    });
    const fechaFormateada = dayjs(date).format('D-M-YYYY');
    let id = orderId? orderId : location.id
    // Construye la URL con los parámetros proporcionados
    const url = `viandas/${action}/${fechaFormateada}/${location.name}`;
    // Navega a la URL construida
    this.route.navigate([url]);
  }


  getMyOrderBetweenDates(
    userId: string,
    dateFrom: Date,
    dateTo: Date
  ): Observable<any> {
    return this.afs
      .collection('meals_ordered', (ref) =>
        ref
          .where('requested_to.id', '==', userId)
          .where('date_Meal', '>=', dateFrom)
          .where('date_Meal', '<=', dateTo)
      )
      .valueChanges({ idField: 'id' });
  }


  orderMealSaveChange(orderId: string, mealOrdered: any): Promise<any> {
    return this.ordersViandasRef.doc(orderId).set(mealOrdered);
  }

  async changeStatus(id: any, state: any, date: any): Promise<any> {
    let orderToUpdate: any;
    orderToUpdate = await this.afs
      .collection('meals_ordered')
      .doc(id)
      .ref.get();
    orderToUpdate = orderToUpdate.data();
    orderToUpdate.status_request = state;
    orderToUpdate.date_request = date;
    return this.orderMealSaveChange(id, orderToUpdate);
  }

  getRequestMeals(
    dateFrom: Date,
    dateTo: Date,
    companyId: string
  ): Observable<any> {
    return this.afs
      .collection('ordered_viandas', (ref) =>
        ref
          .where('date_Meal', '>=', dateFrom)
          .where('date_Meal', '<=', dateTo)
          .where('company.companyId', '==', companyId)
      )
      .valueChanges({ idField: 'id' });
  }

  getRequestMealsByLocation(
    dateFrom: Date,
    dateTo: Date,
    locationId: string
  ): Observable<any> {
    return this.afs
      .collection('ordered_viandas', (ref) =>
        ref
          .where('date_Meal', '>=', dateFrom)
          .where('date_Meal', '<=', dateTo)
          .where('location.location.id', '==', locationId)
      )
      .valueChanges({ idField: 'id' });
  }

  //TODO: Change location.location in objext so we can use only one call
  getPersonalRequestMealsByLocation(
    dateFrom: Date,
    dateTo: Date,
    locationId: string
  ): Observable<any> {
    return this.afs
      .collection('ordered_viandas', (ref) =>
        ref
          .where('date_Meal', '>=', dateFrom)
          .where('date_Meal', '<=', dateTo)
          .where('location.id', '==', locationId)
      )
      .valueChanges({ idField: 'id' });
  }


  getUserRequestedViandasData(id: any): void {
    // Obtiene una referencia a la colección
    const collectionRef = this.ordersViandasRef.ref;
  
    // Realiza la consulta con .where
    collectionRef.where("requester.id", "==", id).onSnapshot(
      (querySnapshot) => {
        const data: any[] = [];
        querySnapshot.forEach((doc) => {
          const documentData = doc.data();
          // Agregar el ID del documento dentro del objeto de datos
          documentData.id = doc.id;
          data.push(documentData);
        });
        this.requestedViandas$.next(data);
      },
      (err) => console.error(err)
    );
  }

  getCompanyRequestedViandasData(id: any): void {
    // Obtiene una referencia a la colección
    const collectionRef = this.ordersViandasRef.ref;
  
    // Realiza la consulta con .where
    collectionRef.where("company.companyId", "==", id).onSnapshot(
      (querySnapshot) => {
        const data: any[] = [];
        querySnapshot.forEach((doc) => {
          const documentData = doc.data();
          // Agregar el ID del documento dentro del objeto de datos
          documentData.id = doc.id;
          data.push(documentData);
        });
        this.requestedViandas$.next(data);
      },
      (err) => console.error(err)
    );
  }
  
  getRequestedViandas(): Observable<any> {
    return this.requestedViandas$.asObservable();
  }


  /**
   * Returns the menu for a specific date
   * @param date date in format 'YYYMMDD'
   * @returns Observable
   */
  getMenuForSingleDate(date: string): Observable<any> {
    return this.afs
      .collection('menus_created')
      .doc(date)
      .valueChanges({ idField: 'id' });
  }

  getOrderByDeliveryId(deliveryId:string){
    return this.afs
    .collection('ordered_viandas', (ref) =>
      ref.where('delivery_order_id', '==', deliveryId)
    )
    .valueChanges({ idField: 'id' });
  }

  getMenusBetweenDates(dateFrom: Date, dateTo: Date): Observable<any> {
    return this.afs
      .collection('menus_created', (ref) =>
        ref.where('date', '>=', dateFrom).where('date', '<=', dateTo)
      )
      .valueChanges({ idField: 'id' });
  }

  orderMeal(orderedVianda: any): Promise<any> {
    return this.ordersViandasRef.add(orderedVianda);
  }

  viandaOrderSaveChange(orderId: string, orderedVianda: any): Promise<any> {

    return this.ordersViandasRef.doc(orderId).set(orderedVianda, { merge: true })
        .catch(error => {
            console.error("Error al guardar datos: ", error);
            throw error;
        });
}


  //Agregados
  getOrderById(idX: any): Observable<any> {
    return this.afs
      .collection('ordered_viandas')
      .doc(idX)
      .valueChanges()
  }

  async getUserOrderByDateAndLocation(userId: any, startOfDay:any, endOfDay:any, locationId:any): Promise<any> {
    try {

      const q = query(
        collection(this.afs.firestore, 'ordered_viandas'),
        where('date_meal', '>=', startOfDay),
        where('date_meal', '<=', endOfDay),
        where('requested_to.id', '==', userId),
        where('location.id', '==', locationId),
      );
  
      const querySnapshot = await getDocs(q);
      const docs = await this.buildArrayOfDocs(querySnapshot);
      return docs;

    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error;
    }
  }


  private async buildArrayOfDocs(docs: any): Promise<any[]> {
    const arrayOfDocs: any[] = [];
    docs.forEach((doc: any) => {
      arrayOfDocs.push({order:doc.data(), orderId:doc.id});
    });
    return arrayOfDocs;
  }

  async changeStatusOrderByButacoAdmin(order: any, action: any, date: any): Promise<any> {
    const newOrder = order
    newOrder.status_request = action
    return this.viandaOrderSaveChange(order.id, newOrder)
  }

  async changeStatusCancelledOrderByButacoAdmin(id: any, state: any, date: any): Promise<any> {
    let changePendingOrder: any
    changePendingOrder = await this.afs.collection('ordered_viandas').doc(id).ref.get()
    changePendingOrder = (changePendingOrder.data())
    changePendingOrder.status_request = state
    changePendingOrder.date_request = date
    return this.viandaOrderSaveChange(id, changePendingOrder)
  }

  getAllContracts(): Observable<any> {
    return this.afs
      .collection('contracts')
      .valueChanges({ idField: 'id' });
  }

  getContractById(contractId: string): Observable<any> {
    return this.afs
      .collection('contracts')
      .doc(contractId)
      .valueChanges();
  }

  getAllDrinks(): Observable<any> {
    return this.afs
      .collection('drinks')
      .valueChanges({ idField: 'id' });
  }

  createNewOrderVianda(orderedVianda: any): Promise<string> {
    return this.ordersViandasRef.add(orderedVianda)
      .then((docRef) => {
        // docRef contiene información sobre el documento recién creado
        return docRef.id;
      });
  }

  changeStatusDelivery(action: string, orderId: string): Promise<any> {
    const orderRef = this.afs.collection('delivery_orders').doc(`${orderId}`);
    return orderRef.get().toPromise()
      .then((doc: { exists: boolean; data: () => DocumentData }) => {
          return orderRef.set({ status: action }, { merge: true }); // El estado no es 'entregado', modificarlo.
      })
      .catch((error) => {
        console.error("Error getting document:", error);
        return Promise.reject(error);
      });
  }


  changeOrderStatus(orderId: string, status: string) {
    const orderRef = this.afs.collection('ordered_viandas').doc(`${orderId}`);
    return orderRef
      .get()
      .toPromise()
      .then((doc: { exists: boolean; data: () => DocumentData }) => {

        return orderRef.set({ status_request: status }, { merge: true }); // El estado no es 'entregado', modificarlo.
        
      })
      .catch((error) => {
        console.error('Error getting document:', error);
        return Promise.reject(error);
      });
  }

  
  generateMenuItem(
    quantity: number,
    menuComponent: any,
    menuContract: any,
    menuPublished: any,
    availableDay: any,
    menuType: string
  ) {
    //valido disponibilidad del menú y de la fecha en contrato, retorno un objeto con el componente y el menú o con el componente no disponible
    if (
      menuComponent &&
      menuPublished &&
      availableDay &&
      menuType === 'vianda'
    ) {
      let component;
      if (menuComponent.type != 'Especial') {
        component = menuComponent.name;
      } else {
        component = menuComponent.component + ' - Especial';
      }
      return {
        component: component,
        menu: menuComponent.menu_name,
        id: '',
        quantity: quantity,
      };
    } else if (menuComponent && menuPublished && menuType === 'menu') {
      return {
        component: menuComponent.name,
        menu: menuComponent.menu_name,
        quantity: quantity,
      };
    } else {
      return {
        component: menuContract.name,
        menu: 'No habilitado',
      };
    }
  }

  getEditPermissions(currentDate: dayjs.Dayjs, dateRequest: dayjs.Dayjs): any {
    const permissions = {
      userCanEdit: false,
      butacoCanEdit: false,
      status: 'pending',
    };

    if (this.isBeforeOrSame(currentDate, dateRequest)) {
      if (this.isMonday(dateRequest)) {
        this.handleMondayPermissions(currentDate, dateRequest, permissions);
      } else if (this.isBetweenTuesdayAndFriday(dateRequest)) {
        this.handleTuesdayToFridayPermissions(
          currentDate,
          dateRequest,
          permissions
        );
      } else {
        this.handleWeekendPermissions(currentDate, dateRequest, permissions);
      }
    }

    return permissions;
  }

  private isBeforeOrSame(
    currentDate: dayjs.Dayjs,
    dateRequest: dayjs.Dayjs
  ): boolean {
    return (
      currentDate.startOf('day').isBefore(dateRequest.startOf('day')) ||
      currentDate.startOf('day').isSame(dateRequest.startOf('day'))
    );
  }

  private isMonday(dateRequest: dayjs.Dayjs): boolean {
    return dateRequest.day() === 1;
  }

  private isBetweenTuesdayAndFriday(dateRequest: dayjs.Dayjs): boolean {
    return dateRequest.day() > 1 && dateRequest.day() < 6;
  }

  private handleMondayPermissions(
    currentDate: dayjs.Dayjs,
    dateRequest: dayjs.Dayjs,
    permissions: any
  ): void {
    if (
      (currentDate.day() === 6 &&
        currentDate.hour() < 6 &&
        currentDate.isSame(dateRequest, 'week')) ||
      (currentDate.day() < 6 &&
        currentDate.day() != 1 &&
        currentDate.isSame(dateRequest, 'week')) ||
      currentDate.isBefore(dateRequest, 'week')
    ) {
      permissions.butacoCanEdit = true;
      permissions.userCanEdit = true;
      permissions.status = 'accepted';
    } else if (
      currentDate.day() === 1 &&
      currentDate.hour() < this.CUT_OFF_HOUR
    ) {
      permissions.butacoCanEdit = true;
      permissions.userCanEdit = true;
    } else if (
      currentDate.day() === 1 &&
      currentDate.hour() >= this.CUT_OFF_HOUR
    ) {
      permissions.butacoCanEdit = true;
    }
  }

  private handleTuesdayToFridayPermissions(
    currentDate: dayjs.Dayjs,
    dateRequest: dayjs.Dayjs,
    permissions: any
  ): void {
    if (
      (dateRequest.day() != currentDate.day() ||
        (dateRequest.day() - 1 === currentDate.day() &&
          currentDate.hour() < this.CUT_OFF_HOUR)) &&
      currentDate.startOf('day').isBefore(dateRequest.startOf('day'))
    ) {
      permissions.butacoCanEdit = true;
      permissions.userCanEdit = true;
      permissions.status = 'accepted';
    } else if (
      currentDate.startOf('day').isSame(dateRequest.startOf('day')) &&
      currentDate.hour() < this.CUT_OFF_HOUR
    ) {
      permissions.butacoCanEdit = true;
      permissions.userCanEdit = true;
    } else if (
      currentDate.startOf('day').isSame(dateRequest.startOf('day')) &&
      currentDate.hour() >= this.CUT_OFF_HOUR
    ) {
      permissions.butacoCanEdit = true;
    } else if (
      currentDate.day() === dateRequest.day() &&
      currentDate.startOf('day').isBefore(dateRequest.startOf('day'))
    ) {
      permissions.butacoCanEdit = true;
      permissions.userCanEdit = true;
      permissions.status = 'accepted';
    }
  }

  private handleWeekendPermissions(
    currentDate: dayjs.Dayjs,
    dateRequest: dayjs.Dayjs,
    permissions: any
  ): void {
    if (
      (currentDate.day() === 5 && currentDate.hour() < 6) ||
      (currentDate.day() < 5 && currentDate.isSame(dateRequest, 'week')) ||
      (currentDate.startOf('day').isBefore(dateRequest.startOf('day')) &&
        !currentDate.isSame(dateRequest, 'week'))
    ) {
      permissions.butacoCanEdit = true;
      permissions.userCanEdit = true;
      permissions.status = 'accepted';
    } else if (
      currentDate.day() === 5 &&
      currentDate.hour() <= 9 &&
      currentDate.week() === dateRequest.week()
    ) {
      permissions.butacoCanEdit = true;
      permissions.userCanEdit = true;
    } else if (
      currentDate.day() === 5 &&
      currentDate.hour() > 9 &&
      currentDate.week() === dateRequest.week()
    ) {
      permissions.butacoCanEdit = true;
    }
  }


}
