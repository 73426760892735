<div class="container">
    <div class="title-section">
        <h2 class="titleSection">Mi Pedido</h2>
    </div>

    <div class="data">
        <ng-container *ngIf="selectedDateData.available_to_order; else changesDisabled">
            <button *ngIf="!selectedDateData.requestAreadySaved" mat-raised-button class="btn-request" color="primary"
            (click)="makeRequest()"
            [disabled]="!selectedDateData.menus_requested || selectedDateData.menus_requested.length === 0">
                <mat-icon fontSet="material-icons-outlined">shopping_cart</mat-icon>
                Realizar Pedido
            </button>

            <div *ngIf="selectedDateData.requestAreadySaved && selectedDateData.status_request === 'accepted'" style="display: flex; width: 95%;">
                <ng-container *ngIf="!enableEdit; else editionCancelled">
                    <button mat-raised-button class="btn-request" color="primary"
                        (click)="makeRequest()"
                        [disabled]="!selectedDateData.menus_requested || selectedDateData.menus_requested.length === 0">
                        <mat-icon fontSet="material-icons-outlined">shopping_cart</mat-icon>
                        Guardar Pedido
                    </button>
                    <div *ngIf="selectedDateData.status_request === 'accepted'">
                        <button mat-raised-button class="btn-request" color="accent" style="width:5%; margin-left: 10px;"
                            (click)="cancellReactiveReq('cancelled')" matTooltip="Cancelar Pedido"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </ng-container>
                <ng-template #editionCancelled>
                    <button mat-raised-button class="btn-request" color="warn" style="color:white; opacity: 0.8;" (click)="enableEditFn()">
                        <mat-icon fontSet="material-icons-outlined">edit_note</mat-icon>
                        Editar Pedido
                    </button>
                </ng-template>
            </div>

            <div *ngIf="selectedDateData.requestAreadySaved && selectedDateData.status_request === 'cancelled'">
                <div style="color: red;">El pedido se encuentra cancelado</div>
                <button mat-raised-button class="btn-request" color="primary"
                    (click)="cancellReactiveReq('accepted')"
                >
                    <mat-icon fontSet="material-icons-outlined">shopping_cart</mat-icon>
                    Volver a Pedir
                </button>
            </div>
        </ng-container>
        
        <ng-template #changesDisabled>
            <button mat-raised-button class="btn-request"
            [disabled]="true">
                Este pedido ya no admite cambios
            </button>
        </ng-template>

        <!-- Ubicación -->
        <div class="subheading-section">
            <h2 class="subheading-section-txt">Ubicación</h2>
            <mat-divider>
            </mat-divider>
        </div>
        <div class="request">
            <div class="request-menu" style="margin-bottom: 8px;">
                <div class="menu-container">
                    <h4 class="special-menu">{{selectedDateData.location_selected.name}} </h4>
                    <h5 class="special-name">{{selectedDateData.location_selected.address}}</h5>
                </div>
            </div>
        </div>

        <!-- Viandas -->
        <div class="subheading-section">
            <h2 class="subheading-section-txt">Viandas</h2>
            <mat-divider>
            </mat-divider>
        </div>

        <div class="request">
            <div *ngFor="let menu of selectedDateData.menus_requested ; let i = index; let last = last">
                <div class="request-menu">
                    <button mat-icon-button color="accent" (click)="removeMeal(i)" [disabled]="enableEdit">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <span class="request-quantity">{{menu.quantity}} x</span>
                    <div>
                        <div *ngFor="let menuFinal of menu.typeMenu">
                            <div class="menu-container">
                                <h4 class="special-menu">{{menuFinal.menu_name}} <b class="especial-txt" *ngIf="menuFinal.especial">(Especial)</b>
                                </h4>
                                <h5 class="special-name">{{menuFinal.name}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="request-divider" *ngIf="!last"></div>
            </div>
        </div>

        <!-- Bebidas -->
        <div class="subheading-section">
            <h2 class="subheading-section-txt">Bebidas</h2>
            <mat-divider>
            </mat-divider>
        </div>

        <div class="request" *ngFor="let drink of selectedDateData.drinks_requested; let i = index">
            <div class="request-menu" style="margin-bottom: 8px;">
                    <button mat-icon-button color="accent" (click)="removeDrink(i)" [disabled]="enableEdit">
                        <mat-icon>delete</mat-icon>
                    </button>

                    <span class="request-quantity">{{drink.quantity}} x</span>

                    <div class="menu-container">
                        <h4 class="special-menu">{{drink.drink.name}} - {{drink.drink.description}}</h4>
                        <h5 class="special-name">{{drink.drink.content}}</h5>
                    </div>
            </div>
        </div>
    </div>
</div>