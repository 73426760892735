import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkOrderStatus',
  standalone: true
})
export class CheckOrderStatusPipe implements PipeTransform {

  transform(orderStatus: any, actionPermitted: String): boolean {
    //actionPermitted es la accion a realizar respecto al pedido, puede ser: 'cancel', 'activate' 
    let meetsCondition
    switch (actionPermitted) {
      case ('cancel'):
        if (orderStatus == 'accepted' || orderStatus == 'pending') {
          meetsCondition = true
        }
        break;
      case ('activate'):
        if (orderStatus == 'cancelled' || orderStatus == 'pending cancellation') {
          meetsCondition = true
        }
        break;
      case ('any'):
        if (orderStatus == 'pending' || orderStatus == 'pending cancellation') {
          meetsCondition = true
        }
        break;
      default:
        meetsCondition = false
        break;
    }
    return meetsCondition
  }

}
