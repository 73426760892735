import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, Router } from '@angular/router';
import { NgIf, NgForOf } from '@angular/common';

//interfaces and models
import { Customer } from 'src/app/shared/interfaces/customer.interface';
import { UserClient } from 'src/app/shared/models/user.model';
import { UserStatus } from 'src/app/shared/models/enums';

//ANGULAR MATERIAL
import {
  UntypedFormControl,
  ReactiveFormsModule,
  FormBuilder,
  FormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';

//rxjs
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

//SERVICES
import { CompanyDataService } from 'src/app/shared/services/company-data.service';
import { UserDataService } from 'src/app/shared/services/user-data.service';
import { ViandasMainComponent } from '../viandas-main/viandas-main.component';
import dayjs from 'dayjs';
import { ViandasService } from 'src/app/shared/services/viandas.service';

@Component({
  selector: 'butaco-detalle-por-usuario',
  templateUrl: './detalle-por-usuario.component.html',
  styleUrls: ['./detalle-por-usuario.component.scss'],
  imports: [
    forwardRef(() => ViandasMainComponent),
    MatInputModule,
    MatMenuModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatProgressBarModule,
    MatPaginatorModule,
    NgForOf,
    NgIf,
  ],
  standalone: true,
})
export class DetallePorUsuarioComponent implements OnInit {
  @Input() companyRequestedViandas: Array<any>;
  @Input() selectedDate: Date;
  @Input() selectedLocation: any;
  viandasDetailArray: Array<any>;
  loading: boolean;
  searchingItems = false;
  search = new UntypedFormControl();
  originalViandasDetailArray: Array<any>; // Mantén una copia de respaldo
  currentPage = 0;
  pageSize = 10; // Número de elementos por página
  constructor(private route: Router, private viandasServ: ViandasService) {}

  ngOnInit(): void {
    this.loading = true;
    this.generateDetailArray();
    this.originalViandasDetailArray = this.viandasDetailArray.slice();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    this.generateDetailArray();
  }

  ngAfterViewInit(): void {
    this.search.valueChanges.pipe(debounceTime(750)).subscribe((value: any) => {
      if (value.length >= 3) {
        this.searchingItems = true;
        this.searchItems(String(value));
      }
    });
  }

  resetData() {
    this.viandasDetailArray = this.originalViandasDetailArray.slice();
    this.currentPage = 0; // Restablece la página a la primera
  }

  get startIndex() {
    return this.currentPage * this.pageSize;
  }

  onPageChange(event: any) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  generateDetailArray() {
    this.viandasDetailArray = [];
    this.companyRequestedViandas.forEach((vianda) => {
      const dateMeal = new Date(vianda.date_meal.seconds * 1000);
      if (
        dayjs(dateMeal).format('DD/MM/YYYY') ===
        dayjs(this.selectedDate).format('DD/MM/YYYY')
      ) {
        this.viandasDetailArray.push({
          user:
            vianda.requester.first_names + ' ' + vianda.requester.last_names,
          quantity: this.getMenusQuantity(vianda),
          status: vianda.status_request,
          date: dayjs(dateMeal).format('DD/MM/YYYY'),
          id: vianda.id,
        });
        // + " " + dayjs(dateMeal).format('HH:mm') no agrego la fecha porque se guarda como las 00:00
      }
    });
    this.loading = false;
  }

  getMenusQuantity(vianda: any) {
    let quantity = 0;
    vianda.menus_requested.forEach((menu: any) => {
      if (menu.typeMenu.component === 'Principal') quantity += menu.quantity;
    });
    return quantity;
  }

  searchItems(term: string): void {
    term = term.toLocaleLowerCase().trim();
    const filteredDetails = this.viandasDetailArray.filter((vianda) =>
      vianda.user.toLocaleLowerCase().includes(term)
    );
    this.viandasDetailArray = filteredDetails;
    this.searchingItems = false;
  }

  redirectToNewRequest( date: any, id: any) {
    this.viandasServ.redirectToNewRequest(
      'edicion de pedido',
      dayjs(date),
      id,
      this.selectedLocation
    );
    /* 
    let routeId = action === 'crear' ? this.selectedLocation.id : id
    this.viandasServ.setNewRequestData({action:action, date:date, id:routeId})
    // Construye la URL con los parámetros proporcionados
    const url = `/${action}/${date}/${routeId}`;
    // Navega a la URL construida
    this.route.navigate([url]); */
  }

  changeStatus(status: string, vianda: any) {
    this.viandasServ.changeOrderStatus(vianda.id, status);
  }
}
