import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViandasRoutingModule } from './viandas-routing.module';
import { MaterialModule } from '../../material/material.module';
import { ViandasMainComponent } from './viandas-main/viandas-main.component';
import { ViandasAdminComponent } from './viandas-admin/viandas-admin.component';
import { ViandasNormalUserComponent } from './viandas-normal-user/viandas-normal-user.component';
import { ViandasAdminDetailComponent } from './viandas-admin-detail/viandas-admin-detail.component';
import { ViandasNewRequestDialogComponent } from './viandas-new-request-dialog/viandas-new-request-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderMealsFormPipe } from './pipes/order-meals-form.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { ViandasNormalUserDatesComponent } from './viandas-normal-user/viandas-normal-user-dates/viandas-normal-user-dates.component';
import { ViandasNormalUserMenusComponent } from './viandas-normal-user/viandas-normal-user-menus/viandas-normal-user-menus.component';
import { ViandasNormalUserRequestComponent } from './viandas-normal-user/viandas-normal-user-request/viandas-normal-user-request.component';
import { MenuOrderedByGroupPipe } from './pipes/menu-ordered-by-group.pipe';
import { ViandasGeneralUserDatesComponent } from './viandas-normal-user/viandas-general-user-dates/viandas-general-user-dates.component';
import { ViandasGeneralUserResumeComponent } from './viandas-normal-user/viandas-general-user-resume/viandas-general-user-resume.component';
import { ExampleHeader } from './viandas-normal-user/viandas-general-user-dates/viandas-general-user-dates.component';
import { PedidoDeViandaComponent } from './pedido-de-vianda/pedido-de-vianda.component';
import { MenusComponent } from './menus/menus.component';
import { DetallePorUsuarioComponent } from './detalle-por-usuario/detalle-por-usuario.component';
import { ResumeViandasComponent } from './resume-viandas/resume-viandas.component';
import { TarjetaViandaComponent } from './pedido-de-vianda/tarjeta-vianda/tarjeta-vianda.component';


@NgModule({
  declarations: [

    ViandasAdminComponent,
    ViandasNormalUserComponent,
    ViandasAdminDetailComponent,
    ViandasNewRequestDialogComponent,
    OrderMealsFormPipe,
    ViandasNormalUserDatesComponent,
    ViandasNormalUserMenusComponent,
    ViandasNormalUserRequestComponent,
    MenuOrderedByGroupPipe,
    ViandasGeneralUserDatesComponent,
    ViandasGeneralUserResumeComponent,
    ExampleHeader,


  ],
  imports: [
    CommonModule,
    ViandasRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,    PedidoDeViandaComponent,
    MenusComponent,
    ViandasMainComponent,
    DetallePorUsuarioComponent,
    TarjetaViandaComponent,
  ]
})
export class ViandasModule { }
