import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import dayjs from 'dayjs';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { skip, switchMap, take, takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common.service';
import { ViandasNewRequestDialogComponent } from '../viandas-new-request-dialog/viandas-new-request-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UserDataService } from 'src/app/shared/services/user-data.service';
import { CompanyDataService } from 'src/app/shared/services/company-data.service';
import { ViandasService } from 'src/app/shared/services/viandas.service';

@Component({
  selector: 'butaco-viandas-admin-detail',
  templateUrl: './viandas-admin-detail.component.html',
  styleUrls: ['./viandas-admin-detail.component.scss'],
})
export class ViandasAdminDetailComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  startDay: any;
  endDay: any;
  params: any;
  requests: any;
  requestsRegUsers: any[] = [];
  requestsInvUsers: any[] = [];
  loadingData = false;
  errorGetingData = false;
  currentDate: any;

  totalRequestToday = 0;

  user: any;
  company: any;
  drinkList: any;
  currentLocationId: any;
  location: any;
  constructor(
    private activeRoute: ActivatedRoute,
    private viandasServ: ViandasService,
    private commonService: CommonService,
    private userDataService: UserDataService,
    private companyDataServ: CompanyDataService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.commonService.setTitleToolbar('Viandas');
    dayjs.locale('es');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    this.activeRoute.params
      .pipe(
        switchMap((params: Params) => {
          this.params = params;
          this.loadingData = true;
          this.currentLocationId = params.location;

          this.commonService.setTitleToolbar(
            'Viandas - ' + this.params.location_name
          );
          this.currentDate = dayjs(params.date, 'DD-MM-YYYY');
          const startDay = this.currentDate.startOf('day').toDate();
          const endDay = this.currentDate.endOf('day').toDate();

          const requestAdmin = this.viandasServ.getRequestMealsByLocation(
            startDay,
            endDay,
            params.location
          );

          const requestPersonal =
            this.viandasServ.getPersonalRequestMealsByLocation(
              startDay,
              endDay,
              params.location
            );
          // console.log('combineLatest');

          return combineLatest([requestAdmin, requestPersonal]);
        })
      )
      // .pipe(skip(1)) //TODO: Sacar cuando este arreglado el endpoint
      .subscribe(
        (res) => {
          // console.log('subscribe');
          if (!res.some((r: any) => r === undefined)) {
            this.totalRequestToday = 0;
            this.requestsInvUsers = [];
            this.requestsRegUsers = [];
            this.requests = res[0]
              .filter((ord: any) => ord.status_request !== 'cancelled')
              .concat(
                res[1].filter((ord: any) => ord.status_request !== 'cancelled')
              );
            // console.log(res[1]);

            const totalQuantity: any = [];
            if (this.requests.length > 0) {
              this.requests.forEach((req: any, idx: number) => {
                if (req.type === 'Invitation') {
                  req.reqPrincipal = req.menus_requested.filter(
                    (men: any) => men.typeMenu.component === 'Principal'
                  );
                  req.reqEntry = req.menus_requested
                    .map((men: any) => men.typeMenu.component)
                    .includes('Entrada');
                  req.reqDessert = req.menus_requested
                    .map((men: any) => men.typeMenu.component)
                    .includes('Postre');
                  const quantyArray = req.menus_requested
                    .filter(
                      (men: any) => men.typeMenu.component === 'Principal'
                    )
                    .map((men: any) => men.quantity);

                  req.quantity = quantyArray.reduce((a: any, b: any) => {
                    return a + b;
                  }, 0);
                  totalQuantity.push(req.quantity);
                  this.requestsInvUsers.push(req);
                } else {
                  req.reqPrincipal = req.menus_requested.filter((men: any) => {
                    if (men.especial === true) {
                      return true;
                    }
                    if (men.component && men.component === 'Principal') {
                      return true;
                    }
                    return false;
                  });
                  req.reqEntry = req.menus_requested
                    .map((men: any) => men.component)
                    .includes('Entrada');
                  req.reqDessert = req.menus_requested
                    .map((men: any) => men.component)
                    .includes('Postre');
                  const quantyArray = req.menus_requested
                    .filter((men: any) => men.component === 'Principal')
                    .map((men: any) => men.quantity);

                  totalQuantity.push(1);
                  this.requestsRegUsers.push(req);
                }

                if (idx === this.requests.length - 1) {
                  this.totalRequestToday = totalQuantity.reduce(
                    (a: any, b: any) => {
                      return a + b;
                    },
                    0
                  );
                }
              });
            }
            // console.log(this.locations);
            this.loadingData = false;
            //TODO: Armar request array
          }
        },
        (err: any) => {
          console.log(err);

          this.loadingData = false;
          this.errorGetingData = true;
        }
      );

    this.getRemainingData();
  }
  getRemainingData() {
    const userData = this.userDataService.getUser();
    const companyData = this.companyDataServ.getCompany();
    const drinkList = this.commonService.getDrinkList();

    combineLatest([userData, companyData, drinkList])
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (!res.some((r: any) => r === undefined)) {
          this.user = res[0];
          this.company = res[1];
          this.drinkList = res[2];

          if (this.company) {
            let location;
            const contract = this.company.contracts.find((cont: any) => {
              const findedLoc = cont.locations.find((loc: any) => {
                return loc.id === this.currentLocationId;
              });
              if (findedLoc) {
                location = findedLoc;
              }
              return findedLoc ? findedLoc : undefined;
            });
            this.location = {
              location: location,
              contract: contract,
            };
          }
        }
      });
  }

  changeDay(action: string) {
    let newDate;
    if (action === 'next') {
      newDate = dayjs(this.currentDate).add(1, 'day').format('DD-MM-YYYY');
    } else {
      newDate = dayjs(this.currentDate).subtract(1, 'day').format('DD-MM-YYYY');
    }

    this.router.navigate([
      '/viandas/ubicacion',
      {
        location: this.params.location,
        location_name: this.params.location_name,
        date: newDate,
      },
    ]);
  }

  newMealRequest(): void {
    // const dialogRef = this._bottomSheet.open(ViandasNewRequestDialogComponent, {
    const dialogRef = this.dialog.open(ViandasNewRequestDialogComponent, {
      data: {
        locations: [this.location],
        user: this.user,
        company: this.company,
        locationsSelected: [],
        drinks: this.drinkList,
        date: dayjs(),
      },
      autoFocus: false,
      // disableClose: true,
    });

    // dialogRef.afterDismissed().subscribe((result) => {
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.snackBar.open('🎉 El pedido fue realizado correctamente', '', {
          duration: 3000,
        });
      }
    });
  }
}
