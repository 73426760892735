import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderMealsForm',
})
export class OrderMealsFormPipe implements PipeTransform {
  transform(value: any): any[] {
    const formsSorted = value.sort((n1: any, n2: any) => {
      if (n1 > n2) {
        return 1;
      }

      if (n1 < n2) {
        return -1;
      }

      return 0;
    });

    return formsSorted;
  }
}
