<div class="container" (click)="isOpen = !isOpen" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
  *ngIf="vianda">
  <div class="edit">
    <ng-container *ngIf="
        (!vianda.delivery_order?.status ||
          vianda.delivery_order?.status === 'pendiente') &&
          dayData.editPermissions | checkEditPermissions : 'usuario';
        else disabledButton
      ">
      <button mat-icon-button color="primary" (click)="editVianda(dayData.dateNewRequest, vianda.id)">
        <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
      </button>
    </ng-container>

    <ng-template #disabledButton>
      <button mat-icon-button color="primary" disabled>
        <mat-icon fontSet="material-icons-outlined">edit_off</mat-icon>
      </button>
    </ng-template>
  </div>
  <div class="header">
    <div class="delivery-number">
      <h5 class="section-title">Orden de entrega</h5>
      <div class="section-content">
        {{ vianda.delivery_order?.number || "-" }}
      </div>
    </div>
  </div>

  <div class="content">
    <div>
      <div class="section-title">Pedido de Vianda</div>

      <div class="section-content statusTxt cancelled" *ngIf="vianda.status_request == 'cancelled'">
        Cancelado
      </div>
      <div class="section-content statusTxt accepted" *ngIf="vianda.status_request == 'accepted'">
        Aceptado
      </div>
      <div class="section-content statusTxt pending" *ngIf="vianda.status_request == 'pending'">
        Pendiente
      </div>
      <div class="section-content statusTxt pending" *ngIf="vianda.status_request == 'pending cancellation'">
        Cancelacion pendiente
      </div>
    </div>
    <div>
      <div class="section-title">Estado de Entrega</div>

      <div class="section-content statusTxt" *ngIf="!vianda.delivery_order?.status">
        -
      </div>
      <div class="section-content statusTxt cancelled" *ngIf="vianda.delivery_order?.status == 'cancelado'">
        Cancelado
      </div>
      <div class="section-content statusTxt accepted" *ngIf="vianda.delivery_order?.status == 'entregado'">
        Entregado
      </div>
      <div class="section-content statusTxt sending" *ngIf="vianda.delivery_order?.status == 'viajando'">
        En viaje
      </div>
      <div class="section-content statusTxt pending" *ngIf="vianda.delivery_order?.status == 'preparando'">
        Preparando
      </div>
      <div class="section-content statusTxt pending" *ngIf="vianda.delivery_order?.status == 'pendiente'">
        Pendiente
      </div>
      <div class="section-content statusTxt pending" *ngIf="vianda.delivery_order?.status == 'firma pendiente'">
        Pendiente de firma
      </div>

    </div>
    <div>
      <div>
        <div class="section-title">Detalle</div>
      </div>

      <div class="menu-data">
        <div class="menu" *ngFor="let menu of groupedMenus">
          <div class="component-admin">
            <div [ngClass]="
                menu.typeMenu.name === 'No habilitado'
                  ? 'component-unavailable'
                  : 'component'
              " class="section-content">
              <ng-container *ngIf="menu.typeMenu.especial; else noEspecial">{{
                menu.typeMenu.menu_name
                }}</ng-container>
              <ng-template #noEspecial>{{ menu.typeMenu.name }}</ng-template>
            </div>
            <ng-container *ngIf="
                menu.quantity > 1 &&
                userRol === 'Responsable de sucursal' &&
                menu.typeMenu.name != 'No habilitado'
              ">
              <div class="quantity">x{{ menu.quantity }}</div>
            </ng-container>
          </div>
        </div>
        <div *ngIf="vianda.drinks_requested.length >= 1" class="menu">
          <div>
            <div class="section-title">Bebidas</div>
          </div>
          <div *ngFor="let drink of vianda.drinks_requested">
            <div class="component-admin">
              <div class="menu-name">
                {{ drink.drink.name }} {{ drink.drink.content }}
              </div>
              <ng-container *ngIf="
              drink.quantity > 1 &&
              userRol === 'Responsable de sucursal' &&
             drink.drink.name != 'No habilitado'
            ">
            <div class="quantity">x{{ drink.quantity }}</div>
          </ng-container>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
    cdkConnectedOverlayWidth="200px" (overlayOutsideClick)="isOpen = false" [cdkConnectedOverlayPositions]="[
    
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 10,
    },{
      originX: 'start',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: -10,
    }
  ]" cdkConnectedOverlayPush="true" [cdkConnectedOverlayViewportMargin]="7">
    <div class="modal mat-elevation-z6">
      <div class="header">
        <div class="delivery-number">
          <h5 class="section-title">Orden de entrega</h5>
          <div class="section-content">
            {{ vianda.delivery_order?.number || "-" }}
          </div>
        </div>
      </div>

      <div class="content">
        <div>
          <div class="section-title">Pedido de Vianda</div>

          <div class="section-content statusTxt cancelled" *ngIf="vianda.status_request == 'cancelled'">
            Cancelado
          </div>
          <div class="section-content statusTxt accepted" *ngIf="vianda.status_request == 'accepted'">
            Aceptado
          </div>
          <div class="section-content statusTxt pending" *ngIf="vianda.status_request == 'pending'">
            Pendiente
          </div>
          <div class="section-content statusTxt pending" *ngIf="vianda.status_request == 'pending cancellation'">
            Cancelacion pendiente
          </div>
        </div>
        <div>
          <div class="section-title">Estado de Entrega</div>

          <div class="section-content statusTxt" *ngIf="!vianda.delivery_order?.status">
            -
          </div>
          <div class="section-content statusTxt cancelled" *ngIf="vianda.delivery_order?.status == 'cancelado'">
            Cancelado
          </div>
          <div class="section-content statusTxt accepted" *ngIf="vianda.delivery_order?.status == 'entregado'">
            Entregado
          </div>
          <div class="section-content statusTxt sending" *ngIf="vianda.delivery_order?.status == 'viajando'">
            En viaje
          </div>
          <div class="section-content statusTxt pending" *ngIf="vianda.delivery_order?.status == 'preparando'">
            Preparando
          </div>
          <div class="section-content statusTxt pending" *ngIf="vianda.delivery_order?.status == 'pendiente'">
            Pendiente
          </div>
          <div class="section-content statusTxt pending" *ngIf="vianda.delivery_order?.status == 'firma pendiente'">
            Pendiente de firma
          </div>
        </div>

        <div>
          <div class="section-title">Detalle</div>
        </div>

        <div class="menu-data">
          <div class="menu" *ngFor="let menu of vianda.menus_requested">
            <div class="component-admin">
              <div [ngClass]="
                  menu.typeMenu.name === 'No habilitado'
                    ? 'component-unavailable'
                    : 'component'
                " class="section-content">
                {{ menu.typeMenu.name }}
              </div>
              <ng-container *ngIf="
                  menu.quantity > 1 &&
                  userRol === 'Responsable de sucursal' &&
                  menu.typeMenu.name != 'No habilitado'
                ">
                <div class="quantity">x{{ menu.quantity }}</div>
              </ng-container>
            </div>
            <div class="menu-name">
              {{ menu.typeMenu.menu_name }}
            </div>
          </div>

          <div *ngIf="vianda.drinks_requested.length >= 1" class="menu">
            <div>
              <div class="section-title">Bebidas</div>
            </div>
            <div *ngFor="let drink of vianda.drinks_requested">
              <div class="component-admin">
                <div class="menu-name">
                  {{ drink.drink.name }} {{ drink.drink.content }}
                </div>
                <ng-container *ngIf="
                drink.quantity > 1 &&
                userRol === 'Responsable de sucursal' &&
               drink.drink.name != 'No habilitado'
              ">
              <div class="quantity">x{{ drink.quantity }}</div>
            </ng-container>
              </div>
            </div>
  
          </div>
          <div class="action-buttons">
            <button mat-mini-fab *ngIf="
                (!vianda.delivery_order ||
                  vianda.delivery_order?.status === 'pendiente') &&
                  dayData.editPermissions | checkEditPermissions : 'usuario'
              " color="primary" (click)="editVianda(dayData.dateNewRequest, vianda.id)" [disabled]="!canEdit()">
              <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
            </button>

            <button *ngIf="
                (!vianda.delivery_order ||
                  vianda.delivery_order?.status === 'pendiente') &&
                (vianda.status_request | checkOrderStatus : 'cancel') &&
                (dayData.editPermissions | checkEditPermissions : 'usuario')
              " color="accent" mat-mini-fab (click)="cancelRequest()" [disabled]="!canEdit()">
              <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
            </button>
            <div class="cant-edit-message" *ngIf="
                (vianda.delivery_order?.status !== 'pendiente' &&
                  vianda.delivery_order) ||
                !canEdit() ||
                !(dayData.editPermissions | checkEditPermissions : 'usuario')
              ">
              Ya no puede realizar modificaciones
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>