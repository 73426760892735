import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentData,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import moment from 'moment-timezone';
import { first, map, take, tap } from 'rxjs/operators';
import {
  collection,
  getDocs,
  query,
  Timestamp,
  where,
} from '@angular/fire/firestore';
import { DeliveryOrder } from '../models/delivery-order';
import { ViandasService } from './viandas.service';
import { deliveryStatus, Services, viandaStatus } from '../models/enums';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeliveryService {
  private deliveryRequests: AngularFirestoreCollection<any>;
  private utilsCollection: AngularFirestoreCollection<any>;

  constructor(
    private afs: AngularFirestore,
    private viandaServ: ViandasService
  ) {
    this.deliveryRequests = this.afs.collection<any>('delivery_orders');
    this.utilsCollection = this.afs.collection<any>('utils');
  }

  incrementDeliveryQuantity() {
    const db = firebase.firestore();
    const increment = firebase.firestore.FieldValue.increment(1);
    const storyRef = db.collection('utils').doc('delivery_counter');
    storyRef.update({ count: increment });
  }

  getDeliveryCount() {
    const db = firebase.firestore();
    return this.utilsCollection
      .doc('delivery_counter')
      .valueChanges()
      .pipe(take(1)) // Utilizamos el operador 'take(1)' para completar el observable después de recibir el primer valor
      .toPromise() // Convertimos el observable en una promesa
      .then((doc: any) => {
        return doc;
      }); // Accedemos al campo 'count' del documento
  }

  getDeliveryByViandaId(id: string) {
    return this.afs
      .collection('delivery_orders')
      .doc(id)
      .valueChanges({ idField: 'id' });
  }

  getDeliveryById(id: string) {
    return this.afs
      .collection('delivery_orders')
      .doc(id)
      .valueChanges({ idField: 'id' })
      .pipe(first())
      .toPromise().then((doc: any) => {
        return doc;
      }) ;
  }

  createDeliveryOrder(deliveryOrder: any, deliveryId: string) {
    return this.deliveryRequests.doc(deliveryId).set(deliveryOrder);
  }

  saveDeliveryOrder(newOrder: any, order: any): Promise<any> {
    const orderRef = this.afs.collection('delivery_orders').doc(`${order.id}`);
    return orderRef.set(newOrder, { merge: true });
  }

  saveDeliveryOrderbyId(newOrder: any, id: string): Promise<any> {
    const orderRef = this.afs.collection('delivery_orders').doc(`${id}`);
    return orderRef.set(newOrder, { merge: true });
  }

  changeStatusDelivery(action: string, orderId: string): Promise<any> {
    const orderRef = this.afs.collection('delivery_orders').doc(`${orderId}`);
    return orderRef
      .get()
      .toPromise()
      .then((doc: { exists: boolean; data: () => DocumentData }) => {
        if (doc.exists && doc.data().status === 'entregado') {
          return Promise.resolve(); // El estado es 'entregado', no es necesario modificarlo.
        } else {
          return orderRef.set({ status: action }, { merge: true }); // El estado no es 'entregado', modificarlo.
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
        return Promise.reject(error);
      });
  }


  async getDeliveryByArray(ordersID: string[]): Promise<any[]> {
    if (ordersID.length === 0) {
      return [];
    }
    const arrayOfIds = ordersID; 
    const q = query(
      collection(this.afs.firestore, 'delivery_orders'),
      where(firebase.firestore.FieldPath.documentId(), 'in', arrayOfIds),
    );

    try {
      const querySnapshot = await getDocs(q);
      const docs: any[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        docs.push({ id: doc.id, ...data });
      });
      return docs;
    } catch (error) {
      console.error("Error fetching deliveries: ", error);
      throw error;
    }
  
  }

  //-----------------------REFACTOR OE-------------------------//
  async getDeliveryByLocationDateClient(
    clientId: any,
    locationId: any,
    deliveryDate: Date
  ) {
    let date = deliveryDate;
    const startOfDateToSearch = moment(date).startOf('day').toDate();
    const endOfDateToSearch = moment(date).endOf('day').toDate();

    const startOfDayTimestamp = Timestamp.fromDate(startOfDateToSearch);
    const endOfDayTimestamp = Timestamp.fromDate(endOfDateToSearch);

    try {
      const q = query(
        collection(this.afs.firestore, 'delivery_orders'),
        where('delivered_date', '>=', startOfDayTimestamp),
        where('delivered_date', '<=', endOfDayTimestamp),
        where('client.companyId', '==', clientId),
        where('location.id', '==', locationId),
        where('status', '==', 'pendiente')
      );

      const querySnapshot = await getDocs(q);
      const docs = await this.buildArrayOfDocs(querySnapshot);
      return docs;
    } catch (error) {
      console.error('Error getting documents: ', error);
      throw error;
    }
  }

  private convertToDate(date: any): Date {
    if (date instanceof Date) {
      return date;
    } else if (typeof date === 'string' || typeof date === 'number') {
      return new Date(date);
    } else if (date.seconds !== undefined && date.nanoseconds !== undefined) {
      return new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
    } else {
      throw new Error('Formato de fecha no reconocido');
    }
  }

  private async buildArrayOfDocs(docs: any): Promise<any[]> {
    const arrayOfDocs: any[] = [];
    docs.forEach((doc: any) => {
      arrayOfDocs.push({ order: doc.data(), orderId: doc.id });
    });
    return arrayOfDocs;
  }

  async addItemToDelivery(item: any, service: Services): Promise<void> {
    try {
      const existingOrders = await this.getDeliveryByLocationDateClient(
        item.company.companyId,
        item.location.id,
        this.convertToDate(item.date_meal)
      );

      item.service = service

      if (existingOrders.length > 0) {
        await this.updateExistingOrder(existingOrders[0], item);
      } else {
        await this.createAndSaveNewOrder(item);
      }
    } catch (error) {
      console.error('Error al gestionar la entrega:', error);
    }
  }

  private async updateExistingOrder(
    existingOrder: any,
    item: any
  ): Promise<void> {
    const orderId = existingOrder.orderId;
    const updatedOrder = existingOrder.order;

    updatedOrder.items.push(item);

    try {
      await this.saveDeliveryOrderbyId(updatedOrder, orderId);
      item.delivery_order_id = orderId;
      await this.viandaServ.viandaOrderSaveChange(item.id, item);
    } catch (error) {
      console.error('Error actualizando la orden:', error);
    }
  }

  private async createAndSaveNewOrder(item: any): Promise<void> {
    try {
      const deliveryCount = await this.getDeliveryCount();
      const newDeliveryOrder: DeliveryOrder = this.createDeliveryOrderObject(
        item,
        deliveryCount.count + 1
      );

      const deliveryId = this.afs.createId();
      await this.createDeliveryOrder(newDeliveryOrder, deliveryId);
      await this.incrementDeliveryQuantity();

      item.delivery_order_id = deliveryId;
      await this.viandaServ.viandaOrderSaveChange(item.id, item);
    } catch (error) {
      console.error('Error creando la nueva orden de entrega:', error);
    }
  }

  private createDeliveryOrderObject(
    item: any,
    orderNumber: number
  ): DeliveryOrder {
    return {
      number: orderNumber,
      receipt_generated: false,
      date_creation: item.date_request,
      status: 'pendiente',
      client: {
        companyId: item.company.companyId,
        companyName: item.company.companyName,
        contractId: item.company.contractId,
      },
      service: 'Viandas',
      items: [item],
      location: {
        address: item.location.address,
        coordinates: {
          latitude: item.location.coordinates.latitude,
          longitude: item.location.coordinates.longitude,
        },
        id: item.location.id,
        name: item.location.name,
        observation: item.location.observation,
        sucursal: null,
      },
      delivered_date: item.date_meal,
      delivered_by: '',
      delivered_to: '',
      delivered_receipt: '',
    };
  }

  editItemDelivery(item: any, itemID: string, oeID: string): void {
    this.getDeliveryById(oeID)
      .then(async (existingOrder: any) => {
        if (existingOrder) {
          const items = existingOrder.items;
          const itemIndex = items.findIndex((i: any) => i.id === itemID);

          if (itemIndex !== -1) {
            switch (item.status_request) {
              case viandaStatus.Cancelado:
                items.splice(itemIndex, 1);
                if (items.length == 0) {
                  existingOrder.status = deliveryStatus.Cancelado;
                }
                item.delivery_order_id = '';
                item.delivery_order = null;
                await this.viandaServ.viandaOrderSaveChange(item.id, item);
                break;
              case viandaStatus.Aceptado:
                items[itemIndex] = item;

                break;
              default:
                console.log('Estado del item no modificado.');
                break;
            }

            try {
              await this.saveDeliveryOrderbyId(existingOrder, oeID);
             
            } catch (error) {
              console.error('Error actualizando la orden de entrega:', error);
            }
          } else {
            console.error('Item no encontrado en la orden de entrega.');
          }
        } else {
          console.error('Orden de entrega no encontrada.');
        }
      })
      .catch((error) => {
        console.error('Error al obtener la orden de entrega:', error);
      });
  }
}
