import { Injectable } from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestore,
} from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class CompanyDataService {
  private contractsCollection: AngularFirestoreCollection<any>;
  private companyCollection: AngularFirestoreCollection<any>;

  contract$ = new BehaviorSubject<any>(undefined);
  client$ = new BehaviorSubject<any>(undefined);
  customer = new BehaviorSubject<any>('');
  generalData$ = new BehaviorSubject<any>(undefined); //guarda info general de la empresa y el user (userList, contracts, locationsAvailable, userData, etc)

  constructor(private afs: AngularFirestore) {
    this.contractsCollection = this.afs.collection<any>('contracts');
    this.companyCollection = this.afs.collection<any>('clients');
  }

  
  setGeneralData(company:any, user:any, locationsAvailable:any, contracts:any){
    this.generalData$.next({company:company, user:user, availableLocations:locationsAvailable, contracts:contracts});
  }

  getGeneralData() {
    return this.generalData$.asObservable();
  }


  getCompany(): Observable<any> {
    return this.client$.asObservable();
  }

  getCompanyData(companyId: string): void {
    this.companyCollection
      .doc(companyId)
      .valueChanges({ idField: 'id' })
      .subscribe(
        (res) => {
          this.client$.next(res);
        },
        (err) => console.error(err)
      );
  }

  getCustomerContract(): Observable<any> {
    return this.contract$.asObservable();
  }

  getCustomerContractData(companyId: string): void {
    this.contractsCollection
      .doc(companyId)
      .valueChanges({ idField: 'id' })
      .subscribe(
        (res) => {
          this.contract$.next(res);
        },
        (err) => console.error(err)
      );
  }

  getIdCompany(code: string) {
    return this.afs.collection('clients', ref =>
      ref.where('company_code', '==', code)
    ).get().pipe(
      map(querySnapshot => {
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          return doc.id;
        } else {
          return null; // Opcional: manejar el caso en el que no se encuentra ningún documento
        }
      })
    );
  }

  getOneCustomer(id: string): Observable<any> {
    return this.companyCollection.doc(id).valueChanges({ idField: 'id' });
  }

  
}
