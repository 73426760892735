<div class="calendar-container">
    <div class="calendar">
        <mat-calendar 
            [selected]="selectedDate"
            (selectedChange)="changeSelectedDay($event)"
            [startAt]="selectedDate"
            [minDate]="contractStartEndDay[0]"
            [maxDate]="contractStartEndDay[1]"
            [headerComponent]="calendarHeader"
            >
        </mat-calendar>
    </div>
    <div class="locations">
        <div *ngFor="let sucursal of sortedLocations">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header style="height: 2rem;">
                        <mat-panel-title style="font-size: smaller; font-weight: 700;">
                            Sucursal {{sucursal.sucursal}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngFor="let location of sucursal.locations">
                        <div class="sucursal-container" (click)="changeSelectedLocation(location)" [ngClass]="{'location-selected' : location.id === selectedLocation.id}">
                            <div class="sucursal-tittle-quantity" >
                                <h4>Base {{location.name}}</h4>
                                <ng-container *ngIf="location.typeMenu === 'Campo'; else ciudad">
                                    <mat-icon class="icon" matTooltip="Almuerzo">sunny</mat-icon><span>{{location.totalesAceptados.Almuerzo}}</span>
                                    <mat-icon class="icon" matTooltip="Cena">nightlight</mat-icon><span>{{location.totalesAceptados.Cena}}</span>
                                    <mat-icon class="icon" matTooltip="Especial">medication</mat-icon><span>{{location.totalesAceptados.Especial}}</span>
                                </ng-container>
                                <ng-template #ciudad>
                                    <mat-icon class="icon" matTooltip="Liviano">eco</mat-icon><span>{{location.totalesAceptados.Liviano}}</span>
                                    <mat-icon class="icon" matTooltip="Saludable">fitness_center</mat-icon><span>{{location.totalesAceptados.Saludable}}</span>
                                    <mat-icon class="icon" matTooltip="Pleno">local_dining</mat-icon><span>{{location.totalesAceptados.Pleno}}</span>
                                    <mat-icon class="icon" matTooltip="Especial">medication</mat-icon><span>{{location.totalesAceptados.Especial}}</span>
                                </ng-template>
                            </div>
                            <div class="sucursal-totalQuantity">
                                <span>Total</span>
                                <h2>{{location.totalesAceptados.Total}}</h2>
                            </div>
                        </div>
                    </div>
                  
                </mat-expansion-panel>
            </mat-accordion>
            
        </div>
    </div>
</div>

