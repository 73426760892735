<div class="container-resume" *ngIf="weekRequestedViandas.length > 0 else anyOrder">
    <div class="container-components">
        <div *ngFor="let resume of resumeTotalComponents" class="component">
            <div class="quantity">{{resume.quantity}}</div>
            <div class="component-name">{{resume.name}}</div>
        </div>
    </div>
    <div class="table-resumen">
        <table mat-table [dataSource]="resumeOrder" multiTemplateDataRows class="mat-elevation-z0">
            <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                <th [ngClass]="column !== 'Fecha' ? 'center notFecha' : 'rowFecha'" mat-header-cell *matHeaderCellDef>
                    {{column}}s </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="column === 'Fecha'">
                        {{element.date}}
                    </div>
                    <div class="center" *ngFor="let component of element.components">
                        <div *ngIf="column === component.name">
                            {{component.quantity}}
                        </div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button aria-label="expand row"
                        (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                    </button>
                </td>
            </ng-container>
            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                    <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="table-expand">
                            <table>
                                <tr>
                                    <th class="remit">Remito</th>
                                    <th class="cant center">Cant</th>
                                    <th class="center">Ver Remito</th>
                                </tr>
                                <tr class="row" *ngFor="let component of element.components">
                                    <td> N°. </td> <!-- aca va el numero de remito -->
                                    <td class="center">{{ component.quantity }}</td>
                                    <td class="center">
                                        <button mat-icon-button color="primary" (click)="$event.stopPropagation()">
                                            <mat-icon fontSet="material-icons-outlined">assignment</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>
    </div>
</div>

<ng-template #anyOrder>
    <div class="anyOrder">
        No hay viandas pedidas para esta fecha
    </div>
</ng-template>