<div class="container">
    <div class="title-section">
        <h2 class="titleSection">Menú</h2>
    </div>

    <div class="data">
        <!-- Ubicación -->
        <div class="subheading-section">
            <h2 class="subheading-section-txt">Ubicación</h2>
            <mat-divider>
            </mat-divider>
        </div>

        <mat-form-field appearance="outline" style="width: 100%; font-size: 12px; line-height: 1.5">
            <mat-label>Ubicación</mat-label>
            <mat-select [formControl]="locationSelected" (selectionChange)="changeLocation($event.value)">
                <mat-option *ngFor="let location of locationsToChoose" [value]="location">
                    {{ location.name }} ({{ location.address }})
                </mat-option>
            </mat-select>
        </mat-form-field>


        <!-- Menus -->
        <div class="subheading-section">
            <h2 class="subheading-section-txt">Menús</h2>
            <mat-divider>
            </mat-divider>
        </div>

        <!-- JUST ESPECIAL THAT DOSNT REPEAT -->
        <div *ngIf="specialMenus.length > 0"> <!-- SI EL ARRAY DE ESPECIALES ESTA VACIO NO MUESTRO MENUS -->
            <div class="request">
                <h4 class="title-request">Especial</h4>
                <div class="request-divider"></div>
                <div class="request-menu">
                    <button mat-icon-button color="primary" [disabled]="!isAvailable" (click)="addMenu(especialSelected.value)">
                        <mat-icon>add_circle</mat-icon>
                    </button>
                    <div class="menu-container">
                        <h4 class="special-menu">{{especialSelected.value.menu_name}}</h4>
                        <h5 class="special-name">{{especialSelected.value.name}}</h5>
                    </div>
                    <div style="flex: 1"></div>
                    <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="!isAvailable">
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngFor="let menu of specialMenus" (click)="changeMenuSpecial(menu)">
                            <h4 class="special-menu">{{menu.menu_name}}</h4>
                            <h5 class="special-name">{{menu.name}}</h5>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>

        <div *ngFor="let menus of menusToChoose">
            <!-- IF para saber si es menu de ciudad o campo -->
            <ng-container *ngIf="menus.type === 'Ciudad'; else menuCampo">
                
                <div class="request" *ngFor="let menu of menus.menu_component | menuOrderedByGroup">
                    <!-- Fijos (Postre y Entrada) -->
                    <h4 class="title-request">Fijos</h4>
                    <div class="request-divider"></div>
                    <div class="request-menu">
                        <button mat-icon-button color="primary" [disabled]="true" (click)="addMenu(menu.menus)">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                        <div>
                            <div class="menu-container" *ngFor="let menuFinal of menu.menus">
                                <div *ngIf="menuFinal.component === 'Entrada' || menuFinal.component === 'Postre'">
                                    <h4 class="special-menu">{{menuFinal.menu_name}}</h4>
                                    <h5 class="special-name">{{menuFinal.component}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Principal (Liviano, Saludable, Pleno) -->
                    <h4 class="title-request">Opcion Principal</h4>
                    <div class="request-divider"></div>
                    <div  *ngFor="let menuFinal of menu.menus; let i = index">
                        <div *ngIf="menuFinal.component === 'Principal'">
                            <h4 class="title-request">Opción {{i}}</h4>
                            <div class="request-divider"></div>
                            <div class="request-menu">
                                <button mat-icon-button color="primary" [disabled]="!isAvailable" (click)="addMenu(menu.menus, menuFinal.name)">
                                    <mat-icon>add_circle</mat-icon>
                                </button>
                                <div>
                                    <div class="menu-container">
                                        <h4 class="special-menu">{{menuFinal.menu_name}}</h4>
                                        <h5 class="special-name">{{menuFinal.name}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="request-divider"></div> -->
                </div>
            </ng-container>
            <ng-template #menuCampo>
                <div class="request" *ngFor="let menu of menus.menu_component | menuOrderedByGroup">
                    <h4 class="title-request">Opción {{menu.group}}</h4>
                    <div class="request-divider"></div>
                    <div class="request-menu">
                        <button mat-icon-button color="primary" [disabled]="!isAvailable" (click)="addMenu(menu.menus)">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                        <div>
                            <div class="menu-container" *ngFor="let menuFinal of menu.menus">
                                <h4 class="special-menu">{{menuFinal.menu_name}}</h4>
                                <h5 class="special-name">{{menuFinal.name}}</h5>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="request-divider"></div> -->
                </div>
            </ng-template>
        </div>

        <!-- Bebidas -->
        <div *ngIf="drinksToChoose.length > 0"> <!-- SI EL ARRAY DE BEBIDAS ESTA VACIO NO MUESTRO BEBIDAS -->
            <div class="subheading-section">
                <h2 class="subheading-section-txt">Bebidas</h2>
                <mat-divider>
                </mat-divider>
            </div>
            <div class="request">
                <div class="request-menu">
                    <button mat-icon-button color="primary" [disabled]="!isAvailable" (click)="addDrink(drinkSelected.value)">
                        <mat-icon>add_circle</mat-icon>
                    </button>
                    <div class="menu-container">
                        <h4 class="special-menu">{{drinkSelected.value.name}}</h4>
                        <h5 class="special-name">{{drinkSelected.value.description}}</h5>
                    </div>
                    <div style="flex: 1"></div>
                    <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="!isAvailable">
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngFor="let drink of drinksToChoose" (click)="changeDrink(drink)">
                            <h4 class="special-menu">{{drink.name}}</h4>
                            <h5 class="special-name">{{drink.description}}</h5>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>

<!--         <div *ngIf="drinksToChoose.length > 0">
            <div class="subheading-section">
                <h2 class="subheading-section-txt">Bebidas</h2>
                <mat-divider>
                </mat-divider>
            </div>

            <div class="request" *ngFor="let drink of drinksToChoose">
                <div class="request-menu" style="margin-bottom: 8px;">
                    <button mat-icon-button color="primary" [disabled]="!isAvailable" (click)="addDrink(drink)">
                        <mat-icon>add_circle</mat-icon>
                    </button>
                    <div class="menu-container">
                        <h4 class="special-menu">{{drink.name}} - {{drink.description}}</h4>
                        <h5 class="special-name">{{drink.content}}</h5>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>