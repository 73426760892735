import { Injectable } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private utilsCollection: AngularFirestoreCollection<any>;
  public currentTitleToolbar = '';
  private specialMenusListCollection: AngularFirestoreCollection<any>;
  private menusCreatedCollection: AngularFirestoreCollection<any>;
  private drinkListCollection: AngularFirestoreCollection<any>;
  drinkList$ = new BehaviorSubject<any>(undefined);
  menuConfig$ = new BehaviorSubject<any>(undefined);
  menusCreated$ = new BehaviorSubject<any>(undefined);
  public menuSettings$ = new BehaviorSubject<any>(undefined);
  menuCongSub: Subscription;
  public specialMenusListList$ = new BehaviorSubject<any>(undefined);

  constructor(private afs: AngularFirestore) {
    this.utilsCollection = this.afs.collection<any>('utils');
    this.specialMenusListCollection = this.afs.collection<any>('special_menus');
    this.menusCreatedCollection = this.afs.collection<any>('menus_created');
    this.drinkListCollection = this.afs.collection<any>('drinks');
  }

  setTitleToolbar(title: string): void {
    this.currentTitleToolbar = title;
  }

  // this func get the options to fill out the select input in case of a special meal
  getSpecialOptions(): Observable<any> {
    return this.utilsCollection.doc('specialMotives').valueChanges();
  }

  public getCurrentVersion(): Observable<any> {
    return this.afs
      .collection('versions', (ref) =>
        ref.where('app_type', '==', 'client-web-app').limit(1)
      )
      .snapshotChanges()
      .pipe(
        map((version) => {
          return version.map((a) => {
            const data = a.payload.doc.data() as any;
            return data;
          });
        })
      );
  }

  getSpecialMenuData(): void {
    this.specialMenusListCollection.valueChanges().subscribe(
      (res) => {
        this.specialMenusListList$.next(res);
      },
      (err) => console.error(err)
    );
  }

  getSpecialMenu(): Observable<any> {
    return this.specialMenusListList$.asObservable();
  }

  getDrinkList(): Observable<any> {
    return this.drinkList$.asObservable();
  }

  getDrinkListData(): void {
    this.drinkListCollection.valueChanges().subscribe(
      (res) => {
        this.drinkList$.next(res);
      },
      (err) => console.error(err)
    );
  }

  getMenuConfig(): Observable<any> {
    return this.menuSettings$.asObservable();
  }

  getMenuConfigData(): void {
    if (!this.menuCongSub) {
      this.menuCongSub = this.utilsCollection
        .doc('menu_config')
        .valueChanges({ idField: 'id' })
        .subscribe((res) => {
          this.menuSettings$.next(res);
        });
    }
  }

  getMenusCreated(): Observable<any> {
    return this.menusCreated$.asObservable();
  }

    /**
   * Returns the menu for a specific date
   * @param date date in format 'YYYMMDD'
   * @returns Observable
   */
    // getMenuForSingleDate(date: string): Observable<any> {
    //   return this.menusCreatedCollection
    //     .doc(date)
    //     .valueChanges({ idField: 'id' });
    // }

  getMenusCreatedData(): void {
    this.menusCreatedCollection.valueChanges().subscribe(
      (res) => {
        this.menusCreated$.next(res);
      },
      (err) => console.error(err)
    );
  }
}
