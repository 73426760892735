import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { guardClassGuard } from '../guards/guard-class.guard';
import { ExternalUserGuard } from '../guards/external-user-guard.service';
// import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  { path: '', redirectTo: 'viandas', pathMatch: 'full' },
  {
    path: 'viandas',
    loadChildren: () => import('./viandas/viandas.module').then(m => m.ViandasModule),
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'consumos',
    loadChildren: () => import('./consumption/consumption.module').then(m => m.ConsumptionModule),
  },
  {
    path: 'tags',
    loadChildren: () => import('./tags/tags.module').then(m=>m.TagsModule)
  },
  
  // {
  //   path: 'usuarios',
  //   loadChildren: () => import('./usuarios/usuarios.module').then(m => m.UsuariosModule)
  // },
  // {
  //   path: 'ubicaciones',
  //   loadChildren: () => import('./ubicaciones/ubicaciones.module').then(m => m.UbicacionesModule)
  // },
  // {
  //   path: 'preferencias',
  //   loadChildren: () => import('./preferences/preferences.module').then(m => m.PreferencesModule)
  // },
  // {
  //   path: 'perfil',
  //   loadChildren: () => import('./perfil/perfil.module').then(m => m.PerfilModule)
  // },
  // {
  //   path: 'notificaciones',
  //   loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule)
  // },
  // {
  //   path: 'compras',
  //   loadChildren: () => import('./02-shopping/shopping.module').then(m => m.ShoppingModule),
  // canActivate: [NgxPermissionsGuard],
  // data: {
  //   permissions: {
  //     only: ['RRHH', 'Administrador', 'Supervisor', 'SysAdmin'],
  //     except: ['Empleado'],
  //     redirectTo: '/no-permissions'
  //   }
  // }
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
