import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { take, timeout } from 'rxjs/operators';
import { Drink } from 'src/app/shared/models/drink-interface';
import { CommonService } from 'src/app/shared/services/common.service';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Subscription } from 'rxjs';
import {
  DrinksRequested,
  meal_ordered,
  MenusRequested,
} from '../interfaces/meal_order.interface';
import { ViandasService } from 'src/app/shared/services/viandas.service';

@Component({
  selector: 'butaco-viandas-normal-user',
  templateUrl: './viandas-normal-user.component.html',
  styleUrls: ['./viandas-normal-user.component.scss'],
})
export class ViandasNormalUserComponent implements OnInit, OnDestroy {
  @Input() user: any;
  @Input() userPreference: any;
  @Input() drinkList: any;
  @Input() contractClient: any;

  contractIncludesDrinks: any;
  contractStartEndDay : any[] = [];
  isLoading = false;
  touched = false;

  selectedDate: any;
  selectedDateData: any;

  menus: any[] = [];
  menusBetweenDates: any[] = [];
  selectedMenus: any[] = [];

  selectedDrink: Drink;
  menuconfigSub: Subscription;
  specialMenusSub: Subscription;
  menuConfig: any[] = [];
  specialMenus: any[] = [];

  date = new Date();
  dateMealTo: Date;
  myOrders: any;

  currentWeek: any;
  emptyDaysReference: any[] = [];

  savingDayRequest = false;

  allOrdersAdmin:any [] = [];
  locationGralUser:any = {}

  view: string = 'user_individual'
  isIndividual : boolean = true;
  userIndividual : any = '';
  editedOrder : any[]  = [];

  constructor(
    private commonService: CommonService,
    private viandasService: ViandasService,
    private snackBar: MatSnackBar
  ) {
    this.commonService.setTitleToolbar('Viandas');

    dayjs.locale('es');
    dayjs.extend(weekOfYear);
    this.currentWeek = {
      weekReferenceDay: dayjs(new Date()),
      weekName: `${dayjs(new Date())
        .startOf('week')
        .format('DD/MM/YYYY')} - ${dayjs(new Date())
        .endOf('week')
        .format('DD/MM/YYYY')}`,
      weekStartDay: dayjs(new Date()).startOf('week'),
      weekEndDay: dayjs(new Date()).endOf('week'),
    };
  }

  ngOnDestroy(): void {
    if (this.menuconfigSub) {
      this.menuconfigSub.unsubscribe();
      this.specialMenusSub.unsubscribe();
    }
  }
  getSpecialsMenu(): void {
    this.specialMenusSub = this.commonService.getSpecialMenu().subscribe(
      (res) => {
        if (res) {
          // console.log(res);
          // this.specialMenuEntryOptions = this.specialMenus.map((menu:any) => menu.type);
          this.specialMenus = res.map((menu: any) => {
            return {
              name: menu.description,
              component: 'Principal',
              menu_name: menu.name,
            };
          });
        }
      },
      (error) => console.error(error)
    );

    if (!this.userPreference.special) {
      this.specialMenus = [];
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.setView();
    this.setStart_EndContractDays();
    this.getMenuConfig();
    this.getSpecialsMenu();

    this.getAllOrders(this.date);

    this.setEmptyDatesArray(
      this.currentWeek.weekStartDay,
      this.currentWeek.weekEndDay
    );


    // console.log('USER', this.user);
    // console.log('PREFERENCES', this.userPreference);
    if (this.userPreference && this.userPreference.drink) {
      this.selectedDrink = this.userPreference.drink;
    }
  }

  setView(): void {
    this.view = this.user.role
    this.isIndividual = this.user.role === 'user_individual'
      ? true
      : false
  }

  setEmptyDatesArray(startDay: any, endDay: any): any {
    let currentDay = dayjs(startDay);
    this.emptyDaysReference = [];
    dayjs.extend(isSameOrBefore);
    while (currentDay.isSameOrBefore(endDay, 'day')) {
      this.emptyDaysReference.push({
        day_name: currentDay.format('DD/MM/YYYY'),
        day: currentDay.toDate(),
      });
      currentDay = currentDay.add(1, 'day');
    }

    this.getMyOrder(dayjs(startDay).toDate(), dayjs(endDay).toDate());
  }

  getMenuConfig(): void {
    this.menuconfigSub = this.commonService.getMenuConfig().subscribe((res) => {
      if (res) {
        this.menuConfig = res.types_menus;
      }
    });
  }

  getMyOrder(startDay: Date, endDay: Date): void {
    // date with data --> 20211011
    // Se envie el principio del dia y el final porque el query a FB toma las horas en cuenta en el desde hasta
    const userId = this.userIndividual.id || this.user.id;
    this.viandasService
      .getMyOrderBetweenDates(userId, startDay, endDay)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.myOrders = res.filter(
            (ord: any) =>
              /* ord.status_request !== 'cancelled' && */ ord.type === 'Personal' && ord.isEdit === false
          );
          this.editedOrder = res.filter((ord:any) => ord.isEdit === true);
          this.getMenusBetweenDates(startDay, endDay);
        },
        (err) => console.error(err)
      );
  }

  getAllOrders(day: Date):void{
    const dayStart = dayjs(day).startOf('day').toDate()
    const dayEnd = dayjs(day).endOf('day').toDate()
    this.viandasService
      .getRequestMeals(dayStart, dayEnd, this.user.company_id)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.allOrdersAdmin = res;
        },
        (err) => console.error(err)
      );
  }

  getMenusBetweenDates(startDay: Date, endDay: Date): void {
    // Se envia como param el principio del dia y el final con horas porque el query a FB toma las horas en cuenta en el desde hasta
    // console.log(dayjs(startDay).toDate(), dayjs(endDay).toDate());
    this.viandasService
      .getMenusBetweenDates(dayjs(startDay).toDate(), dayjs(endDay).toDate())
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          const dates: any = [];
          this.menus = [];
          this.menusBetweenDates = res;

          this.emptyDaysReference.forEach((date: any, idx: number) => {
            const alredyHasOrder = this.myOrders.find((ord: any) =>
              dayjs(ord.date_Meal.toDate()).isSame(dayjs(date.day), 'day')
            );
            const status_request = alredyHasOrder
              ? alredyHasOrder.status_request
              : null;

            const locationSel = alredyHasOrder
              ? alredyHasOrder.location
              : this.user.locations[0];

            const contract = this.contractClient.find((cont: any) => {
              const locFinded = cont.locations.find(
                (loc: any) => loc.id === locationSel.id
              );

              if (locFinded) {
                return cont;
              }
            });
            //Agregar regla negocio con el día
            let statusDate; 
            if (dayjs(date.day).isBefore(dayjs(), 'day')) {
              statusDate = 'closed';
            } else if (dayjs(date.day).isSame(dayjs(), 'day')) {
              if (
                dayjs().isBefore(
                  dayjs().startOf('day').add(11, 'hours'),
                  'hour'
                )
              ) {
                statusDate = 'open';
              } else {
                statusDate = 'closedTime';
              }
            } else {
              statusDate = 'open';
            }

            const menuDate: any = {
              date: date.day,
              status: statusDate,
              status_request: status_request,
              locations: this.user.locations,
              menu_types: [],
              location_selected: locationSel,
              contract_location: contract,
              menus_requested: alredyHasOrder
                ? this.getFormatedOrders(alredyHasOrder.menus_requested)
                : [],
              drinks_requested: alredyHasOrder
                ? alredyHasOrder.drinks_requested
                : [],
              special_selected: alredyHasOrder
                ? alredyHasOrder.menus_requested.some(
                    (menu: any) => menu.especial === true
                  )
                : false,
              available_to_order: this.isAvalableToOrder(date.day, contract),
              alredy_has_order: alredyHasOrder ? alredyHasOrder.id : null,
              drinks: contract.components.includes('Bebida')
                ? this.drinkList
                : [],
              available_to_edit : alredyHasOrder 
                ? false
                : true,
              requested_to : alredyHasOrder
                ? alredyHasOrder.requested_to
                : null,
            };
            if (alredyHasOrder) {
              menuDate.requestAreadySaved = true;
              menuDate.id = alredyHasOrder.id;
            }
            // console.log('MENU DATE', menuDate);

            const menusDate = res.find((menu: any) =>
              dayjs(menu.date.toDate()).isSame(dayjs(date.day), 'day')
            );
            if (menusDate) {
              menusDate.menu.forEach((menu: any, idxMenu: number) => {
                if (contract.typeMenu === menu.type) {
                  const existTipeMenu = menuDate.menu_types.findIndex(
                    (men: any) => men.type === menu.type
                  );

                  const menuTypeConfig = this.menuConfig.find(
                    (menC: any) => menC.name === menu.type
                  );

                  const menuConfig = menuTypeConfig.menus.find(
                    (menC: any) =>
                      menC.component === menu.component &&
                      menC.name === menu.name
                  );

                  // console.log(dayjs(date.date.toDate()).format('DD/MM/YY'), isSelected);
                  menu.group = menuConfig ? menuConfig.group : null;
                  menu.order = menuConfig ? menuConfig.order : null;

                  if (existTipeMenu === -1) {
                    const newMenuType = {
                      // Ciudad, Campo, Escuela
                      type: menu.type,
                      // Entrada, Principal, Postre
                      menu_component: [
                        {
                          name: menu.component,
                          menus: [menu],
                        },
                      ],
                    };

                    menuDate.menu_types.push(newMenuType);
                  } else {
                    // console.log(menuDate.menu_types[existTipeMenu]);
                    const existCompMenu = menuDate.menu_types[
                      existTipeMenu
                    ].menu_component.findIndex(
                      (men_comp: any) =>
                        men_comp.name.replace(/\s/g, '') ===
                        menu.component.replace(/\s/g, '')
                    );

                    if (existCompMenu === -1) {
                      menuDate.menu_types[existTipeMenu].menu_component.push({
                        name: menu.component,
                        menus: [menu],
                      });
                    } else {
                      menuDate.menu_types[existTipeMenu].menu_component[
                        existCompMenu
                      ].menus.push(menu);
                    }
                  }
                }

                if (idxMenu === menusDate.menu.length - 1) {
                  dates.push(menuDate);
                }
                if (
                  idx === this.emptyDaysReference.length - 1 &&
                  idxMenu === menusDate.menu.length - 1
                ) {
                  this.menus = dates;
                  if (!this.selectedDate) {
                    this.changeSelectedDay(new Date());
                  }
                  this.isLoading = false;
                }
              });
            } else {
              dates.push(menuDate);
              if (idx === this.emptyDaysReference.length - 1) {
                this.menus = dates;
                if (!this.selectedDate) {
                  this.changeSelectedDay(new Date());
                }
                this.isLoading = false;
              }
            }
          });
        },
        (err) => console.error(err)
      ); 
  }

  getFormatedOrders(menusRequested: any): any[] {
    const formatedOrders: any = [];
    if (menusRequested[0].typeMenu.type === 'Ciudad') {
      for (let index = 0; index < menusRequested.length; index = index + 3) {
        const pos1 = index + 1;
        const pos2 = index + 2;
        const typeMenu = [];
        typeMenu.push(menusRequested[index].typeMenu);
        typeMenu.push(menusRequested[pos1].typeMenu);
        typeMenu.push(menusRequested[pos2].typeMenu);
        formatedOrders.push({
          quantity: menusRequested[index].quantity,
          typeMenu,
        });
      }
      //console.log(formatedOrders)
    } else {
      menusRequested.forEach((menu: any) => {
        const menuTypeConfig = this.menuConfig.find(
          (menC: any) => menC.name === menu.typeMenu.type
        );
        let menuConfig;
        if (menuTypeConfig) {
          menuConfig = menuTypeConfig.menus.find(
            (menC: any) =>
              menC.component === menu.typeMenu.component &&
              menC.name === menu.typeMenu.name
          );
        } else {
          menuConfig = {
            celiacs: null,
            component: menu.component,
            especial: true,
            menu_name: menu.menu_name,
            name: menu.name,
            type: 'Especial',
            vegetarian: null,
          };
        }

        menu.typeMenu.order = menuConfig.order ? menuConfig.order : 1;
        menu.typeMenu.group = menuConfig.group ? menuConfig.group : 0;

        const idx = formatedOrders.findIndex((men: any) => {
          return men.typeMenu.find((menuTp: any) => {
            return menuTp.group === menu.typeMenu.group;
          });
        });
        if (idx === -1) {
          const menuRequested = {
            quantity: menu.quantity,
            typeMenu: [menu.typeMenu],
          };
          formatedOrders.push(menuRequested);
        } else {
          formatedOrders[idx].typeMenu.push(menu.typeMenu);
        }
      });
    }

    return formatedOrders;
  }

  setStart_EndContractDays(){
    let startDay = dayjs('2300-00-00', 'YYYY-MM-DD')
    let endDay = dayjs('1970-00-00', 'YYYY-MM-DD')
    for (const contract of this.contractClient) {
      const contractStart = dayjs(contract.dateFrom.toDate())
      const contractEnd = dayjs(contract.dateUntil.toDate())

      if(contractStart.isBefore(startDay)){
        startDay = contractStart
      } 
      if (contractEnd.isAfter(endDay)){
        endDay = contractEnd
      }
    }
    this.contractStartEndDay = [startDay.toDate(), endDay.toDate()]
  }

  /**
   * Change the current date selected
   * @param event is the new selected date as Date
   */
  changeSelectedDay(event: any): void {
    this.selectedDateData = undefined;
    this.selectedDate = event;

    //Cambio de dia para el usuario GRAL
    if(!this.isIndividual){
      this.getAllOrders(event)
      let difBetwenWeeks = dayjs(event).week() - this.currentWeek.weekEndDay.week()
      while (difBetwenWeeks !== 0){
        if (difBetwenWeeks > 0){
          this.changeSelectedWeek('next', 'fooChSD')
          difBetwenWeeks--
        }
        else {
          this.changeSelectedWeek('prev', 'fooChSD')
          difBetwenWeeks++
        }
      }
    }

    setTimeout(() => {
      //console.log(this.currentWeek)
      this.setEmptyDatesArray(
        this.currentWeek.weekStartDay,
        this.currentWeek.weekEndDay
      );
      this.selectedDateData = this.menus.find((menu) =>
        dayjs(menu.date).isSame(dayjs(event), 'day')
      );
      //this.isLoading = true;

      //console.log(this.selectedDateData);
    }, 500);
  }

  /**
   * Change the current week selected
   * @param action if is next or prev week
   * @param from if the foo is called from this.changeSelectedDay or by user
   */
  changeSelectedWeek(action: string, from?: string) {
    if (action === 'next') {
      this.currentWeek = {
        weekReferenceDay: dayjs(this.currentWeek.weekReferenceDay).add(
          1,
          'week'
        ),
        weekName: `${dayjs(this.currentWeek.weekReferenceDay)
          .add(1, 'week')
          .startOf('week')
          .format('DD/MM/YYYY')} - ${dayjs(this.currentWeek.weekReferenceDay)
          .add(1, 'week')
          .endOf('week')
          .format('DD/MM/YYYY')}`,
        weekStartDay: dayjs(this.currentWeek.weekReferenceDay)
          .add(1, 'week')
          .startOf('week'),
        weekEndDay: dayjs(this.currentWeek.weekReferenceDay)
          .add(1, 'week')
          .endOf('week'),
      };
    } else {
      this.currentWeek = {
        weekReferenceDay: dayjs(this.currentWeek.weekReferenceDay).subtract(
          1,
          'week'
        ),
        weekName: `${dayjs(this.currentWeek.weekReferenceDay)
          .subtract(1, 'week')
          .startOf('week')
          .format('DD/MM/YYYY')} - ${dayjs(this.currentWeek.weekReferenceDay)
          .subtract(1, 'week')
          .endOf('week')
          .format('DD/MM/YYYY')}`,
        weekStartDay: dayjs(this.currentWeek.weekReferenceDay)
          .subtract(1, 'week')
          .startOf('week'),
        weekEndDay: dayjs(this.currentWeek.weekReferenceDay)
          .subtract(1, 'week')
          .endOf('week'),
      };
    }

    //TODO mejorar esto de la FOO
    if (!from) {
      this.isLoading = true;
      this.setEmptyDatesArray(
        this.currentWeek.weekStartDay,
        this.currentWeek.weekEndDay
      );
      this.changeSelectedDay(this.currentWeek.weekStartDay);
    } else {
      this.setEmptyDatesArray(
        this.currentWeek.weekStartDay,
        this.currentWeek.weekEndDay
      );
    }

  }

  isAvalableToOrder(dateAndTime: Date, contract: any): boolean {
    const avilableDays = contract.serviceDays;
    const date = dayjs(dateAndTime);
    const now = dayjs();

    let isAvalable = false;
    const isDayAvailable = avilableDays.find((day: string) => {
      const formatedDay = day
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      let formatDayJs = date.format('dddd')
        .replace('á','a')
        .replace('é', 'e')
      //Regla de negocio (Si es sabado despues de las 10, no tiene habilitado para pedir el domingo siguiente)
      if (Number(now.format('d')) === 6){
        const dspsDe10 = date
          .startOf('day')
          .add(10, 'hours')
          .isAfter(now);
        if (dspsDe10 && Number(date.format('d')) === 0){
          return false;
        }
      }
      return formatedDay === formatDayJs
    });

    if (isDayAvailable) {
      if (date.isSame(dayjs(), 'day')) {
        isAvalable = date
          .startOf('day')
          .add(13, 'hours')
          .add(30, 'minutes')
          .isAfter(now);
      } else {
        isAvalable = date.isAfter(now);
      }
    }
    return isAvalable;
  }

  /**
   * Change the location selected for the day, and check the contract and apply the correspond menus
   * @param idxMenu index Day
   * @param idxLocSel Index locations in user
   */
  changeLocationSelected(event: any) {
    const contract = this.contractClient.find((cont: any) => {
      const locFinded = cont.locations.find(
        (loc: any) => loc.id === event.location.id
      );

      if (locFinded) {
        return cont;
      }
    });

    const date = this.menusBetweenDates.find((date: any) =>
      dayjs(date.date.toDate()).isSame(dayjs(event.date), 'day')
    );

    const alredyHasOrder = this.myOrders.find((ord: any) =>
      dayjs(ord.date_Meal.toDate()).isSame(dayjs(date.date.toDate()), 'day')
    );

    let statusDate;
    if (dayjs(date.date.toDate()).isBefore(dayjs(new Date()), 'day')) {
      statusDate = 'closed';
    } else if (dayjs(date.date.toDate()).isSame(dayjs(new Date()), 'day')) {
      if (
        dayjs(new Date()).isBefore(
          dayjs(new Date()).startOf('day').add(11, 'hours'),
          'hour'
        )
      ) {
        statusDate = 'open';
      } else {
        statusDate = 'closedTime';
      }
    } else {
      statusDate = 'open';
    }

    const menuDate: any = {
      date: date.date.toDate(),
      status: statusDate,
      locations: this.user.locations,
      menu_types: [],
      location_selected: event.location,
      contract_location: contract,
      menus_requested: alredyHasOrder ? alredyHasOrder.menus_requested : [],
      drinks_requested: alredyHasOrder ? alredyHasOrder.drinks_requested : [],
      available_to_order: this.isAvalableToOrder(date.date.toDate(), contract),
      alredy_has_order: alredyHasOrder ? alredyHasOrder.id : null,
      drinks: contract.components.includes('Bebida') ? this.drinkList : [],
      available_to_edit : alredyHasOrder ? false : true,
    };
    date.menu.forEach((menu: any, idxMen: number) => {
      if (contract.typeMenu === menu.type) {
        const existTipeMenu = menuDate.menu_types.findIndex(
          (men: any) => men.type === menu.type
        );

        const menuTypeConfig = this.menuConfig.find(
          (menC: any) => menC.name === menu.type
        );

        const menuConfig = menuTypeConfig.menus.find(
          (menC: any) =>
            menC.component === menu.component && menC.name === menu.name
        );

        // Adds properties for easy control in the template
        menu.selected = false;
        menu.group = menuConfig.group;
        menu.order = menuConfig.order;

        if (existTipeMenu === -1) {
          const newMenuType = {
            // Ciudad, Campo, Escuela
            type: menu.type,
            // Entrada, Principal, Postre
            menu_component: [
              {
                name: menu.component,
                menus: [menu],
              },
            ],
          };

          menuDate.menu_types.push(newMenuType);
        } else {
          // console.log(menuDate.menu_types[existTipeMenu]);
          const existCompMenu = menuDate.menu_types[
            existTipeMenu
          ].menu_component.findIndex(
            (men_comp: any) =>
              men_comp.name.replace(/\s/g, '') ===
              menu.component.replace(/\s/g, '')
          );

          if (existCompMenu === -1) {
            menuDate.menu_types[existTipeMenu].menu_component.push({
              name: menu.component,
              menus: [menu],
            });
          } else {
            menuDate.menu_types[existTipeMenu].menu_component[
              existCompMenu
            ].menus.push(menu);
          }
        }
      }

      if (idxMen === date.menu.length - 1) {
        const newMenuIdx = this.menus.findIndex((menu: any) =>
          dayjs(menu.date).isSame(dayjs(event.date), 'day')
        );
        this.menus[newMenuIdx] = menuDate;
        this.selectedDateData = menuDate;
      }
    });
  }

  /**
   * Change the location selected for the day (General user)
   * @param locationInfo location selected
   */
  changeSelectedLocationGral(locationInfo: any) {
    this.locationGralUser = locationInfo
    //console.log(this.locationGralUser)
  }

  /**
   * Change the view general or individual user (General user)
   * @param role change the rol for view another view
   */
  changeView(role: string):void{
    this.view = role
  }

  onAddedMenu(menuNew: any): void {
    let menuAddedIdx = -1;
    menuNew = Array.isArray(menuNew) ? menuNew : [menuNew];
    if (this.selectedDateData.menus_requested) {
      menuAddedIdx = this.selectedDateData.menus_requested.findIndex(
        (menu: any) => {
          // Se hace json.stringify para que no se compare el objeto, si no devolvia false en cada comparacion
          // return JSON.stringify(menu.typeMenu) === JSON.stringify(menuNew);

          //Se fija si cada objeto dentro del array typeMenu es igual al de menuNew
          let res = false;
          const responseArr: boolean[] = [];
          if (menu.typeMenu.length === menuNew.length) {
            for (let index = 0; index < menu.typeMenu.length; index++) {
              responseArr.push(
                this.ObjCompare(menu.typeMenu[index], menuNew[index])
              );
            }
          }
          res = !responseArr.includes(false);
          return res;
        }
      );
    }
    if (
      this.selectedDateData.menus_requested &&
      this.selectedDateData.menus_requested.length !== 0
    ) {
      if (menuAddedIdx === -1) {
        const menuRequested = {
          quantity: 1,
          typeMenu: menuNew,
        };
        this.selectedDateData.menus_requested.push(menuRequested);
      } else {
        this.selectedDateData.menus_requested[menuAddedIdx].quantity =
          this.selectedDateData.menus_requested[menuAddedIdx].quantity + 1;
      }
    } else {
      if (menuAddedIdx === -1) {
        const menuRequested = {
          quantity: 1,
          typeMenu: menuNew,
        };
        this.selectedDateData.menus_requested = [menuRequested];
      } else {
        this.selectedDateData.menus_requested[menuAddedIdx].quantity =
          this.selectedDateData.menus_requested[menuAddedIdx].quantity + 1;
      }
    }
  }

  onAddedDrink(drinkNew: any): void {
    let drinkAddedIdx = -1;
    if (this.selectedDateData.drinks_requested) {
      drinkAddedIdx = this.selectedDateData.drinks_requested.findIndex(
        (drink: DrinksRequested) => {
          return drink.drink.id === drinkNew.id;
        }
      );
    }

    if (
      this.selectedDateData.drinks_requested &&
      this.selectedDateData.drinks_requested.length !== 0
    ) {
      if (drinkAddedIdx === -1) {
        const drinkRequested = {
          quantity: 1,
          drink: drinkNew,
        };
        this.selectedDateData.drinks_requested.push(drinkRequested);
      } else {
        this.selectedDateData.drinks_requested[drinkAddedIdx].quantity =
          this.selectedDateData.drinks_requested[drinkAddedIdx].quantity + 1;
      }
    } else {
      if (drinkAddedIdx === -1) {
        const menuRequested = {
          quantity: 1,
          drink: drinkNew,
        };
        this.selectedDateData.drinks_requested = [menuRequested];
      } else {
        this.selectedDateData.drinks_requested[drinkAddedIdx].quantity =
          this.selectedDateData.drinks_requested[drinkAddedIdx].quantity + 1;
      }
    }
  }

  onMakeRequest(): void {
    let newMealRequest: any = {
      type: 'Personal',
      isEdit : false,
      drinks_requested: this.selectedDateData.drinks_requested
        ? this.selectedDateData.drinks_requested
        : [],
      //status_request: 'accepted',
      status_request: this.dateDiff() > 12 
        ? 'accepted'
        : 'acceptancePending',
      status_making: 'pending',
      location: this.selectedDateData.location_selected,
      date_request: new Date(),
      company: {
        companyId: this.selectedDateData.location_selected.customer.id,
        companyName: this.selectedDateData.location_selected.customer.name,
        contractId: this.selectedDateData.contract_location.id
      },
      menus_requested: this.getSimplifiedMenus(
        this.selectedDateData.menus_requested
      ),
      date_Meal: this.selectedDateData.date,
      requester: this.user,
      requested_to: this.user
    };

    //Si el usuario tiene una orden de edicion, edito sobre esa edicion
    const ordenEdicion = this.editedOrder.find((ord: any) =>
      dayjs(ord.date_Meal.toDate()).isSame(dayjs(this.selectedDate), 'day')
    );
    if (ordenEdicion){
      newMealRequest = ordenEdicion
      newMealRequest.date_request = new Date()
      newMealRequest.drinks_requested = this.selectedDateData.drinks_requested
      ? this.selectedDateData.drinks_requested
      : []
      newMealRequest.menus_requested = this.getSimplifiedMenus(this.selectedDateData.menus_requested)
    }
    //console.log('ORDEN EDICION', ordenEdicion)


    //Me fijo si el contrato tiene habilitado bebidas
    //Si las tiene me fijo que la cantidad de bebidas sea igual a la de menus
    let drinksQuantity = 0
    let menusQuantity = 0
    if(this.selectedDateData.drinks.length > 0){
      for (const drinksQuant of this.selectedDateData.drinks_requested) {
        drinksQuantity += drinksQuant.quantity
      }
      for (const menusQuant of this.selectedDateData.menus_requested) {
        menusQuantity += menusQuant.quantity
      }
    }
    if(drinksQuantity !== menusQuantity){
      this.snackBar.open(
        '❌  La cantidad de viandas y bebidas debe ser la misma, por favor intente nuevamente.',
        '❌',
        {
          duration: 4000,
        }
      );
      return
    }

    //Me fijo el rol del usuario, si es usuario normal no puede agregar mas de una vianda.
    let quantityPermited
    if (this.isIndividual){
      const menuType = (this.selectedDateData.menu_types[0].type)
      quantityPermited = menuType === 'Ciudad' ? 3 : 6;
    }
    if (quantityPermited){
      let totalRequested = 0
      for (const menu of newMealRequest.menus_requested) {
        totalRequested += menu.quantity
      }
      if (totalRequested > quantityPermited){
        this.snackBar.open(
          '❌  Ud pidio mas viandas de las que tiene permitidas, por favor disminuya la cantidad.',
          '',
          {
            duration: 3000,
          }
        );
        return
      }
    }

    //Me fijo si el usuario es general o administrador, una sola vianda para el, mas para invitado
    if (!this.isIndividual){
      const menuType = (this.selectedDateData.menu_types[0].type)
      quantityPermited = menuType === 'Ciudad' ? 3 : 6;
      let totalRequested = 0
      for (const menu of newMealRequest.menus_requested) {
        totalRequested += menu.quantity
      }
      if (totalRequested > quantityPermited){
        newMealRequest.requester = this.user
        this.userIndividual
          ? newMealRequest.requested_to = this.userIndividual
          : newMealRequest.requested_to = this.user
      }
    }

    //Edit
    if(this.selectedDateData.requestAreadySaved && newMealRequest.status_request === 'acceptancePending'){
      newMealRequest.isEdit = true;
      newMealRequest['original_id'] = this.selectedDateData.id;
    } 

    //Si el usuario ya tiene una orden del tipo edicion, modifico esa orden.
    //Sino, creo una nueva orden, que puede ser de edicion o una nueva orden en si
    if(ordenEdicion){
      this.viandasService
        .orderMealSaveChange(newMealRequest.id, newMealRequest)
        .then ((res:any) =>{
          this.savingDayRequest = false;
          this.snackBar.open(
            '✅ El cambio en el pedido fue guardado correctamente',
            '',
            {
              duration: 3000,
              panelClass: ['success-snackbar'],
            }
          );
          this.isLoading = true;
          this.setEmptyDatesArray(
            this.currentWeek.weekStartDay,
            this.currentWeek.weekEndDay
          );
          this.selectedDateData.available_to_edit = false; //Despues de los cambios deshabilito la edicion
          this.editedOrder = []; //Despues de los cambios vacio el pedido para editar
          this.isIndividual ? this.view = 'user_individual' : this.view = 'user_gral';//Dsps cambios seteo de nuevo la vista
        })
        .catch((err: any) => {
          this.snackBar.open(
            '❌  Ocurrio un error y no se pudo realizar el pedido, por favor intente más tarde nuevamente.',
            '',
            {
              duration: 3000,
            }
          );
          this.savingDayRequest = false;
        });
    } else {
      if (!this.selectedDateData.requestAreadySaved || newMealRequest.status_request === 'acceptancePending') {
        this.viandasService
          .orderMeal(newMealRequest)
          .then((res: any) => {
            this.savingDayRequest = false;
            this.selectedDateData.requestAreadySaved = true;
            this.selectedDateData.id = res.id;
  
            let msg = ''
            newMealRequest.status_request === 'acceptancePending'
              ? msg = '❗ El pedido se ha guardado correctamente, pero aún no ha sido aceptado.'
              : msg = '✅ El pedido de vianda fue guardado'
  
            this.snackBar.open(msg, '', {
              duration: 3000,
              panelClass: ['success-snackbar'],
            });
  
            this.isLoading = true;
            this.setEmptyDatesArray(
              this.currentWeek.weekStartDay,
              this.currentWeek.weekEndDay
            );

            this.isIndividual ? this.view = 'user_individual' : this.view = 'user_gral'; //Dsps cambios seteo de nuevo la vista

          })
          .catch((err: any) => {
            this.snackBar.open(
              '❌  Ocurrio un error y no se pudo realizar el pedido, por favor intente más tarde nuevamente.',
              '',
              {
                duration: 3000,
              }
            );
            this.savingDayRequest = false;
          });
      } else {
        this.viandasService
          .orderMealSaveChange(this.selectedDateData.id, newMealRequest)
          .then((res: any) => {
            // console.log(res);
            // this._bottomSheetRef.dismiss(true);
            this.savingDayRequest = false;
            this.snackBar.open(
              '✅ El cambio en el pedido fue guardado correctamente',
              '',
              {
                duration: 3000,
                panelClass: ['success-snackbar'],
              }
            );
            this.isLoading = true;
            this.setEmptyDatesArray(
              this.currentWeek.weekStartDay,
              this.currentWeek.weekEndDay
            );
            this.selectedDateData.available_to_edit = false; //Despues de los cambios deshabilito la edicion
            this.isIndividual ? this.view = 'user_individual' : this.view = 'user_gral'; //Dsps cambios seteo de nuevo la vista
          })
          .catch((err: any) => {
            this.snackBar.open(
              '❌  Ocurrio un error y no se pudo realizar el pedido, por favor intente más tarde nuevamente.',
              '',
              {
                duration: 3000,
              }
            );
            this.savingDayRequest = false;
          });
      }
    }

  }

  onCancellReactiveReq(state: string, orderId?:string): void {
    //Calculo la diferencia de horas, para marcar el pedido pendiente de aceptar o de cancelar
    //Si hay mas de 12 hs de dif, se cancela o se acepta directamente, sino queda pendiente de aceptar o cancelar
    const diff = this.dateDiff()

    if (state === 'cancelled' && diff < 12) {
      state = 'cancellationPending';
    }
    if (state === 'accepted' && diff < 12) {
      state = 'acceptancePending';
    }

    //Si es Sabado despues de las 10, y es un pedido para el mismo sabado, domingo proximo o lunes proximo, interviene butaco
    const todayDayJs = dayjs(new Date).day();
    if (todayDayJs === 6 && diff <= 50){
      state = state === 'cancelled' 
        ? 'cancellationPending' 
        : 'acceptancePending';
    }
    
    const id = orderId ? orderId : this.selectedDateData.id;

    this.viandasService
      .changeStatus(id, state, new Date())
      .then((res: any) => {
        this.savingDayRequest = false;
        this.snackBar.open('✅ El pedido cambio de estado', '', {
          duration: 3000,
          panelClass: ['success-snackbar'],
        });

        this.isLoading = true;
        this.setEmptyDatesArray(
          this.currentWeek.weekStartDay,
          this.currentWeek.weekEndDay
        );
        this.changeSelectedDay(this.selectedDate);
      })
      .catch((err: any) => {
        this.snackBar.open(
          '❌  Ocurrio un error y no se pudo modificar el estado del pedido, por favor intente más tarde nuevamente.',
          '',
          {
            duration: 3000,
          }
        );
        this.savingDayRequest = false;
      });
  }

  onEditMenuGralUser(user_invited: any): void {
    this.selectedDateData = undefined;
    this.view = 'user_individual'
    this.isLoading = true;

    if(this.user.id !== user_invited.id) {
      this.userIndividual = user_invited
    }

    let startDay = dayjs(this.selectedDate).startOf('day')
    let endDay = dayjs(this.selectedDate).endOf('day')
    this.setEmptyDatesArray(startDay,endDay)
    
    setTimeout(() => {
      this.selectedDateData = this.menus.find((menu) =>
        dayjs(menu.date).isSame(dayjs(this.selectedDate), 'day')
      );
    }, 500);
  }

  onCancelMenuGralUser(orderDetails: any): void {
    this.onCancellReactiveReq(orderDetails.state, orderDetails.orderId)
  }

  getSimplifiedMenus(menus: any): any[] {
    const simplifiedMenus: any[] = [];
    menus.forEach((menu: any) => {
      menu.typeMenu.forEach((typeMenuR: any) => {
        simplifiedMenus.push({
          quantity: menu.quantity,
          typeMenu: this.getMenuObj(typeMenuR),
        });
      });
    });
    return simplifiedMenus;
  }

  getMenuObj(typeMenuR: any): object {
    if (!typeMenuR.type) {
      // Especial
      return {
        especial: true,
        celiacs: null,
        vegetarian: null,
        component: typeMenuR.component,
        menu_name: typeMenuR.menu_name,
        name: typeMenuR.name,
        type: 'Especial',
      };
    } else {
      return {
        especial: typeMenuR.especial,
        celiacs: typeMenuR.celiacs,
        vegetarian: typeMenuR.vegetarian,
        component: typeMenuR.component,
        menu_name: typeMenuR.menu_name,
        name: typeMenuR.name,
        type: typeMenuR.type,
      };
    }
  }

  ObjCompare(obj1: any, obj2: any) {
    const Obj1_keys = Object.keys(obj1);
    const Obj2_keys = Object.keys(obj2);
    /*     if (Obj1_keys.length !== Obj2_keys.length){
        return false;
    } */
    for (let k of Obj1_keys) {
      if (obj1[k] !== obj2[k]) {
        return false;
      }
    }
    return true;
  }

  dateDiff(){
    const date1 = dayjs(this.selectedDateData.date);
    const date2 = dayjs(new Date());
    return date1.diff(date2, 'hours');
  }

}
