import { InputModalityDetector } from '@angular/cdk/a11y';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common.service';
import { CompanyDataService } from 'src/app/shared/services/company-data.service';
import { UserDataService } from 'src/app/shared/services/user-data.service';
import { TypeMenu } from '../../interfaces/meal_order.interface';

@Component({
  selector: 'butaco-viandas-normal-user-menus',
  templateUrl: './viandas-normal-user-menus.component.html',
  styleUrls: ['./viandas-normal-user-menus.component.scss'],
})
export class ViandasNormalUserMenusComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() drinksToChoose: any;
  @Input() locationsToChoose: any[] = [];
  @Input() specialMenus: any[] = [];
  @Input() menusToChoose: any;
  @Input() isAvailable: any;
  @Input() alreadyHasOrder: any;
  @Input() date: any;
  @Output() onChangeLocation = new EventEmitter<any>();
  @Output() onAddedDrink = new EventEmitter<any>();
  @Output() onAddedMenu = new EventEmitter<any>();

  locationSelected = new UntypedFormControl(undefined, [Validators.required]);
  especialSelected = new UntypedFormControl(undefined, [Validators.required]);
  drinkSelected = new UntypedFormControl(undefined, [Validators.required])

  ngOnChanges(changes: SimpleChanges) {
    //console.log(this.menusToChoose)
    if (
      changes.alreadyHasOrder &&
      changes.alreadyHasOrder.firstChange === false &&
      changes.alreadyHasOrder &&
      changes.alreadyHasOrder.currentValue === true
    ) {
      this.locationSelected.disable();
    }

    if (changes.drinksToChoose){
      this.drinkSelected.setValue(changes.drinksToChoose.currentValue[0])
    }
  }

  constructor() {}

  ngOnInit(): void {
    if (this.locationsToChoose.length > 0) {
      this.locationSelected.setValue(this.locationsToChoose[0]);
      if (this.locationsToChoose.length === 1 || this.alreadyHasOrder) {
        this.locationSelected.disable();
      }
    }
    if (this.specialMenus.length > 0) {
      this.especialSelected.setValue(this.specialMenus[0]);
      if (this.specialMenus.length === 1) {
        this.especialSelected.disable();
      }
    }

    if (this.drinksToChoose.length > 0) {
      this.drinkSelected.setValue(this.drinksToChoose[0])
      if(this.drinksToChoose.length === 1 ){
        this.drinkSelected.disable();
      }
    }

    if (!this.isAvailable) {
      this.locationSelected.disable();
    }

    if (this.alreadyHasOrder) {
      this.locationSelected.disable();
    }
  }

  ngOnDestroy(): void {}

  addMenu(menus: any[] , ciudad?:string): void {
    if(ciudad){
      const aux = menus.filter(menu => menu.component === 'Postre' || menu.component === 'Entrada' || menu.name === ciudad)
      menus = aux
    }
    this.onAddedMenu.emit(menus);
    if (this.drinkSelected.value) {
      this.addDrink(this.drinkSelected.value)
    }

  }

  addDrink(drink: any): void {
    this.onAddedDrink.emit(drink);
  }

  changeLocation(value: any): void {
    this.onChangeLocation.emit({ location: value, date: this.date });
  }

  changeMenuSpecial(value: any): void {
    this.especialSelected.setValue(value);
  }

  changeDrink(value:any) : void {
    this.drinkSelected.setValue(value);
  }
}
