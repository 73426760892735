import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/services/common.service';
import { CompanyDataService } from 'src/app/shared/services/company-data.service';
import { UserDataService } from 'src/app/shared/services/user-data.service';
import { ViandasService } from '../../../shared/services/viandas.service';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PedidoDeViandaComponent } from '../pedido-de-vianda/pedido-de-vianda.component';
import { MenusComponent } from '../menus/menus.component';
import { NgIf, NgForOf } from '@angular/common';
import { SpecialMenu } from 'src/app/shared/interfaces/special-menu-interface';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { DateHelpers } from 'src/app/shared/helpers/dateHelpers';
import { ContractHelpers } from 'src/app/shared/helpers/contractHelpers';
import { DetallePorUsuarioComponent } from '../detalle-por-usuario/detalle-por-usuario.component';
import { ResumeViandasComponent } from '../resume-viandas/resume-viandas.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { LocationHelpers } from 'src/app/shared/helpers/locationHelpers';
@Component({
  standalone: true,
  selector: 'butaco-viandas-main',
  templateUrl: './viandas-main.component.html',
  styleUrls: ['./viandas-main.component.scss'],
  imports: [MatDatepickerModule, MatTabsModule, MatNativeDateModule, FormsModule, MatProgressSpinnerModule, MatButtonToggleModule,
    MatProgressBarModule, MatFormFieldModule, PedidoDeViandaComponent, ResumeViandasComponent, DetallePorUsuarioComponent, MenusComponent, NgIf, NgClass, NgForOf, MatInputModule, MatSelectModule],
  // schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class ViandasMainComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  loadingUserData = true;
  public user: any;
  company: any;
  drinkList: any;
  userPreference: any;
  contractClient: any;
  menuConfig: any;
  specialMenus: any;
  menusCreated: any;
  requestedViandas: any;
  contractIncludesDrinks: any;
  userWithoutLocation = true;
  locationsAvailable: any;
  thereMenusCreated: boolean = false;
  contractUser: any;
  currentDate: Date;
  selectedDate: any = new Date;
  selectedWeek: Array<any> = [];
  weekMenus: Array<any> = [];
  weekRequestedViandas: Array<any> = [];
  companyRequestedViandas: Array<any> = [];
  weekStart: any;
  weekEnd: any;
  selectedLocation: any;
  userSpecialMenus: Array<SpecialMenu> = [];
  userRol: string;
  selectedTog = 'Pedidos de vianda';
  weekDaysName: Array<any> = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ]
  contractStartDate: Date;
  contractEndDate: Date;
  constructor(
    private commonService: CommonService,
    private userDataService: UserDataService,
    private companyDataServ: CompanyDataService,
    private viandasServ: ViandasService,
    private route: ActivatedRoute,
    private dateHelpers: DateHelpers,
    private contractHelpers: ContractHelpers,
    private router: Router,
    private locationHelpers: LocationHelpers
  ) {
    this.commonService.setTitleToolbar('Mis Pedidos');
  }

  ngOnInit(): void {
    //configuro la librería dayjs para que el primer día de la semana sea lunes
    dayjs.locale('es');
    this.getData();
    const tiempoTranscurrido = Date.now();
    this.currentDate = new Date(tiempoTranscurrido);
    this.selectedTog = 'Pedidos de vianda'
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  selectButton(togItem: string) {
    this.selectedTog = togItem;
  }


  //obtiene todos los datos necesarios para el componente
  getData(): void {
    const userData = this.userDataService.getUser();
    const drinkList = this.commonService.getDrinkList();
    const companyData = this.companyDataServ.getCompany();
    const userPreferencesData = this.userDataService.getUserPreferences();
    const customerContractsData = this.companyDataServ.getCustomerContract();
    const specialMenusData = this.commonService.getSpecialMenu();
    const menuConfigData = this.commonService.getMenuConfig();
    const menusCreatedData = this.commonService.getMenusCreated();
    const requestedViandasData = this.viandasServ.getRequestedViandas();
    combineLatest([
      userData,
      userPreferencesData,
      customerContractsData,
      drinkList,
      companyData,
      specialMenusData,
      menuConfigData,
      menusCreatedData,
      requestedViandasData,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          if (!res.some((r: any) => r === undefined)) {
            this.user = res[0];
            this.userPreference = res[1];
            this.contractClient = res[2].contracts;

            this.drinkList = res[3];

            this.company = res[4];
            if (
              this.company.locations &&
              this.company.locations.length > 0
            ) {
              this.userWithoutLocation = false;
            } else {
              this.userWithoutLocation = true;
            }

            this.specialMenus = res[5];
            this.menuConfig = res[6];
            this.menusCreated = res[7];
            this.requestedViandas = res[8];
            this.user.special_enabled ? this.userSpecialMenus = this.user.special_enabled : this.userSpecialMenus = [];
            this.loadingUserData = false;
            this.generateSelectedWeek(undefined)
            this.generateLocationsArray()
            this.companyDataServ.setGeneralData(this.company, this.user, this.locationsAvailable, this.contractClient)
          }

          this.route.params.subscribe(params => {
            if (params.$d) {
              this.dateChange(params.$d);
              this.selectedDate = new Date(params.$d);
              this.router.navigate(['viandas'], { replaceUrl: true });
            }
          });
        },
        (err) => {
          console.error(err);
          this.loadingUserData = false;
        }
      );
  }

  //valida si la fecha que llega por parámetro está dentro de la semana ya generada y se encuentra dentro de la validez del contrato
  validateDateInWeek(date: any) {
    let isDateValid = false
    if ((dayjs(date).isAfter(this.weekStart) || dayjs(date).isSame(this.weekStart)) &&
      (dayjs(date).isBefore(this.weekEnd) || dayjs(date).isSame(this.weekEnd)) && this.contractHelpers.validateContractVigency(date, this.contractStartDate, this.contractEndDate)) {
      isDateValid = true
    }
    return isDateValid
  }

  //obtiene una semana de lunes a domingo a partir de una fecha seleccionada
  generateSelectedWeek(e: any) {
    if (e != undefined) {
      this.selectedDate = e
    }
    if (this.validateDateInWeek(this.selectedDate)) {
      //la fecha existe en la semana 
      return
    } else {
      //la fecha está fuera de la semana 
      this.selectedWeek = []
      const selectedDay = dayjs(new Date(this.selectedDate))
      this.weekStart = selectedDay.startOf('week')
      this.weekEnd = selectedDay.endOf('week')

      //agrego al array de la semana el día lunes
      let i = 1;
      let d = this.weekStart.toDate()
      this.selectedWeek.push({
        day: this.weekDaysName[d.getDay()],
        date: d
      })

      //agrego los días entre lunes y domingo
      do {
        let weekDay = this.weekStart.add(i, 'd')
        d = weekDay.toDate()
        this.selectedWeek.push({
          day: this.weekDaysName[d.getDay()],
          date: d
        })
        i++
      } while (i < 6)

      //agrego el día domingo 
      d = this.weekEnd.toDate()
      this.selectedWeek.push({
        day: this.weekDaysName[d.getDay()],
        date: d
      })
      this.getWeekMenus()
    }
  }

  //obtiene la semana nueva segun la fecha seleccionada y llama a la funcion que obtiene las viandas segun semana y ubicación
  dateChange(e: any) {
    this.generateSelectedWeek(e)
    this.getWeekAndLocationViandas()
  }

  //obtiene los menus generados para la semana seleccionada
  getWeekMenus() {
    this.weekMenus = [];
    this.selectedWeek.forEach(weekDay => {
      const normalizedDate = dayjs(weekDay.date).format('YYYYMMDD')
      const menu = this.menusCreated.find((menuCreated: any) => menuCreated.id === normalizedDate)
      if (menu) {
        this.thereMenusCreated = true;
        this.weekMenus.push(menu)
      } else {
        this.weekMenus.push(null)
      }
    });
  }

  //valida que la ubicacion de entrega de la vianda coincida con la ubicacion seleccionada por el user
  validateViandaLocation(vianda: any) {
    let isLocationValid = false
    if (this.selectedLocation.id === vianda.location.id) {
      isLocationValid = true
    }
    return isLocationValid
  }

  //obtiene las viandas de la semana y ubicacion seleccionadas
  getWeekAndLocationViandas() {
    this.weekRequestedViandas = []
    this.companyRequestedViandas = []
    this.requestedViandas.forEach((vianda: any) => {
      if (this.validateViandaLocation(vianda)) {
        // console.log(vianda)
        const dateVianda = new Date(vianda.date_meal.seconds * 1000);
        if (this.validateDateInWeek(dateVianda)) {
          //si el user es responsable de sucursal, guardo la vianda
          // if (this.userRol === 'Responsable de sucursal') {
            this.companyRequestedViandas.push(vianda)
          // }
          //  else {
          //si es usuario final me fijo que la vianda esté a su nombre
          if (vianda.requester.id === this.user.id || vianda.requested_to.id === this.user.id) {
            this.fixModificationsMenusAndDrinkStructure(vianda)
            this.weekRequestedViandas.push(vianda)
          }
          // }
        }
      }
    })

  }

  //desde el SE se generó un error que cambió la estructura de modificaciones de algunas viandas, con esta función se corrige para evitar más errores
  fixModificationsMenusAndDrinkStructure(vianda:any) {
    
    vianda.modifications.forEach((modification: any) => {
      if (modification.menus.menus) {
        modification.menus = modification.menus.menus
      }
      if (modification.drinks.drinks) {
        modification.drinks = modification.drinks.drinks
      }
    });

  }

  //genera el array de ubicaciones asociadas al user
  generateLocationsArray() {
    if (this.user.locationAccess && this.user.locationAccess.length > 0) {
      // console.log('tiene locationAccess')
      this.locationsAvailable = [];
      this.user.locationAccess.forEach((location: any) => {
        this.locationsAvailable.push({
          id: location.locationId,
          name: location.locationName,
          rol: location.role //este es el rol que tiene el user en la ubicación
        })
      })
      //selecciono una location con su rol por default
      this.selectedLocation = this.locationsAvailable[0];
      this.userRol = this.locationsAvailable[0].rol
      this.filterContracts()
      this.getWeekAndLocationViandas()
    } else {
      this.locationsAvailable = undefined
    }

  }

  //cambia el rol segun corresponda cuando el user elige otra ubicación y llamo a las funciones que dependen del selectedLocation
  locationChange() {
    this.userRol = this.selectedLocation.rol;
    this.locationHelpers.setSelectedLocation(this.selectedLocation)
    this.filterContracts()
    this.getWeekAndLocationViandas()
  }

  //obtiene el contrato del user segun la ubicación seleccionada (no hay más de un contrato por ubicación)
  filterContracts() {
    this.contractClient.forEach((contract: any) => {
      let contractExists = contract.locations.find((location: any) => location.id === this.selectedLocation.id)
      if (contractExists) {
        this.contractUser = contract
      }
    });
    this.contractStartDate = new Date(this.dateHelpers.convertDate(this.contractUser.dateFrom))
    this.contractEndDate = new Date(this.dateHelpers.convertDate(this.contractUser.dateUntil))
  }


}
