import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, takeUntil, switchMap } from 'rxjs/operators';
import { UserConfig } from './../models/UserConfig';
import { User } from '../models/user.model';
import { CompanyDataService } from './company-data.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  public userConfig: any;

  // SideBar
  mode = new BehaviorSubject<any>(undefined);
  isOpen = new BehaviorSubject<any>(undefined);
  isToggled = new BehaviorSubject<any>(undefined);
  destroy$: Subject<boolean> = new Subject<boolean>();

  private user$ = new BehaviorSubject<any>(undefined);
  userEdit$ = new BehaviorSubject<any>(undefined);
  private userPreferencesCollection: AngularFirestoreCollection<any>;
  private usersCollection: AngularFirestoreCollection<any>;
  userPreferences$ = new BehaviorSubject<any>(undefined);

  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth, private companyDataServ: CompanyDataService) {
    this.userConfig = this.getUserConfig();
    this.usersCollection = this.afs.collection<any>('users');
    this.userPreferencesCollection =
      this.afs.collection<any>('user_preferences');
    const isOpenSavedConf = this.userConfig.sidebar_isOpen;
    this.isOpen.next(isOpenSavedConf);
    const istoggledSavedConf = this.userConfig.sidebar_isToggled;
    this.isToggled.next(istoggledSavedConf);
    const modeSavedConf = this.userConfig.sidebar_mode;
    this.mode.next(modeSavedConf);
  }

  //devuelve un usuario si lo encuentra, si no devuelve false
  async checkDuplicateEmail(
    email: string,
    userIdToExclude?: string
  ): Promise<any | null> {
    const user = await this.usersCollection.ref
      .where('email', '==', email)
      .get();
    for (const doc of user.docs) {
      const userId = doc.id;
      if (userId !== userIdToExclude && userId) {
        return user;
      }
    }

    // Si no se encuentra ningún usuario con el correo electrónico o todos los IDs son iguales al que se debe excluir, devuelve null.
    return null;
  }

  async updateUser(userData: any): Promise<any> {
    // Obtén una referencia al documento de usuario
    const userRef: AngularFirestoreDocument<any> = this.usersCollection.doc(
      userData.uid
    );

    const dataToUpdate = {
      locationAccess: userData.locationAccess ? userData.locationAccess : [],
      special_enabled: userData.special_enabled ? userData.special_enabled : [],
      first_names: userData.first_names,
      last_names: userData.last_names,
      email: userData.email,
      dni: userData.dni,
      phone: userData.phone ? userData.phone : '',
      ...(userData.status !== undefined && { status: userData.status }),
      adminUser: userData.adminUser ? userData.adminUser : false,
      validated: userData.validated ? userData.validated : false,
      tags: userData.tags ? userData.tags : []
    };



    if (userData.uid) {
      return userRef.set(dataToUpdate, { merge: true });
    } else {
      return this.afs
        .collection('users')
        .add(dataToUpdate)
        .then(() => {
          // console.log('usuario creado');
        });
    }
  }

  setUserEdit(user: User) {
    this.userEdit$.next(user)
  }

  getUserEdit(): Observable<User> {
    return this.userEdit$.asObservable()
  }

  getUser(): Observable<any> {
    return this.user$.asObservable();
  }

  getUserData(): any {
    this.afAuth.authState.pipe(takeUntil(this.destroy$)).subscribe(
      (user: any) => {
        // console.log(user);
        this.afs
          .doc<any>(`users/${user.uid}`)
          .valueChanges({ idField: 'id' })
          .pipe(takeUntil(this.destroy$))
          .subscribe((res) => {
            this.user$.next(res);
          });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  //funcion que funciona para ver los datos en guards
  getUserServ(): Observable<any> {
    return this.afAuth.authState.pipe(
      takeUntil(this.destroy$),
      switchMap((user: any) => {
        if (user) {
          return this.afs
            .doc<any>(`users/${user.uid}`)
            .valueChanges({ idField: 'id' });
        } else {
          return of(null); // Si no hay usuario autenticado, devuelve null
        }
      })
    );
  }

  stopListenUserData(): void {
    this.user$.next(undefined);
    this.destroy$.next(true);
  }

  saveUserTokenMessaging(token: string, userId: any): Promise<any> {
    const prodRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${userId}`
    );
    return prodRef.set(
      { token_messaging: token, notification_enable: true },
      { merge: true }
    );
  }

  getUserNotifications(uid: string): Observable<any> {
    return this.afs
      .collection('notifications', (ref) => ref.where('user.uid', '==', uid))
      .snapshotChanges()
      .pipe(
        takeUntil(this.destroy$),
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  getUserPreferences(): Observable<any> {
    return this.userPreferences$.asObservable();
  }

  getUserPreferencesData(userId: string) {
    this.userPreferencesCollection
      .doc(userId)
      .valueChanges()
      .subscribe(
        (res) => {
          if (res) {
            this.userPreferences$.next(res);
          } else {
            // Saves the user preferences doc empty if not exists
            this.userPreferencesCollection.doc(userId).set({});
          }
        },
        (err) => console.error(err)
      );
  }

  //////////////////////////////////// LOCAL CONFIG FOR SIDEBAR ////////////////////////////////////
  getUserConfig(): UserConfig {
    const configGeted: any = localStorage.getItem('butaco_user_config');
    let config: UserConfig = JSON.parse(configGeted);
    if (!config) {
      config = {
        sidebar_isToggled: false,
        sidebar_isOpen: true,
        sidebar_mode: 'side',
        items_toggle_get_Detail: false,
        dark_mode_activated: false,
        stock_control_required_stock: false,
        stock_control_required_stock_all: true,
        stock_control_show_products: false,
      };
      localStorage.setItem('butaco_user_config', JSON.stringify(config));
    }
    return config;
  }

  // ToggleSideNav
  toggle(newState: any): any {
    this.isToggled.next(newState);
    if (newState) {
      this.userConfig.sidebar_isToggled = true;
    } else {
      this.userConfig.sidebar_isToggled = false;
    }
    localStorage.setItem('butaco_user_config', JSON.stringify(this.userConfig));
  }

  // Show or not SideNav
  openClose(newState: any): any {
    this.isOpen.next(newState);
    if (newState) {
      this.userConfig.sidebar_isOpen = true;
    } else {
      this.userConfig.sidebar_isOpen = false;
    }
    localStorage.setItem('butaco_user_config', JSON.stringify(this.userConfig));
  }

  sidebarMode(newState: any): any {
    if (newState === 'over') {
      this.mode.next('over');
      this.userConfig.sidebar_isOpen = 'over';
    } else {
      this.mode.next('side');
      this.userConfig.sidebar_isOpen = 'side';
    }
    localStorage.
      setItem('butaco_user_config', JSON.stringify(this.userConfig));
  }



  getCanEdit(): Observable<boolean> {
    return this.getUser().pipe(
      switchMap(actualUser => {
        return this.companyDataServ.getCompany().pipe(
          map(data => {
            if (data && data.users) {
              const currentUser = data.users.find((user: User) => user.uid === actualUser.uid);
              return currentUser ? currentUser.adminUser : false;
            }
            return false;
          })
        );
      })
    );
  }

}
