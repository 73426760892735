import { Pedido } from '../models/viandas';
import { viandaStatus } from '../models/enums';

export interface ViandaState {
  accept(pedido: Pedido, isWithinPendingRange: boolean): void;
  cancel(pedido: Pedido, isWithinPendingRange: boolean): void;
}

//--------------------------------CLASES DE ESTADO-----------------------------------------//
export class AcceptedState implements ViandaState {
  accept(pedido: Pedido, isWithinPendingRange: boolean): void {
    // Sin acción si está aceptado
  }

  cancel(pedido: Pedido, isWithinPendingRange: boolean): void {
    if (isWithinPendingRange) {
      pedido.status_request = viandaStatus.CancelacionPendiente;
    } else {
      pedido.status_request = viandaStatus.Cancelado;
    }
  }
}

export class CancelledState implements ViandaState {
  accept(pedido: Pedido, isWithinPendingRange: boolean): void {
    // Sin acción si está aceptado
  }

  cancel(pedido: Pedido, isWithinPendingRange: boolean): void {
    // Sin acción si está cancelado
  }
}

export class PendingState implements ViandaState {
  accept(pedido: Pedido, isWithinPendingRange: boolean): void {
    pedido.status_request = viandaStatus.Aceptado;
  }

  cancel(pedido: Pedido, isWithinPendingRange: boolean): void {
    if (isWithinPendingRange) {
      pedido.status_request = viandaStatus.CancelacionPendiente;
    } else {
      pedido.status_request = viandaStatus.Cancelado;
    }
  }
}

export class PendingCancellationState implements ViandaState {
  accept(pedido: Pedido, isWithinPendingRange: boolean): void {
    pedido.status_request = viandaStatus.Aceptado;
  }

  cancel(pedido: Pedido, isWithinPendingRange: boolean): void {
    pedido.status_request = viandaStatus.Cancelado;
  }
}
