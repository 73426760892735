<div class="container">
    <button mat-mini-fab color="primary" (click)="generatePDF()"
    class="buttonPDF" >
    <mat-icon fontSet="material-icons-outlined" class="icon">
            picture_as_pdf
    </mat-icon></button>
    <div class="menu-data">
        <ng-container *ngFor="let dayData of weekData; let isLast = last;">
            <div class="menu-card" [ngClass]="!isLast ? 'border-right' : ''">
                <div class="week-day">
                        <span class="text-md day">{{dayData.dayName}} {{dayData.date}}</span>
                        <!-- <button
                        *ngIf="dayData.isAvailable && dayData.menuPublished && userRol === 'Responsable de sucursal'"
                        mat-icon-button class="icnBtn-sm button-shop"
                       (click)="redirectToNewRequest('crear', dayData.dateNewRequest, '')
                       ">
                        <mat-icon fontSet="material-icons-outlined" class="icon">
                            shopping_cart
                        </mat-icon>
                    </button> -->
                </div>

                <div class="menu-day" *ngIf="dayData.menuExist ; else menuNull">
                    <div class="menus-container" *ngFor="let menu of dayData.menu">
                        <div [ngClass]="this.userSpecialMenus.length>0 ? 'menu-compact' : 'menu'">
                            <h4 [ngClass]="menu.menu ==='No habilitado'? 'component-unavailable': 'component'"
                                class="text-lg">
                                {{menu.component}}
                            </h4>
                            <h5 class="menu-name text-sm"
                                [ngStyle]="{'color': menu.menu ==='No habilitado'? 'rgb(224, 0, 0)' : 'black'}">
                                {{menu.menu}}
                            </h5>
                        </div>

                    </div>
                </div>
                <ng-template #menuNull>
                    <div class="cartel">No hay menus creados para esta fecha</div>
                </ng-template>
            </div>
        </ng-container>
    </div>
    <!-- <div class="action-div">
        <ng-container *ngFor="let dayData of weekData; let isLast = last;">
            <div class="button" *ngIf="userRol === 'Responsable de sucursal'">
                <button *ngIf="dayData.isAvailable && dayData.menuPublished" mat-icon-button
                    class="icnBtn-sm button-shop"
                    [routerLink]="['crear', dayData.dateNewRequest, this.selectedLocation.id]">
                    <mat-icon fontSet="material-icons-outlined" class="icon">
                        shopping_cart
                    </mat-icon></button> -->
    <!-- <button *ngIf="!dayData.isAvailable || !dayData.menuPublished" disable mat-icon-button disable
                    class="icnBtn-sm button-unavailable">
                    <mat-icon fontSet="material-icons-outlined" class="icon">
                        close
                    </mat-icon></button> -->
    <!-- </div>
                    </mat-icon></button>
                    <button  mat-icon-button (click)="generatePDF()"
                    class="icnBtn-sm button-shop" >
                    <mat-icon fontSet="material-icons-outlined" class="icon">
                            picture_as_pdf
                    </mat-icon></button>
            </div>
        </ng-container>
    </div> -->

    <!-- 
    <table mat-table>
        <tr>
            <th *ngFor="let dayData of weekData">{{dayData.dayName}} {{dayData.date}}</th>
        </tr>
        <td *ngFor="let dayData of weekData">
            <ng-container >
                <tr *ngFor="let viandaItem of dayData.menu">
                    <div>
                        {{viandaItem.component}}
                    </div>
                    <div>
                        {{viandaItem.menu}}
                    </div>
                </tr>
            </ng-container>
        </td>
    </table> -->


    <div class="info">
        <h4 class="text-lg l-h-2"> Recordá que podrás pedir hasta las 10:00am. Los pedidos o modificaciones realizados
            despues de este horario tendrán que ser aprobados por Butaco SRL. Los pedidos de los días domingos serán
            aprobados hasta el sabado a las 12:00pm. Luego de ese horario necesitarán aprobacion de Butaco SRL.</h4>
    </div>
</div>